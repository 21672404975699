import { StyleSheet } from 'react-native';

import { theme, screen } from 'utils';

const { isMobile } = screen;
const { radius, thumbnailHeight, thumbnailWidth } = theme;

const styles = StyleSheet.create({
  container: {
    height: thumbnailHeight + 80,
    borderRadius: radius,
    marginBottom: 20,
    width: isMobile ? 'auto' : thumbnailWidth,
    marginHorizontal: 10,
    flexBasis: 'auto',
    backgroundColor: theme.colors.white,
  },
  content: {
    display: 'flex',
  },
});

export default styles;
