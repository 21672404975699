export default {
  BOOKINGS: 'Bookings',
  DATE_CONFIG: 'DateConfig',
  DETAIL: 'Detail',
  HOME: 'Home',
  LOG_IN: 'LogIn',
  MEMBERSHIP: 'Membership',
  PAYMENT_DETAIL: 'PaymentDetail',
  STRIPE_FORM: 'StripeForm',
  PAYMENT_METHODS: 'PaymentMethods',
  RECOVER_PASSWORD: 'RecoverPassword',
  RESULTS: 'Results',
  SEARCH: 'Search',
  SEARCH_CONFIG: 'SearchConfig',
  SEARCH_FROM_LOCATION: 'SearchFromLocation',
  SIGN_UP: 'SignUp',
  TERMS_CONDITIONS: 'TermsConditions',
  ABOUTUS:'About Us',
  DECIDIR_FORM:'DecidirForm'
};
