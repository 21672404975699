import { StyleSheet } from 'react-native';

import { screen, theme } from 'utils';

const { isBigScreen } = screen;
const { secondary, success } = theme.colors;

const styles = StyleSheet.create({
  reservationInfoAndAction: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  reservationInfo: {
    flex: 1,
  },
  reservationTotal: {
    fontSize: 18,
  },
  reservationPrice: {
    fontSize: 28,
    fontWeight: '700',
    marginRight: isBigScreen ? 20 : 0,
  },
  reservationDetailPrice: {
    fontSize: 22,
    fontWeight: '600',
    marginRight: isBigScreen ? 20 : 0,
  },
  reservationActionContainer: {
    alignSelf: 'center',
  },
  reservationAction: {
    borderRadius: 10,
    elevation: 10,
    paddingHorizontal: 15,
    paddingVertical: 8,
    shadowColor: secondary,
    shadowOffset: { width: 0, height: 15 },
    shadowOpacity: 0.5,
    shadowRadius: 15.0,
    width: 200,
    marginLeft: 10,
  },
  buttonSuccess: {
    backgroundColor: theme.colors.success,
    elevation: 0,
    shadowOpacity: 0,
  },
  buttonExpired: {
    backgroundColor: theme.colors.mediumGrey,
    elevation: 0,
    shadowOpacity: 0,
  },
});

export default styles;
