import React from 'react';
import { View } from 'react-native';

import styles from './RecoverPassword.styles';
import { recoverPassword as recoverPasswordKeys } from 'i18n';
import AuthTemplate from '../auth-template';
import Button from '../button';
import TextInput from '../text-input';
import Text from 'components/text/Text';

const { EMAIL_FIELD, SUBMIT_BUTTON, CANCEL_BUTTON } = recoverPasswordKeys;

const RecoverPassword = ({
  email,
  setEmail,
  recoverPassword,
  onCancel,
  isLoading,
}) => (
  <AuthTemplate>
    <View style={styles.container}>
      <TextInput
        itemProps={{ floatingLabel: true }}
        label={EMAIL_FIELD}
        onChangeText={(value) => setEmail(value)}
        value={email}
        icon="email"
        inputStyles={styles.input}
      />

      <Button
        onPress={recoverPassword}
        buttonStyles={styles.button}
        labelStyle={styles.buttonLabel}
        mode="outlined"
        loading={isLoading}
      >
        <Text style={styles.submitText}>{SUBMIT_BUTTON}</Text>
      </Button>
      <Button
        onPress={onCancel}
        buttonStyles={styles.cancelButton}
        labelStyle={styles.buttonLabel}
        mode="outlined"
        color="#000"
      >
        <Text style={styles.cancelButtonText}>{CANCEL_BUTTON}</Text>
      </Button>
    </View>
  </AuthTemplate>
);

export default RecoverPassword;
