import { StyleSheet } from 'react-native';

import { theme } from 'utils';

const styles = StyleSheet.create({
  newArrivalButton: {
    alignItems: 'flex-start',
    backgroundColor: theme.colors.primary,
    borderColor: 'white',
    borderRadius: 5,
    borderWidth: 1,
    marginTop: 0,
    width: '100%',
    maxWidth: 500,
  },
  newArrivalButtonDisabled: {
    opacity: 0.4,
  },
  newArrivalButtonContent: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 70,
    paddingHorizontal: 15,
    width: '100%',
  },
  newArrivalButtonContentStyle: {
    backgroundColor: 'blue',
    height: 100,
    width: 100,
  },

  newArrivalButtonIcon: {
    color: 'white',
    height: 24,
    marginRight: 10,
    width: 24,
  },
  newArrivalButtonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: '800',
  },
});

export default styles;
