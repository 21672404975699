import { Dimensions } from 'react-native';

const TABLET_INITIAL_RESOLUTION = 500;
const DESKTOP_INITIAL_RESOLUTION = 1200;
const DEVICE_WIDTH = Dimensions.get('window').width;
const SCREEN_WIDTH = Dimensions.get('screen').width;

export default {
  maxWidth: '100%',
  deviceWidth: DEVICE_WIDTH,
  screenWidth:
    SCREEN_WIDTH >= DESKTOP_INITIAL_RESOLUTION
      ? DESKTOP_INITIAL_RESOLUTION
      : SCREEN_WIDTH,
  isMobile: DEVICE_WIDTH < TABLET_INITIAL_RESOLUTION,
  isTablet:
    DEVICE_WIDTH >= TABLET_INITIAL_RESOLUTION &&
    DEVICE_WIDTH < DESKTOP_INITIAL_RESOLUTION,
  isBigScreen: DEVICE_WIDTH >= DESKTOP_INITIAL_RESOLUTION,
  desktopInitialResolution: DESKTOP_INITIAL_RESOLUTION,
  tabletInitialResolution: TABLET_INITIAL_RESOLUTION,
};
