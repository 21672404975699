import React, { useContext, useState } from 'react';

import { signup as signupKeys } from 'i18n';
import { signUp } from 'api';
import { constants, methods, routes } from 'utils';
import SignUpComponent from 'components/signup';
import { SnackbarContext } from '../contexts/SnackbarContext';
import errorTranslate from 'i18n/errorTranslate';

const { REGISTER } = constants.METRICS;
const { triggerMetric } = methods;
const { LOG_IN } = routes;
const { MISSING_FIELD, SUCCESSFUL_REGISTRATION } = signupKeys;

const SignUpScreen = ({ navigation }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [dni, setDni] = useState('');
  const [phone, setPhone] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [nameError, setNameError] = useState('');
  const [surnameError, setSurnameError] = useState('');
  const [dniError, setDniError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { setSnackbar } = useContext(SnackbarContext);

  const triggerSuccessfulRegistrationMetric = (userId) =>
    triggerMetric({ event: REGISTER, userId });

  const onSuccess = (res) => {
    const data = (res?.config?.data && JSON.parse(res.config.data)) || {};
    setIsLoading(false);
    setSnackbar({
      message: SUCCESSFUL_REGISTRATION,
      type: 'success',
      visible: true,
    });
    triggerSuccessfulRegistrationMetric(data?.email);
    navigation.navigate(LOG_IN);
  };

  const hasError = (fieldName, errors) => errors?.indexOf(fieldName) >= 0;

  const checkError = ({ field, setErrorFn, missingFields }) => {
    if (hasError(field, missingFields)) {
      setErrorFn(MISSING_FIELD);
    } else {
      setErrorFn('');
    }
  };

  const onError = (err) => {
    setIsLoading(false);
    if (err?.response?.data?.missingFields) {
      const { missingFields } = err.response.data;

      checkError({
        field: 'email',
        setErrorFn: setEmailError,
        missingFields,
      });
      checkError({
        field: 'password',
        setErrorFn: setPasswordError,
        missingFields,
      });
      checkError({
        field: 'name',
        setErrorFn: setNameError,
        missingFields,
      });
      checkError({
        field: 'surname',
        setErrorFn: setSurnameError,
        missingFields,
      });
      checkError({
        field: 'dni',
        setErrorFn: setDniError,
        missingFields,
      });
      checkError({
        field: 'phone',
        setErrorFn: setPhoneError,
        missingFields,
      });
    } else {
      setSnackbar({
        message: errorTranslate(err?.response?.data),
        type: 'error',
        visible: true,
      });
    }
  };

  const signUpHandler = () => {
    const data = { email, password, name, surname, dni, phone };
    setIsLoading(true);
    signUp({ data, onSuccess, onError });
  };

  const onCancel = () => navigation.navigate(LOG_IN);

  return (
    <>
      <SignUpComponent
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        name={name}
        setName={setName}
        surname={surname}
        setSurname={setSurname}
        dni={dni}
        setDni={setDni}
        phone={phone}
        setPhone={setPhone}
        signUp={signUpHandler}
        emailError={emailError}
        passwordError={passwordError}
        nameError={nameError}
        surnameError={surnameError}
        dniError={dniError}
        phoneError={phoneError}
        isLoading={isLoading}
        onCancel={onCancel}
      />
    </>
  );
};

export default SignUpScreen;
