import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

import { locationsContainer as locationsContainerKeys } from 'i18n';
import { methods, routes } from 'utils';
import IconButton from 'components/icon-button';
import LocationInput from './LocationInput';

const { ADD_DESTINATION } = locationsContainerKeys;
const { isAnEmptyObject } = methods;
const { SEARCH } = routes;

const LocationsContainer = ({
  locations: lc,
  flightType,
  onRemoveLocation,
}) => {
  const navigation = useNavigation();
  const [locations, setLocations] = useState(lc);
  const lastLocation = locations && locations[locations.length - 1];
  const isLastLocationEmpty = lastLocation && isAnEmptyObject(lastLocation);
  const isMultiLeg = flightType === 'multiLeg';
  const type = 'toLocations';

  const onPressInputHandler = (index) => {
    const locationsArr = [...locations];
    locationsArr.splice(index, 1);
    setLocations(locationsArr);
    onRemoveLocation(locationsArr);
  };

  const onPressIconButtonHandler = () => {
    const newLocations = [...locations, {}];
    if (!isLastLocationEmpty && isMultiLeg) {
      setLocations(newLocations);
      navigation.push(SEARCH, {
        type,
        locations: newLocations,
        locationIndex: locations.length,
        flightType,
      });
    }
  };

  const onFocusHandler = ({ locationIndex }) => {
    navigation.push(SEARCH, { type, locations, locationIndex, flightType });
  };

  useEffect(() => {
    setLocations(lc);
  }, [lc]);

  return (
    <>
      {locations.map((location, i) => (
        <LocationInput
          key={uuidv4()}
          location={location}
          locationsQuantity={locations.length}
          index={i}
          onFocus={onFocusHandler}
          onPress={onPressInputHandler}
        />
      ))}
      {isMultiLeg && (
        <View style={{ paddingHorizontal: 10, width: '100%', maxWidth: 500 }}>
          <IconButton
            onPress={onPressIconButtonHandler}
            disabled={isLastLocationEmpty}
            label={ADD_DESTINATION}
          />
        </View>
      )}
    </>
  );
};

LocationsContainer.propTypes = {
  locations: PropTypes.array.isRequired,
  flightType: PropTypes.string.isRequired,
};

export default LocationsContainer;
