import React from 'react';
import { View } from 'react-native';

import styles from './TermsAndConditions.styles';

const TermsAndConditionsComponent = ({ data }) => (
  <View style={styles.container}>
    <div dangerouslySetInnerHTML={{ __html: data }} />
  </View>
);

export default TermsAndConditionsComponent;
