import { StyleSheet } from 'react-native';

import { device, screen } from 'utils';

const { isNative } = device;
const { isBigScreen } = screen;

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
  },
  snackbar: {
    marginHorizontal: isNative ? 20 : 'auto',
    width: isNative || !isBigScreen ? '90%' : 500,
  },
});

export default styles;
