import { StyleSheet } from 'react-native';

import { theme } from 'utils';

const styles = StyleSheet.create({
  container: {
    textAlign: 'center',
    marginBottom: 10,
    width: '100%',
  },
  text: {
    color: theme.colors.white,
    textAlign: 'center',
    textTransform: 'capitalize',
    fontSize: 18,
  },
});

export default styles;
