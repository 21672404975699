import React from 'react';
import { View } from 'react-native';
import ContentLoader, { Rect } from 'react-content-loader/native';

import styles from './FlightThumbnailSkeleton.styles';
import { theme } from 'utils';

const { shadow, thumbnailHeight } = theme;

const OfferSkeleton = () => (
  <View style={[styles.container, shadow]}>
    <ContentLoader
      width="100%"
      height={thumbnailHeight + 100}
      style={styles.content}
    >
      <Rect x="0" y="0" rx="10" ry="10" width="100%" height={thumbnailHeight} />
      <Rect
        x="20"
        y={thumbnailHeight + 15}
        rx="0"
        ry="0"
        width="80%"
        height="20"
      />
      <Rect
        x="20"
        y={thumbnailHeight + 45}
        rx="0"
        ry="0"
        width="35%"
        height="10"
      />
    </ContentLoader>
  </View>
);

export default OfferSkeleton;
