import ButtonComponent from 'components/button/Button';
import Text from 'components/text/Text';
import * as React from 'react';
import { View } from 'react-native';
import {
  Button,
  Paragraph,
  Dialog,
  Portal,
  Provider,
  FAB,
  IconButton,
} from 'react-native-paper';
import { theme } from 'utils';

const destinos = [
  'Mendoza - SAME',
  'Punta del este - SULS',
  'Cordoba - SACO',
  'San Martín de los Andes - SAZY',
  'Miami',
  'Iguazú - SARI',
  'Río de Janeiro - SBGL',
  'Florianopolis - SBFL',
];

const NoSeatsDialog = () => {
  const [visible, setVisible] = React.useState(false);

  const showDialog = () => setVisible(true);

  const hideDialog = () => setVisible(false);

  return (
    <View>
      <IconButton
        color={theme.colors.secondary}
        size={20}
        icon="help-circle-outline"
        onPress={showDialog}
        style={{ margin: 0, height: 20 }}
      />
      <Portal>
        <Dialog
          visible={visible}
          style={{ backgroundColor: theme.colors.white }}
          onDismiss={hideDialog}
        >
          <Dialog.Content>
            <Text style={{ marginBottom: 15, fontSize: 18 }}>
              La opción sharing está disponible solo en los siguientes destinos:
            </Text>
            {destinos.map((destino) => (
              <Text style={{ marginLeft: 10, marginBottom: 5, fontSize: 16 }}>
                - {destino}
              </Text>
            ))}
            <Text style={{ marginTop: 15, fontSize: 18 }}>
              Utilizando la modalidad "solo ida"
            </Text>
          </Dialog.Content>
          <Dialog.Actions>
            <ButtonComponent onPress={hideDialog}>
              <Text
                style={{
                  fontWeight: '700',
                  textTransform: 'capitalize',
                  fontSize: 24,
                  letterSpacing: 0,
                  paddingHorizontal: 10,
                }}
              >
                Hecho
              </Text>
            </ButtonComponent>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  );
};

export default NoSeatsDialog;
