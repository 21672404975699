import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-native-paper';

import styles from './Button.styles';

const ButtonComponent = ({ children, buttonStyles, ...restProps }) => {
  return (
    <Button
      labelStyle={styles.labelStyle}
      mode="contained"
      style={[styles.button, buttonStyles]}
      {...restProps}
    >
      {children}
    </Button>
  );
};

ButtonComponent.propTypes = {
  children: PropTypes.node.isRequired,
  buttonStyles: PropTypes.number,
};

export default ButtonComponent;
