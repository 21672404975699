import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { HelperText, TextInput } from 'react-native-paper';

import styles from './TextInput.styles';
import { theme } from 'utils';

const TextInputComponent = forwardRef((props, ref) => {
  const {
    icon,
    onPressIconLeft,
    iconRight,
    onPressIconRight,
    iconRightStyles,
    containerStyles,
    inputStyles,
    errorMessage,
    ...restProps
  } = props;
  const left = !!icon && {
    left: (
      <TextInput.Icon
        icon={icon}
        color={theme.colors.darkGrey}
        onPress={onPressIconLeft}
      />
    ),
  };
  const right = !!iconRight && {
    right: (
      <TextInput.Icon
        icon={iconRight}
        color={theme.colors.darkGrey}
        style={iconRightStyles}
        onPress={onPressIconRight}
      />
    ),
  };

  return (
    <View style={[styles.container, containerStyles]}>
      <TextInput
        style={[styles.input, inputStyles]}
        underlineColor="transparent"
        error={!!errorMessage}
        {...left}
        {...right}
        {...restProps}
        theme={{
          colors: {
            primary: theme.colors.primary, // línea inferior cuando está focus
            text: theme.colors.darkGrey,
            placeholder: theme.colors.primary,
          },
        }}
        ref={ref}
      />
      {!!errorMessage && (
        <HelperText type="error" visible={!!errorMessage} style={styles.error}>
          {errorMessage}
        </HelperText>
      )}
    </View>
  );
});

TextInputComponent.propTypes = {
  icon: PropTypes.string,
  onPressIconLeft: PropTypes.func,
  iconRight: PropTypes.string,
  onPressIconRight: PropTypes.func,
  iconRightStyles: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  containerStyles: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  inputStyles: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  errorMessage: PropTypes.string,
};

TextInputComponent.defaultProps = {
  icon: null,
  onPressIconLeft: null,
  iconRight: null,
  onPressIconRight: null,
  iconRightStyles: null,
  containerStyles: null,
  inputStyles: null,
  errorMessage: null,
};

export default TextInputComponent;
