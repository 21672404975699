import { StyleSheet } from 'react-native';

import { theme } from 'utils';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 20,
  },
  label: {
    fontSize: 20,
    marginRight: 16,
  },
  button: {
    backgroundColor: theme.colors.primary,
    display: 'flex',
    flexDirection: 'row',
  },
  contentStyle: {
    flexDirection: 'row-reverse',
  },
  labelStyle: {
    alignItems: 'center',
    display: 'flex',
    marginRight: 14,
  },
  text: {
    display: 'flex',
    fontSize: 20,
    marginRight: 8,
  },
});

export default styles;
