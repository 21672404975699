import { StyleSheet } from 'react-native';
import { screen, theme } from 'utils';

const { desktopInitialResolution, isMobile } = screen;

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  containerWhite: {
    width: '100%',
    backgroundColor:'#fff',
  },
  titleContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 20,
    width: 1700,
    maxWidth: '80%',
  },
  title: {
    alignSelf: 'center',
    color: 'black',
    fontSize: 20,
    paddingRight: 24,
    maxWidth: '90%',
  },
  origin: {
    whiteSpace: 'nowrap',
    fontWeight: '700',
  },
  offersContainer: {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    flexWrap: isMobile ? 'nowrap' : 'wrap',
    justifyContent: 'center',
    maxWidth: 2000,
    marginHorizontal: isMobile ? 0 : 'auto',
    padding: 24,
    width: 'auto',
  },
  noResultsContainer: {
    justifyContent: 'center',
  },
  noResultsText: {
    fontSize: 20,
    textAlign: 'center',
    color: theme.colors.primary,
  },
  fab: {
    alignSelf: 'center',
    backgroundColor: theme.colors.secondary,
  },
  backgroundImage: {
    height: 200,
    opacity: 1,
    width: 250,
    marginHorizontal: 'auto',
    alignSelf: 'center',
  },
});

export default styles;
