import React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={28}
      height={23}
      viewBox="0 0 28 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M27.088.418c.23.305.272.636.128.993L18.58 21.877a.863.863 0 01-.57.508.765.765 0 01-.429-.004.879.879 0 01-.293-.16l-5.219-4.273-4.153 3.21a.743.743 0 01-.714.138.703.703 0 01-.27-.138.968.968 0 01-.335-.994l1.21-4.867L22.653 3.756 5.619 12.662 1.07 8.926C.784 8.704.675 8.398.744 8.01c.075-.377.28-.612.618-.704L26.216.136A.762.762 0 0126.66.13c.172.05.315.146.427.288z"
        fill="#000"
      />
    </Svg>
  );
}

export default SvgComponent;
