import React from 'react';
import PropTypes from 'prop-types';
import { Image, View } from 'react-native';

import styles from './LocationTitle.styles';
import planeIcon from 'assets/plane.png';
import Text from 'components/text/Text';

const LocationTitle = ({ from, to }) => {
  return (
    <View>
      <Text style={styles.location} ellipsizeMode="tail" numberOfLines={1}>
        {from}
        <Image
          source={planeIcon}
          style={styles.planeIcon}
          resizeMode="contain"
        />
      </Text>
      <Text style={styles.location} ellipsizeMode="tail" numberOfLines={1}>
        {to}
      </Text>
    </View>
  );
};

LocationTitle.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default LocationTitle;
