import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

import styles from './Map.styles';
import { device, screen } from 'utils';
import MapComponent from 'components/map';

const { isMobile } = screen;

const mapContaineStyles = {
  borderRadius: 20,
  height: '380px',
  width: isMobile ? 'auto' : '512px',
};

const mapStyles = {
  height: '100%',
};

const key = device.isAndroid
  ? 'AIzaSyBlaRsLRZUKKwFLuFKBqW2PxCcH8Snw6gs'
  : 'AIzaSyBpHCEJp73oU8WYuKiiqcVzQimdcT2HHEI';

const Map = ({ longitudesAndLatitudes }) => {
  const mapProps = device.isAndroid
    ? { longitudesAndLatitudes }
    : {
        isMarkerShown: true,
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: '100%' }} />,
        containerElement: <div style={mapContaineStyles} />,
        mapElement: <div style={mapStyles} />,
        longitudesAndLatitudes,
      };

  return (
    <View style={styles.container}>
      <MapComponent {...mapProps} />
    </View>
  );
};

Map.propTypes = {
  longitudesAndLatitudes: PropTypes.array.isRequired,
};

export default Map;
