import React, { useState } from 'react';
import { View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import styles from './Detail.styles';
import { detail as detailKeys } from 'i18n';
import { screen, theme } from 'utils';
import Modal from './components/Modal';
import Select from 'components/select';
import Text from 'components/text/Text';
import Map from './components/Map';
import JetInfo from './components/JetInfo';
import FlightInfo from './components/FlightInfo';
import ReservationType from './components/ReservationType';
import Reservation from './components/Reservation';

const { PASSENGERS_QUANTITY, SEAT, SEATS, TITLE } = detailKeys;
const { isBigScreen, isMobile } = screen;

const Detail = (props) => {
  const [visible, setVisible] = useState(false);
  const {
    jet,
    paymentMethods,
    price,
    segments,
    userBook,
    flightAirports = [],
    passengers: paxParam,
    emptySeats,
    logged,
  } = props;
  const [passengers, setPassengers] = useState(
    userBook?.passengers || paxParam || 1
  );
  const { category, maxPax, photos, type } = jet;
  const { value, seatPrice } = price;
  const isSeatType = props.type === 'seat';
  const totalSeatsText =
    passengers > 1 ? `${passengers} ${SEATS}` : `${passengers} ${SEAT}`;
  const flightValue =
    userBook?.price || (isSeatType ? seatPrice * passengers : value);

  const transformPosition = (position) => ({
    lat: position.latitude,
    lng: position.longitude,
  });

  const getLongitudesAndLatitudes = () =>
    flightAirports.map((airport) => transformPosition(airport.position));

  const longitudesAndLatitudes = getLongitudesAndLatitudes();

  const getPassengersValues = () => {
    const values = [];
    for (let index = 1; index <= parseInt(emptySeats); index++) {
      values.push({
        id: index,
        text: index,
      });
    }
    return values;
  };

  const renderMap = () =>
    Boolean(flightAirports.length) && (
      <Map longitudesAndLatitudes={longitudesAndLatitudes} />
    );

  const ReservationFooter = () => {
    return (
      <View
        style={[
          styles.reservationContainer,
          isMobile
            ? {
                ...theme.shadow,
                shadowOffset: {
                  width: 0,
                  height: 0,
                },
              }
            : {},
        ]}
      >
        {isSeatType && !userBook && (
          <View>
            <Select
              label={PASSENGERS_QUANTITY}
              values={getPassengersValues()}
              selectedValue={passengers}
              setValue={setPassengers}
            />
          </View>
        )}
        <Reservation
          logged={logged}
          flightValue={flightValue}
          isSeatType={isSeatType}
          paymentMethods={paymentMethods}
          totalSeatsText={totalSeatsText}
          routeParams={props}
          passengers={passengers}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollableContainer}>
        <View style={styles.swiperBackgroundWhite} />
        <View style={styles.columnsContainer}>
          <View style={styles.column}>
            <JetInfo
              category={category}
              maxPax={maxPax}
              photos={photos}
              type={type}
              userBook={userBook}
            />

            {isBigScreen && renderMap()}
          </View>

          <View style={styles.column}>
            {isBigScreen && (
              <View style={styles.titleContainer}>
                <Text style={styles.title}>{TITLE}</Text>
              </View>
            )}

            <FlightInfo segments={segments} />

            <ReservationType isSeatType={isSeatType} />

            {!isBigScreen && renderMap()}

            {!isMobile && <ReservationFooter />}
          </View>
        </View>
      </ScrollView>
      {isMobile && <ReservationFooter />}
      <Modal visible={visible} setVisible={setVisible} />
    </View>
  );
};

export default Detail;
