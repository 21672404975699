import { StyleSheet } from 'react-native';
import { screen } from 'utils';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: screen.isMobile ? 'center' : 'flex-start',
    marginTop: screen.isMobile ? 0 : 200,
    alignItems: 'center',
  },
  text: { fontSize: 40, fontStyle: 'italic' },
  backgroundImage: {
    height: 130,
    opacity: 1,
    width: 250,
    marginTop: 20,
    marginHorizontal: 'auto',
    alignSelf: 'center',
  },
});

export default styles;
