import React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={28}
      height={24}
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M27.154 21.176c-.13.367-.375.572-.734.616l-20.757 2.17c-.262.025-.493-.067-.693-.279a.905.905 0 01-.211-.402 1.045 1.045 0 01-.019-.354l.823-7.009-4.655-2.272c-.24-.11-.396-.31-.467-.598a.84.84 0 01-.025-.322.945.945 0 01.199-.513.832.832 0 01.432-.297l4.515-1.304 16.694 8.093L6.584 7.252l.727-6.114c.035-.38.226-.634.573-.764.34-.118.632-.043.874.226l18.187 19.62a.964.964 0 01.21.956z"
        fill="#000"
      />
    </Svg>
  );
}

export default SvgComponent;
