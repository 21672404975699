import { StyleSheet } from 'react-native';

import { screen, theme } from 'utils';

const { isBigScreen } = screen;

const styles = StyleSheet.create({
  container: {
    height: 190,
    justifyContent: 'center',
    marginTop: isBigScreen ? 20 : 10,
    maxWidth: isBigScreen ? 'none' : 500,
    width: '100%',
  },
  backgroundContainer: {
    height: 190,
    position: 'absolute',
    width: '100%',
  },
  image: {
    flex: 1,
  },
  flightDuration: {
    flex: 1,
  },
  duration: {
    bottom: 10,
    color: theme.colors.secondary,
    fontSize: 14,
    textAlign: 'center',
  },
  info: {
    flexDirection: 'row',
  },
  infoColumn: {
    width: '33%',
  },
  alignRight: {
    textAlign: 'right',
  },
  date: {
    fontSize: 14,
  },
  time: {
    fontSize: 24,
    fontWeight: '700',
  },
  code: {
    fontSize: 30,
    fontWeight: '700',
    marginTop: 10,
  },
  airport: {
    fontSize: 16,
  },
});

export default styles;
