import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { FAB } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import * as Location from 'expo-location';

import styles from './OffersContainer.styles';
import { getClosestAirports, getOffers, getOpenOffers } from 'api';
import { constants, device, routes } from 'utils';
import FlightThumbnail from 'components/flight-thumbnail';
import FlightThumbnailSkeleton from 'components/flight-thumbnail-skeleton';
import { theme } from 'utils';
import Text from 'components/text/Text';
import { search } from 'i18n';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import NoResults from 'components/noResults';
import { noResults } from 'i18n';

const { thumbnailWidth } = theme;
const { storage } = device;
const { ERRORS } = constants;

const OffersContainer = () => {
  const [offers, setOffers] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [fromLocation, setFromLocation] = useState(null);
  const navigation = useNavigation();
  const { setSnackbar } = useContext(SnackbarContext);

  const onGetClosestAirportsSuccess = (res) => {
    storage.setItem('fromLocationHome', res);
    storage.setItem('fromLocation', { ...res, airports: [res.airports[0]] });
    setFromLocation(res);
  };

  const onGetClosestAirportsError = (err) => {
    setSnackbar({
      message: ERRORS.DEFAULT,
      visible: true,
    });
  };

  const setOffersWithPrev = (data) => {
    setOffers((prev) => {
      if (!prev || !prev.length) return [...data];
      else if (data && data.length) return [...prev, ...data];
      return [...prev];
    });
  };

  const loadOpenOffers = async () => {
    try {
      const data = (await getOpenOffers()).data;
      if (data && data.length) setOffersWithPrev(data);
    } catch (e) {}
  };

  const loadOffers = async (fromLocation) => {
    if (fromLocation) {
      // fromLocation.airports.forEach(async (airport) => {
      try {
        const data = (
          await getOffers({
            fromLocation: {
              ...fromLocation,
              airports: [fromLocation.airports[0]],
            },
          })
        ).data;
        setOffersWithPrev(data);
      } catch (e) {
        console.log('error', e);
      }
      // });
    }
  };

  useEffect(() => {
    if (fromLocation?.id === 'unknown') {
      setOffersWithPrev([]);
    } else {
      loadOffers(fromLocation);
    }
  }, [fromLocation]);

  useEffect(() => {
    loadOpenOffers();
    storage.getItem('fromLocationHome').then((location) => {
      if (location) {
        setFromLocation(location);
      } else {
        (async () => {
          let { status } = await Location.requestForegroundPermissionsAsync();
          if (status !== 'granted') {
            setFromLocation({
              id: 'unknown',
            });
            return;
          }
          let location = await Location.getCurrentPositionAsync({});
          setLatitude(location.coords.latitude);
          setLongitude(location.coords.longitude);
        })();
      }
    });
    setTimeout(() => {
      setOffersWithPrev([]);
    }, 40000);
  }, []);

  useEffect(() => {
    if (latitude && longitude) {
      getClosestAirports({
        position: {
          longitude,
          latitude,
        },
      })
        .then((res) => {
          onGetClosestAirportsSuccess(res);
        })
        .catch((error) => {
          onGetClosestAirportsError();
        });
    }
  }, [latitude, longitude]);

  const onEditFromLocation = () => {
    navigation.push(routes.SEARCH, { type: 'fromLocationHome' });
  };

  function compareOffers(a, b) {
    const isOpenFlight = (flight) => flight.status === 'open';
    if (isOpenFlight(a) && !isOpenFlight(b)) {
      return -1;
    }
    if (!isOpenFlight(a) && isOpenFlight(b)) {
      return 1;
    }
    return 0;
  }

  const sortedOffers = offers?.sort(compareOffers);

  return (
    <View style={styles.containerWhite}>
      {!!fromLocation && (
        <View style={styles.titleContainer}>
          <Text style={styles.title} ellipsizeMode="tail" numberOfLines={2}>
            {fromLocation?.id === 'unknown' ? (
              <>Cambiar Localidad</>
            ) : (
              <>
                Ofertas desde{' '}
                <Text style={styles.origin}>{fromLocation.city}</Text>
              </>
            )}
          </Text>
          <FAB
            style={styles.fab}
            small={true}
            icon="pencil"
            onPress={onEditFromLocation}
            color={theme.colors.white}
          />
        </View>
      )}
      <View style={styles.offersContainer}>
        {offers ? (
          sortedOffers.map((offer) => (
            <FlightThumbnail
              source={'home'}
              key={offer?.jet?.id}
              {...offer}
              titleType="trip"
            />
          ))
        ) : (
          <>
            <FlightThumbnailSkeleton />
            <FlightThumbnailSkeleton />
            <FlightThumbnailSkeleton />
            <FlightThumbnailSkeleton />
          </>
        )}
        {!!(offers?.length === 0) &&
          (fromLocation?.id === 'unknown' ? (
            <NoResults message={noResults.NO_GPS} />
          ) : (
            <NoResults city={fromLocation?.city} />
          ))}
        {!!(offers?.length !== 0) && (
          <>
            <View
              style={{ width: thumbnailWidth }}
              className="fill"
              key="fill-1"
              titleType="trip"
            />
            <View
              style={{ width: thumbnailWidth }}
              className="fill"
              key="fill-2"
              titleType="trip"
            />
            <View
              style={{ width: thumbnailWidth }}
              className="fill"
              key="fill-3"
              titleType="trip"
            />
          </>
        )}
      </View>
    </View>
  );
};

export default OffersContainer;
