import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, View } from 'react-native';
import { TextInput } from 'react-native-paper';

import styles from './IconButton.styles';
import ArrivalIcon from 'assets/arrival.png';
import Text from 'components/text/Text';

const IconButton = ({ disabled, label, onPress }) => {
  const newArrivalButtonDisabled = disabled
    ? styles.newArrivalButtonDisabled
    : {};

  return (
    <View style={[styles.newArrivalButton, newArrivalButtonDisabled]}>
      <TouchableOpacity
        style={styles.newArrivalButtonContent}
        onPress={onPress}
        disabled={disabled}
      >
        <View style={styles.newArrivalButtonIcon}>
          <TextInput.Icon icon={ArrivalIcon} color="white" size={24} />
        </View>
        <Text style={styles.newArrivalButtonText}>{label}</Text>
      </TouchableOpacity>
    </View>
  );
};

IconButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onPress: PropTypes.func,
};

IconButton.defaultProps = {
  disabled: false,
  onPress: null,
};

export default IconButton;
