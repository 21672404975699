import { getBannerProducts } from 'api/flights';
import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/core';

import BannerCarousel from './BannerCarousel';

const CommissionsCarousel = () => {
  const [commissions, setCommissions] = useState(null);
  const navigation = useNavigation();

  useEffect(() => {
    (async () => {
      const bannerProducts = await getBannerProducts();
      setCommissions(bannerProducts.data);
    })();
  }, []);

  return <BannerCarousel commissions={commissions} navigation={navigation} />;
};

export default CommissionsCarousel;
