import { StyleSheet } from 'react-native';

import { theme } from 'utils';

const { background, secondary } = theme.colors;

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexShrink: 1,
  },
  scrollableContainer: {
    flex: 1,
  },
  buttomContainer: {
    backgroundColor: background,
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 40,
  },
  buttonActionContainer: {
    alignSelf: 'center',
    borderRadius: theme.radius,
    maxWidth: 500,
    width: '100%',
  },
  button: {
    paddingHorizontal: 15,
    paddingVertical: 8,
    borderRadius: theme.radius,
    elevation: 10,
    shadowOffset: { width: 0, height: 15 },
    shadowColor: secondary,
    shadowOpacity: 0.5,
    shadowRadius: 15.0,
  },
  text: {
    fontSize: 24,
    fontWeight: '700',
    letterSpacing: 0,
    textTransform: 'capitalize',
  },
});

export default styles;
