import { StyleSheet } from 'react-native';

import { screen, theme } from 'utils';

const { isMobile } = screen;
const { primary, secondary, white } = theme.colors;

const styles = StyleSheet.create({
  containerWrapper: {
    backgroundColor: theme.colors.primary,
    marginBottom: 40,
  },
  container: {
    alignSelf: isMobile ? 'auto' : 'center',
    backgroundColor: primary,
    paddingHorizontal: 24,
    paddingBottom: 24,
    width: isMobile ? 'auto' : 500,
  },
  multipleTripsContainer: {
    marginBottom: 10,
  },
  location: {
    color: white,
    fontSize: 16,
    fontWeight: '700',
  },
  text: {
    color: 'white',
    fontSize: 16,
  },
  fab: {
    backgroundColor: secondary,
    position: 'absolute',
    right: 0,
    bottom: -45,
    elevation: 10,
    shadowOffset: { width: 0, height: 15 },
    shadowColor: secondary,
    shadowOpacity: 0.5,
    shadowRadius: 15.0,
  },
});

export default styles;
