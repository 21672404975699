import { StyleSheet } from 'react-native';

import { screen, theme } from 'utils';
const { desktopInitialResolution, isMobile } = screen;

const { background, lightGrey, primary } = theme.colors;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    height: '100%',
  },
  header: {
    alignItems: 'center',
    backgroundColor: primary,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  noResultsContainer: {
    backgroundColor: lightGrey,
    padding: 20,
    borderRadius: 5,
    width: '80%',
    maxWidth: desktopInitialResolution,
    height: isMobile ? 'auto' : 100,
    maxWidth: 800,
    justifyContent: 'center',
    marginTop: 20,
    marginHorizontal: 'auto',
    alignSelf: 'center',
  },
  noResultsText: {
    textAlign: 'center',
    fontSize: 16,
  },
  backgroundImage: {
    alignSelf: 'center',
    marginTop: isMobile ? 80 : 20,
    opacity: 1,
    width: isMobile ? '100%' : '60%',
    aspectRatio: 16 / 9,
  },
});

export default styles;
