import { StyleSheet } from 'react-native';

import { screen, theme } from 'utils';

const { isMobile, isBigScreen } = screen;
const { white } = theme.colors;

const styles = StyleSheet.create({
  swiperContainer: {
    height: isMobile ? 270 : 380,
    marginHorizontal: 'auto',
    maxWidth: isBigScreen ? 'none' : 700,
    position: 'relative',
    width: '100%',
  },
  slideContainer: {
    backgroundColor: white,
    borderRadius: 20,
    height: isMobile ? 230 : 340,
    marginHorizontal: 20,
    marginTop: 10,
  },
  image: {
    borderRadius: 20,
    flex: 1,
  },
  info: {
    marginHorizontal: 'auto',
    marginTop: -20,
    maxWidth: 700,
    paddingHorizontal: 20,
    width: '100%',
  },
  jetModel: {
    fontSize: 18,
    fontWeight: '700',
  },
  jetDescription: {
    fontSize: 14,
    marginTop: 5,
  },
});

export default styles;
