import { errors } from 'i18n';

const errorTranslate = (err, fallback) => {
  const error = err?.toUpperCase?.();
  if (error.includes('EMAIL_NOT_FOUND')) return errors.EMAIL_NOT_FOUND;
  if (error.includes('THE EMAIL ADDRESS IS IMPROPERLY FORMATTED'))
    return errors.EMAIL_FORMAT;
  if (
    error.includes(
      'The password must be a string with at least 6 characters'.toUpperCase()
    )
  )
    return errors.PASSWORD_LENGTH;
  if (
    error.includes(
      'The email address is already in use by another account'.toUpperCase()
    )
  ) {
    return errors.EMAIL_USED;
  }
  if (error.includes('STRIPE_CANCELED')) return errors.STRIPE_CANCELLED;
  return fallback || err;
};

export default errorTranslate;
