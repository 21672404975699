import { axios } from './axios';
import firebase from 'firebase';

export const logIn = ({ data, onSuccess, onError }) => {
  const { email, password } = data;

  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((userCredentials) => {
      if (!userCredentials.user.emailVerified) {
        onError({
          request: {
            status: 403,
          },
        });
        firebase.auth().signOut();
      } else {
        onSuccess(userCredentials);
      }
    })
    .catch((error) => onError(error));
};

export const recoverPassword = ({ data, onSuccess, onError }) =>
  axios.put('/user/change-password', data).then(onSuccess).catch(onError);
