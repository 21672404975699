import { StyleSheet } from 'react-native';

import { theme } from 'utils';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginTop: 200,
  },
  image: {
    height: 36,
    position: 'absolute',
    top: 6,
    width: 36,
  },
  text: {
    color: theme.colors.primary,
    fontSize: 16,
    fontStyle: 'italic',
    marginTop: 10,
  },
});

export default styles;
