import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

import styles from './ToggleButtons.styles';
import Button from '../custom-button';

const ToggleButtons = ({
  values,
  selectedValue,
  onPress,
  buttonStyle = {},
  containerStyle = {},
}) => {
  const getButtonStyle = (value) => {
    const buttonStyles = [styles.button, buttonStyle];
    if (value.disabled) buttonStyles.push(styles.buttonDisabled);
    if (value.id === selectedValue) buttonStyles.push(styles.buttonChecked);
    return buttonStyles;
  };
  return (
    <View style={[styles.container, containerStyle]}>
      {values.map((value) => (
        <Button
          key={value.id}
          style={getButtonStyle(value)}
          labelStyle={
            value.id === selectedValue
              ? [styles.buttonLabelStyle, styles.buttonLabelStyleChecked]
              : styles.buttonLabelStyle
          }
          selected={value.id === selectedValue}
          onPress={() => onPress(value.id)}
          text={value.text}
          icon={value.icon}
          disabled={value.disabled}
        ></Button>
      ))}
    </View>
  );
};

ToggleButtons.propTypes = {
  values: PropTypes.array.isRequired,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onPress: PropTypes.func.isRequired,
  buttonStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
};

ToggleButtons.defaultProps = {
  buttonStyle: {},
  containerStyle: {},
};

export default ToggleButtons;
