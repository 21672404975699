import { constants, date } from 'utils';
import { dateSelected, innerDateSelected } from './data';

const {
  FLIGHT_TYPES: { ONE_WAY, ROUND_TRIP, MULTI_LEG },
} = constants;
const { getStringDate } = date;

// Devuelve los estilos de acuerdo a:
// - Si es una fecha seleccionada (`!isInner`)
// - Si una fecha contemplada entre dos fechas seleccionadas. (`isInner`)
const getDateStyles = ({ isInner, timestamp }) => {
  if (!isInner) {
    return { ...dateSelected, timestamp };
  }
  return { ...innerDateSelected, timestamp };
};

// Para saber si la fecha seleccionada es mayor a la primera seleccionada.
const isCurrentDateGreater = (newMarkedDates, dateString) => {
  const currentDate = new Date(dateString);
  const initialDateString = Object.keys(newMarkedDates)?.[0];
  const initialDate = initialDateString && new Date(initialDateString);
  return !initialDate || currentDate.getTime() >= initialDate.getTime();
};

// Este método se encarga de volver a crear las fechas de `markedDates` luego de cambiar el `flightType`.
// Es decir, el usuario seleccionó ROUND_TRIP o MULTI_LEG y eligió 2 fechas (o más para el caso de MULTI_LEG).
// Si vuelve a cambiar a ONE_WAY, como no puede tener 2 fechas seleccionadas, solo dejamos la primera.
// Lo mismo sucede si pasara de MULTI_LEG a ROUND_TRIP, solo que en este caso dejaría las dos primeras fechas.
const rebuildMarkedDatesFromTimestamps = ({
  markedDates,
  flightType,
  timestamps,
  setMarkedDates,
  setSelected,
}) => {
  const newMarkedDates = {};
  const newMarkedDatesArray = Object.entries(markedDates);
  const selectedDatesByUser = newMarkedDatesArray.filter((dateArr) => {
    const currentDateTimestamp = dateArr[1].timestamp;

    if (flightType === ONE_WAY) {
      return currentDateTimestamp === timestamps[0];
    }

    // TODO: Acá seguramente tenga que iterar timestamps para los casos de MULTI_LEG
    return (
      currentDateTimestamp === timestamps[0] ||
      currentDateTimestamp === timestamps[1]
    );
  });

  selectedDatesByUser.forEach(
    (selectedDate) => (newMarkedDates[selectedDate[0]] = selectedDate[1])
  );

  selectInnerDates(newMarkedDates);
  setMarkedDates(newMarkedDates);
  setSelected(timestamps?.length);
};

// Se encarga de generar los objetitos visuales para las fechas contempladas entre fechas seleccionadas.
const selectInnerDates = (newMarkedDates, timestamp, todayTimestamp) => {
  const newMarkedDatesArray = Object.entries(newMarkedDates);
  const selectedDatesByUser = newMarkedDatesArray.filter(
    (dateArr) => dateArr[1].startingDay || dateArr[1].endingDay
  );
  const startDate = new Date(selectedDatesByUser[0][0]);
  const endDate = new Date(
    selectedDatesByUser[selectedDatesByUser.length - 1][0]
  );
  const isASelectedDateByUser = (dateStr) =>
    !!selectedDatesByUser.find((dateArr) => dateArr[0] === dateStr);

  for (
    let currentDate = new Date(todayTimestamp);
    currentDate < endDate;
    currentDate.setDate(currentDate.getDate() + 1)
  ) {
    if (currentDate < startDate || currentDate < endDate) {
      const currentStringDate = getStringDate(currentDate);
      if (!isASelectedDateByUser(currentStringDate)) {
        newMarkedDates[currentStringDate] = getDateStyles({
          isInner: true,
          timestamp,
        });
      }
    }
  }
};

export {
  getDateStyles,
  isCurrentDateGreater,
  rebuildMarkedDatesFromTimestamps,
  selectInnerDates,
};
