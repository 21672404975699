import React from 'react';
import PropTypes from 'prop-types';

import { View } from 'react-native';
import { IconButton, Title, Text } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import styles from './Navbar.styles';
import { methods, routes, theme } from 'utils';
import logo from 'assets/logo.png';
import logoWhite from 'assets/logo-white.png';
import LogoComponent from './components/Logo';

const { makeCall, whatsappMessage } = methods;
import Menu from './components/Menu';

const Navbar = ({ title, type, logged }) => {
  const navigation = useNavigation();
  const isInternal = type === 'internal';
  let containerClass = [styles.container, styles.internalContainer];
  if (type === 'home') containerClass = [containerClass, styles.home];
  const iconsColor = theme.colors.white;

  const onBackHandler = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate(routes.HOME);
    }
  };

  if (type === 'logo') {
    return (
      <View style={containerClass}>
        <View style={styles.logoContainer}>
          <LogoComponent source={logo} />
        </View>
      </View>
    );
  }

  return (
    <View style={containerClass}>
      {isInternal ? (
        <>
          <IconButton
            icon="chevron-left"
            size={36}
            color="white"
            onPress={onBackHandler}
            testID="chevron-left"
          />
          {/* <Text style={styles.sectionTitle} testID="title">
            {title}
          </Text> */}
        </>
      ) : (
        <LogoComponent source={logoWhite} />
      )}
      <Title style={styles.title}>
        {isInternal && <LogoComponent source={logoWhite} />}
      </Title>

      <IconButton
        icon="whatsapp"
        size={28}
        onPress={() => whatsappMessage()}
        color={iconsColor}
        testID="whatsapp-icon"
      />

      <IconButton
        icon="phone-in-talk"
        size={28}
        onPress={makeCall}
        color={iconsColor}
        testID="phone-icon"
      />


      <Menu iconsColor={iconsColor} logged={logged} />
    </View>
  );
};

Navbar.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
};

Navbar.defaultProps = {
  title: '',
  type: '',
};

export default Navbar;
