import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const promise = loadStripe(
  'pk_live_51JdHofGe6zKZHX7KuOGTzRe91srgOmbzOqcbdAHgv0GjRdzxGjaDC1SZ4wk9y5Ywl7POS12U4elGbZAHp5ETfCVh00btmCi7SX'
  //'pk_test_51JdHofGe6zKZHX7KcfG2nw4SWJmITKt4iw4hGJFfTECCQMY8Ugcj4y8eFWzf9AkQqvp4yN6fiPmeY2w5ryXORfAp00vlhsOZbd'
  );

export default function PaymentsProvider({ children }) {
  return <Elements stripe={promise}>{children}</Elements>;
}
