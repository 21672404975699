import React, { useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import { useRoute } from '@react-navigation/native';

import { getFlights } from 'api';
import ResultsContainer from 'containers/results-container';
import SearchInfo from 'components/search-info';

const Results = () => {
  const route = useRoute();
  const { bookType, flightType, passengers, segments, flightAirports } =
    route.params;
  const [flights, setFlights] = useState(null);
  const [filteredFlights, setFilteredFlights] = useState(null);
  const [categories, setCategories] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState(null);

  const getCategories = (flights) => [
    ...new Set(flights.map((flight) => flight.jet.category)),
  ];

  const onSuccess = (res) => {
    setFlights(res.data);
    setFilteredFlights(res.data);
    setCategories(getCategories(res.data));
  };

  const onError = (err) => {
    console.log('Error: ', err);
    setFlights([]);
    setFilteredFlights([]);
    setCategories([]);
  };

  useEffect(() => {
    getFlights({ data: route.params, onSuccess, onError });
  }, []);

  useEffect(() => {
    if (flights) {
      const filtered = flights.filter((flight) =>
        selectedCategories.includes(flight.jet.category)
      );
      setFilteredFlights(filtered);
    }
  }, [selectedCategories]);

  return (
    <ScrollView style={{backgroundColor:'#fff'}}>
      <SearchInfo
        bookType={bookType}
        flightType={flightType}
        passengers={passengers}
        segments={segments}
        categories={categories}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />
      <ResultsContainer
        flights={filteredFlights}
        passengers={passengers}
        flightAirports={flightAirports}
      />
    </ScrollView>
  );
};

export default Results;
