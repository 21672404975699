import React from 'react';
import { IconButton, Modal, Portal } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';

import styles from './Modal.styles';
import { detail as detailKeys } from 'i18n';
import { routes } from 'utils';
import Button from 'components/button';
import Text from 'components/text/Text';

const { BOOKING_SUCCESS, BOOKING_FEEDBACK, MODAL_BUTTON } = detailKeys;
const { HOME } = routes;

const ModalComponent = ({ visible, setVisible }) => {
  const navigation = useNavigation();

  const onModalPress = () => {
    setVisible(false);
    navigation.navigate(HOME);
  };

  return (
    <Portal style={styles.container}>
      <Modal visible={visible} contentContainerStyle={styles.container}>
        <IconButton icon="check" size={50} color="green" style={styles.icon} />
        <Text style={styles.title}>{BOOKING_SUCCESS}</Text>
        <Text style={styles.text}>{BOOKING_FEEDBACK}</Text>
        <Button buttonStyles={styles.button} onPress={onModalPress}>
          <Text style={styles.buttonText}>{MODAL_BUTTON}</Text>
        </Button>
      </Modal>
    </Portal>
  );
};

export default ModalComponent;
