import { axios } from './axios';

export const getOffers = async (data) => {
  return axios.post('/offers/', data);
};

export const getBannerProducts = async (data) => {
  return axios.get('/offers/banner-products', data);
};

export const getOpenOffers = async () => axios.get('/offers/open-offers');

export const getAirports = (filter) =>
  axios.get(`flights/airports?filter=${filter}`).then((res) => {
    let result = [];
    if (res?.data) {
      // Iteramos cada uno de los lugares que trae la API.
      res.data.forEach((place) => {
        const { airports, city, province, country } = place;

        // Iteramos por cada uno de los aeropuertos que contiene cada uno de estos lugares.
        for (let i = 0; i < airports.length; i++) {
          const location = {
            city,
            province,
            country,
            airports: [airports[i]],
          };

          result.push(location);
        }
      });
    }
    return result;
  });

export const getLocations = (filter) =>
  axios.get(`flights/airports?filter=${filter}`);

// TODO: Mover a offers.
export const getClosestAirports = ({ position, onSuccess, onError }) => {
  const { latitude, longitude } = position;
  return axios
    .get(`/offers/closest-airports?latitude=${latitude}&longitude=${longitude}`)
    .then((res) => {
      const { airports, city, province, country } = res.data;
      return {
        airports,
        city,
        province,
        country,
      };
    });
};

export const getFlights = ({ data, onSuccess, onError }) =>
  axios.post(`flights/search-flights`, data).then(onSuccess).catch(onError);

export const postFlight = async ({ data }) => axios.post('flights', data);

export const getBookings = ({ onSuccess, onError }) =>
  axios
    .get('flights/bookings/GJt82QAbyjWkpfkCehzOwKVrt513') // TODO: sacar hardcodeo.
    .then(onSuccess)
    .catch(onError);

export const putPayment = ({ data, onSuccess, onError }) => {
  const { file, flightBookId, userBookCode, paymentMethodId } = data;

  return axios
    .put(
      `flights/${flightBookId}/${userBookCode}/payment/${paymentMethodId}`,
      file,
      {
        headers: {
          'Content-Type': 'application/pdf',
        },
      }
    )
    .then(onSuccess)
    .catch(onError);
};

export const getFlightPaymentSheet = async (flightBookId, userBookCode) =>
  axios.post(`flights/${flightBookId}/${userBookCode}/stripe/payment-intent`);

export const getTermsAndConditions = async () =>
  axios.get(`terms-and-conditions/`);

export const getFlightDetails = async (flightId) =>
  axios.get(`flights/${flightId}`);
