import {
  bookTypes as bookTypesKeys,
  flightTypes as flightTypesKeys,
} from 'i18n';

const { FULL_FLIGHT, SEATS_FLIGHT } = bookTypesKeys;
const { ONE_WAY, ROUND_TRIP, MULTI_LEG } = flightTypesKeys;

const flightTypeValues = [
  { id: 'oneWay', text: ONE_WAY },
  { id: 'roundTrip', text: ROUND_TRIP },
  { id: 'multiLeg', text: MULTI_LEG },
];

const bookTypesValues = [
  { id: 1, text: FULL_FLIGHT, value: 'FLIGHT' },
  { id: 2, text: SEATS_FLIGHT, value: 'SEAT' },
];

export { flightTypeValues, bookTypesValues };
