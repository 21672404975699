import React, { useEffect, useState, useContext } from 'react';
import { View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';

import styles from './SearchConfig.styles';
import {
  flightTypeValues,
  bookTypesValues,
  passengersValues,
  flightTypeLabelMap,
  bookTypesLabelMap,
} from './data';
import { searchConfig as searchConfigKeys } from 'i18n';
import { constants, device, methods, routes, theme } from 'utils';
import ToggleButtons from 'components/toggle-buttons/ToggleButtons';
import Select from 'components/select';
import ToLocations from 'containers/locations-container';
import BottomButton from 'components/bottom-button';
import FakeTextInput from 'components/fake-text-input';
import { PlaneDeparture } from 'components/icons';
import Text from 'components/text/Text';
import { SnackbarContext } from 'contexts/SnackbarContext';
import LazyHOC from 'components/lazy-hoc/LazyHoc';
import NoSeatsDialog from './components/NoSeatsDialog';
import useAuth from '../../oauth';

const { SEARCH_BUTTON } = constants.METRICS;
const { storage } = device;
const { triggerMetric } = methods;
const { DATE_CONFIG, SEARCH, SEARCH_FROM_LOCATION } = routes;
const {
  CURRENT_LOCATION,
  PASSENGERS_QUANTITY,
  SET_FROM_LOCATION,
  JUST,
  SET_TO_LOCATION,
} = searchConfigKeys;

const SearchConfig = () => {
  const navigation = useNavigation();
  const { user } = useAuth();
  const route = useRoute();
  const {
    fromLocation: fromLocationParam,
    toLocations: toLocationsParam,
    flightType: flightTypeParam,
  } = route.params;
  const [fromLocation, setFromLocation] = useState(null);
  const [toLocations, setToLocations] = useState([]);
  const [fromLabel, setFromLabel] = useState('');
  const [flightType, setFlightType] = useState(flightTypeParam || 'oneWay');
  const [bookType, setBookType] = useState(1);
  const [passengers, setPassengers] = useState(1);
  const { setSnackbar } = useContext(SnackbarContext);

  const getLabel = ({ airports, city, country }) => [
    airports[0].name,
    `${city}, ${country.name.toUpperCase()}`,
  ];

  const onSetFlightTypeHandler = (value) => {
    setToLocations([toLocations[0]]);
    setFlightType(value);
    if (value !== 1) setBookType(1);
  };

  const triggerSearchMetric = () => {
    const origen = fromLocation?.city;
    const destino = toLocations?.[0]?.city;
    triggerMetric({
      event: SEARCH_BUTTON,
      userId: user,
      origen,
      destino,
    });
  };

  const onSearchHandler = () => {
    triggerSearchMetric();

    if (!fromLocation) {
      return setSnackbar({
        message: SET_FROM_LOCATION,
        visible: true,
      });
    }
    if (!toLocations || !toLocations[0]) {
      return setSnackbar({
        message: SET_TO_LOCATION,
        visible: true,
      });
    }
    if (fromLocation && toLocations?.length) {
      navigation.navigate(DATE_CONFIG, {
        bookTypeId: bookTypesValues.find((bt) => bt.id === bookType).id,
        passengers,
        fromLocation,
        toLocations,
        flightType,
      });
    }
  };

  const handleFocus = ({ type, location }) => {
    let screen = SEARCH;
    if (type === 'fromLocation') {
      screen = SEARCH_FROM_LOCATION;
    }
    navigation.navigate(screen, { type, location });
  };

  useEffect(() => {
    storage.getItem('fromLocation').then((res) => {
      setFromLocation(res);
    });
  }, []);

  useEffect(() => {
    if (fromLocation) {
      const label = getLabel(fromLocation);
      storage.setItem('fromLocation', fromLocation);
      setFromLabel(label);
    }
  }, [fromLocation]);

  useEffect(() => {
    setFromLocation(fromLocationParam);
  }, [fromLocationParam]);

  useEffect(() => {
    setToLocations(toLocationsParam || [null]);
  }, [toLocationsParam]);

  const isSeatDisabled = () => {
    if (flightType !== 'oneWay') return true;
    if (toLocations?.length) {
      const toLocation = toLocations[0];
      if (toLocation.city.toUpperCase() === 'MIAMI') return false;
      if (
        ['SAME', 'SULS', 'SACO', 'SAZY', 'SARI', 'SBGL', 'SBFL'].includes(
          toLocation.airports[0].code
        )
      )
        return false;
      return true;
    }
    return false;
  };

  const seatDisabled = isSeatDisabled();

  const getBookTypesValues = () =>
    bookTypesValues.map((bookType) => ({
      ...bookType,
      disabled: bookType.id === 2 && seatDisabled,
    }));
  const getBookTypesLabel = () => {
    if (seatDisabled) return JUST + ' ' + 'avión';
    return bookTypesLabelMap[bookType];
  };

  return (
    // <LazyHOC>
    <BottomButton buttonText="Continuar" onPress={onSearchHandler}>
      <View style={styles.container}>
        <View style={styles.toggleButtonsContainer}>
          <View style={{ alignItems: 'center' }}>
            <ToggleButtons
              values={flightTypeValues}
              selectedValue={flightType}
              onPress={onSetFlightTypeHandler}
              buttonStyle={styles.buttonStyle}
              containerStyle={styles.containerStyle}
            />
            <Text style={styles.toggleButtonLabel}>
              {flightTypeLabelMap[flightType]}
            </Text>
          </View>
          <View style={{ alignItems: 'center' }}>
            <ToggleButtons
              values={getBookTypesValues()}
              selectedValue={bookType}
              onPress={setBookType}
              buttonStyle={styles.buttonStyle}
              containerStyle={styles.containerStyle}
            />
            <Text style={styles.toggleButtonLabel}>
              {getBookTypesLabel()}
              {seatDisabled && <NoSeatsDialog />}
            </Text>
          </View>
        </View>

        <View style={styles.locations}>
          <View style={styles.locationsContainer}>
            <FakeTextInput
              Icon={PlaneDeparture}
              onPress={() =>
                handleFocus({ type: 'fromLocation', location: fromLocation })
              }
              text={fromLabel || CURRENT_LOCATION}
              textStyles={styles.fromInput}
            />
          </View>

          <ToLocations
            locations={toLocations}
            flightType={flightType}
            onRemoveLocation={setToLocations}
          />
        </View>
      </View>
      <Select
        label={PASSENGERS_QUANTITY}
        values={passengersValues}
        selectedValue={passengers}
        setValue={setPassengers}
      />
    </BottomButton>
    // </LazyHOC>
  );
};

export default SearchConfig;
