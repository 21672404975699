import React from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-native-web-swiper';

import { screen, theme } from 'utils';

const { lightGrey, mediumGrey, primary, secondary } = theme.colors;
const { isMobile } = screen;

const defaultDotsWrapperStyle = {
  marginBottom: 60,
};
const defaultBadgeStyle = {
  backgroundColor: lightGrey,
  width: isMobile ? 8 : 10,
  height: isMobile ? 8 : 10,
  borderRadius: isMobile ? 8 : 10,
  marginHorizontal: isMobile ? 0 : 5,
};

const SwiperComponent = ({
  children,
  dotsWrapperStyle = defaultDotsWrapperStyle,
  badgeStyle = defaultBadgeStyle,
}) => (
  <Swiper
    controlsProps={{
      prevPos: false,
      nextPos: false,
      dotsPos: 'bottom',
      dotsWrapperStyle,
      dotProps: {
        badgeStyle,
      },
      dotActiveStyle: {
        backgroundColor: secondary,
      },
      dotsTouchable: isMobile ? false : true,
    }}
    loadMinimal={false}
    removeClippedSubviews={false}
  >
    {children}
  </Swiper>
);

SwiperComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SwiperComponent;
