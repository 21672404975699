import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

import styles from './FlightInfo.styles';
import { date, methods } from 'utils';
import { PlaneFlying } from '../icons';
import Image from '../image';
import Text from '../text/Text';
import worldBackground from 'assets/world-background.png';

const { getTimeBetweenTwoTimestamps } = date;
const { capitalize } = methods;

const FlightDuration = ({ time }) => (
  <View style={styles.flightDuration}>
    <PlaneFlying width={'100%'} />
    <Text style={styles.duration}>{time}</Text>
  </View>
);

const FlightInfo = ({ fromLocation, toLocation }) => {
  const renderInfoColumn = ({ date, time, code, airport, alignRight }) => (
    <View style={styles.infoColumn}>
      <Text style={alignRight ? [styles.date, styles.alignRight] : styles.date}>
        {date}
      </Text>
      <Text style={alignRight ? [styles.time, styles.alignRight] : styles.time}>
        {time}
      </Text>
      <Text style={alignRight ? [styles.code, styles.alignRight] : styles.code}>
        {code}
      </Text>
      <Text
        style={
          alignRight ? [styles.airport, styles.alignRight] : styles.airport
        }
      >
        {capitalize(airport)}
      </Text>
    </View>
  );

  const getTripTime = () => {
    const tripTime = getTimeBetweenTwoTimestamps(
      fromLocation.timestamp,
      toLocation.timestamp
    );
    const { hours, minutes } = tripTime;

    return `${hours}h ${minutes}m`;
  };

  return (
    <View style={styles.container}>
      <View style={styles.backgroundContainer}>
        <Image source={worldBackground} style={styles.image} />
      </View>
      <View style={styles.info}>
        {renderInfoColumn(fromLocation)}
        <FlightDuration time={getTripTime()} />
        {renderInfoColumn({ ...toLocation, alignRight: true })}
      </View>
    </View>
  );
};

FlightInfo.propTypes = {
  fromLocation: PropTypes.object.isRequired,
  toLocation: PropTypes.object.isRequired,
};

export default FlightInfo;
