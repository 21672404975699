import { StyleSheet } from 'react-native';

import { screen } from 'utils';

const { isBigScreen } = screen;

const styles = StyleSheet.create({
  segmentsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginHorizontal: 'auto',
    marginTop: isBigScreen ? 20 : 0,
    maxWidth: 1300,
    padding: 20,
    width: isBigScreen ? '100%' : 'auto',
  },
});

export default styles;
