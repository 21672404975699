import React, { useState, useEffect, useContext } from 'react';
import { View, TextInput } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { putPayment } from 'api/flights';
import Text from 'components/text/Text';
import Button from 'components/button';
import { Modal } from 'react-native-paper';
import { useRoute } from '@react-navigation/core';
import styles from './DecidirForm.styles';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import errorTranslate from 'i18n/errorTranslate';
import { routes, theme } from 'utils';
import { stripeForm as stripeFormKeys } from 'i18n';
import Spinner from 'components/spinner';
import MaskInput from 'react-native-mask-input';
import { getDecidirToken, postDecidirPayment } from '../../api/decidir';

const { PAY, SUCCESSFUL_PAYMENT, TITLE, CVC, CARD_NUMBER, EXPIRATION_NUMBER } =
  stripeFormKeys;

const DecidirForm = () => {
  const route = useRoute();
  const { bookData } = route.params || {};
  const { flightBookId, userBookCode, price, flightValue } = {
    flightBookId: '00fDjHFMtYUx0Sx7NT3Q',
    userBookCode: 'AV-16359217977095505-1',
    price: {
      currency: 'U$D',
    },
    flightValue: 192,
  };
  const { setSnackbar } = useContext(SnackbarContext);
  const navigation = useNavigation();
  const [error, setError] = useState();
  const [processing, setProcessing] = useState();
  const [disabled, setDisabled] = useState();
  const [succeeded, setSucceeded] = useState();
  const [formValues, setFormValues] = useState({});

  const disabledButton = error || processing || disabled || succeeded;

  const onSuccess = () =>{
    setSucceeded('bien')
    navigation.navigate(routes.HOME);
  }

  const onError = () =>{
    setError('mal')
  }

  const handleSubmit = async () => {
    //TODO handle formValues
    const data = {
      card_number: formValues.cardNumber,
      card_expiration_month: formValues.expiration.substring(0, 2),
      card_expiration_year: formValues.expiration.substring(2, 4),
      security_code: formValues.CVC,
      card_holder_name: formValues.holderName,
      card_holder_dni: formValues.card_holder_dni,
    };
    const token = await getDecidirToken({ data },onSuccess);
    console.log('token','bookData',bookData,token);
    const id = await postDecidirPayment({
      data: {
        flightBookId,
        userBookCode,
        token,
        cardNumber: formValues.cardNumber,
      },
    },onSuccess,onError);
    console.log(id);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{TITLE}</Text>
      <Text>Nombre del titular (como figura en la tarjeta)</Text>
      <View style={[styles.cardElementContainer]}>
        <TextInput
          value={formValues.holderName}
          onChangeText={(text) => {
            setFormValues({
              ...formValues,
              holderName: text,
            });
          }}
          style={styles.textinput}
        />
      </View>
      <View style={{ marginTop: 20 }}>
        <Text>DNI</Text>
        <View style={[styles.cardElementContainer]}>
          <TextInput
            value={formValues.card_holder_dni}
            onChangeText={(text) => {
              setFormValues({
                ...formValues,
                card_holder_dni: text,
              });
            }}
            style={styles.textinput}
          />
        </View>
      </View>
      <View style={{ marginTop: 20 }}>
        <Text>{CARD_NUMBER}</Text>
        <View style={[styles.cardElementContainer]}>
          <MaskInput
            style={styles.textinput}
            value={formValues.cardNumber}
            onChangeText={(masked, unmasked) => {
              setFormValues({
                ...formValues,
                cardNumber: unmasked,
              });
            }}
            mask={[
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
          />
        </View>
      </View>
      <View style={{ flexDirection: 'row', gap: 40, marginVertical: 20 }}>
        <View style={{ flex: 1 }}>
          <Text>{EXPIRATION_NUMBER}</Text>
          <View style={styles.cardElementContainer}>
            <MaskInput
              style={styles.textinput}
              value={formValues.expiration}
              onChangeText={(masked, unmasked) => {
                setFormValues({
                  ...formValues,
                  expiration: unmasked,
                });
              }}
              mask={[/\d/, /\d/, '/', /\d/, /\d/]}
            />
          </View>
        </View>
        <View style={{ flex: 1 }}>
          <Text>{CVC}</Text>
          <View style={styles.cardElementContainer}>
            <MaskInput
              style={styles.textinput}
              value={formValues.CVC}
              onChangeText={(masked, unmasked) => {
                setFormValues({
                  ...formValues,
                  CVC: unmasked,
                });
              }}
              mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
            />
          </View>
        </View>
      </View>
      <Text style={styles.price}>
        Total: {price?.currency} {flightValue}
      </Text>
      <Button
        disabled={disabledButton}
        buttonStyles={styles.button(disabledButton)}
        onPress={handleSubmit}
      >
        {processing && (
          <Spinner noMargin withText={false} color={theme.colors.white} />
        )}
        {!processing && <Text>{PAY}</Text>}
      </Button>
      {<Text style={styles.error}>{error || ''}</Text>}
      <Modal></Modal>
    </View>
  );
};

export default DecidirForm;
