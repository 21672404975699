import { StyleSheet } from 'react-native';

import { screen, theme } from 'utils';

const { isBigScreen } = screen;
const { primary } = theme.colors;

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: primary,
    marginBottom: 20,
  },
  toggleButtonsContainer: {
    flexDirection: 'row',
    paddingTop: 30,
    paddingBottom: 10,
    justifyContent: 'center',
    maxWidth: 1000,
    width: '100%',
  },
  locationsContainer: {
    marginBottom: 20,
    width: '100%',
    maxWidth: 500,
    paddingHorizontal: 10,
  },
  locations: {
    backgroundColor: primary,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: isBigScreen ? 'space-between' : 'center',
    maxWidth: 1050,
    paddingHorizontal: 24,
    paddingVertical: 20,
    width: '100%',
  },
  searchInputContainer: {
    paddingBottom: 0,
  },
  searchInput: {
    fontSize: 16,
    fontWeight: '800',
    height: 54,
    paddingLeft: 20,
    paddingRight: 20,
  },
  bookTypesButton: {
    flexGrow: 1,
    maxWidth: 500,
    marginHorizontal: 10,
  },
  bookTypesContainer: {
    backgroundColor: 'transparent',
    paddingVertical: 15,
    justifyContent: 'center',
  },
  searchButtonContainer: {
    marginVertical: 16,
    paddingHorizontal: 24,
  },
  fromInput: {
    fontSize: 16,
  },
  buttonStyle: {
    borderRadius: 100,
    width: 40,
    height: 40,
    marginHorizontal: 3,
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerStyle: {
    paddingBottom: 1,
    justifyContent: 'center',
  },
  toggleButtonLabel: {
    color: 'white',
    fontWeight: '600',
    marginTop: 10,
    fontSize: 14,
    display: 'flex',
    alignItems: 'flex-start',
  },
});

export default styles;
