import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity, View } from 'react-native';

import Text from 'components/text/Text';
import styles from '../PaymentButton.styles';
import { getFlightPaymentSheet } from 'api/flights';
import { IconButton } from 'react-native-paper';
import { paymentMethods } from 'i18n';
import { constants, methods, routes, theme } from 'utils';
import Spinner from 'components/spinner';
import useAuth from '../../../oauth';

const { PAYMENT_METHOD_CREDIT_CARD } = constants.METRICS;
const { triggerMetric } = methods;
const { STRIPE_FORM } = routes;

const StripeButton = ({ bookData }) => {
  const { flightBookId, userBookCode } = bookData || {};
  const [clientSecret, setClientSecret] = useState(null);
  const { user } = useAuth();

  const fetchPaymentSheetParams = async () => {
    const data = (await getFlightPaymentSheet(flightBookId, userBookCode)).data;
    const { paymentIntent } = data;
    setClientSecret(paymentIntent);
  };

  useEffect(() => {
    fetchPaymentSheetParams();
  }, []);

  const navigation = useNavigation();

  const onPressHandler = () => {
    triggerMetric({
      event: PAYMENT_METHOD_CREDIT_CARD,
      userId: user,
    });
    return navigation.push(STRIPE_FORM, { bookData, clientSecret });
  };

  if (!clientSecret) {
    return (
      <View style={styles.paymentMethod}>
        <Spinner withImage={false} noMargin withText={false} />
        <Text style={styles.paymentLabel}>{paymentMethods.STRIPE}</Text>
      </View>
    );
  }

  return (
    <TouchableOpacity onPress={onPressHandler}>
      <View style={styles.paymentMethod}>
        <IconButton icon="credit-card" size={28} color={theme.colors.primary} />
        <Text style={styles.paymentLabel}>{paymentMethods.STRIPE}</Text>
      </View>
    </TouchableOpacity>
  );
};

export default StripeButton;
