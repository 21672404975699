import React from 'react';

import styles from './AppVersionText.styles';
import { device } from 'utils';
import Text from 'components/text/Text';
import Constants from 'expo-constants';

const { isAndroid, isIOS } = device;

function AppVersionText() {
  if (isAndroid || isIOS) {
    return (
      <Text style={styles.container}>
        v{Constants?.manifest?.extra?.APP_VERSION}
      </Text>
    );
  }

  return null;
}

export default AppVersionText;
