import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/text/Text';

const Tag = ({ styles, text }) => <Text style={styles}>{text}</Text>;

Tag.propTypes = {
  styles: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

export default Tag;
