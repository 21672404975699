import { StyleSheet } from 'react-native';

import { screen } from 'utils';

const { isMobile } = screen;

const styles = StyleSheet.create({
  container: {
    height: isMobile ? 280 : 480,
    borderRadius: 20,
    marginBottom: 20,
    width: '100%',
    marginHorizontal: 10,
    flexBasis: 'auto',
    backgroundColor: '#414a5a',
    paddingVertical: 40,
    paddingHorizontal: 40,
  },
  content: {
    display: 'flex',
  },
});

export default styles;
