import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    maxWidth: 600,
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
  },
});

export default styles;
