import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

import styles from './FlightInfo.styles';
import { date } from 'utils';
import FlightInfo from 'components/flight-info';

const FlightInfoComponent = ({ segments }) => {
  const getLocationProps = (location) => {
    const { airports, date: locationDate } = location;
    const date = locationDate.dateTimeLocal.split('T')[0];
    const time = locationDate.dateTimeLocal.split('T')[1].substring(0, 5);
    const currentDate = new Date(locationDate.dateTimeLocal); // TODO: dateTimeUTC

    return {
      date,
      time,
      timestamp: currentDate,
      code: airports?.[0]?.code,
      airport: airports?.[0]?.name,
    };
  };

  const renderFlightInfo = () =>
    segments
      .filter((segment) => segment.paxSegment === true)
      .map((segment) => {
        const { fromLocation, toLocation } = segment;
        const fromProps = getLocationProps(fromLocation);
        const toProps = getLocationProps(toLocation);

        return (
          <FlightInfo
            key={`${fromProps.airport}-${toProps.airport}`}
            fromLocation={fromProps}
            toLocation={toProps}
          />
        );
      });

  return <View style={styles.segmentsContainer}>{renderFlightInfo()}</View>;
};

FlightInfoComponent.propTypes = {
  segments: PropTypes.array.isRequired,
};

export default FlightInfoComponent;
