import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from 'react-native-paper';

import styles from './Snackbar.styles';
import { theme } from 'utils';

const { black, red, success, white } = theme.colors;

const snackbarTypes = {
  error: { colors: { surface: white, onSurface: red } },
  neutral: { colors: { surface: white, onSurface: black } },
  success: { colors: { surface: white, onSurface: success } },
  warning: { colors: { surface: white, onSurface: 'orange' } },
};

const SnackbarComponent = ({ duration, message, type, visible, onDismiss }) => {
  return (
    <Snackbar
      duration={duration}
      visible={visible}
      theme={snackbarTypes[type]}
      onDismiss={onDismiss}
      style={styles.snackbar}
      wrapperStyle={styles.wrapper}
    >
      {message}
    </Snackbar>
  );
};

SnackbarComponent.propTypes = {
  duration: PropTypes.number,
  message: PropTypes.string,
  type: PropTypes.string,
  visible: PropTypes.bool.isRequired,
};

SnackbarComponent.defaultProps = {
  duration: 5000,
  message: '',
  type: null,
};

export default SnackbarComponent;
