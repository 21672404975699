import { StyleSheet } from 'react-native';

import { theme } from 'utils';

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.white,
    padding: 20,
    margin: 20,
    borderRadius: 10,
  },
  icon: {
    marginVertical: 10,
    marginHorizontal: 'auto',
    borderWidth: 4,
    borderColor: theme.colors.success,
    alignSelf: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: '700',
    marginTop: 20,
    textAlign: 'center',
  },
  text: {
    fontSize: 16,
    marginTop: 10,
  },
  button: {
    marginTop: 40,
  },
  buttonText: {
    fontWeight: '700',
    textTransform: 'capitalize',
    fontSize: 24,
    letterSpacing: 0,
  },
});

export default styles;
