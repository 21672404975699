import { StyleSheet } from 'react-native';

import { theme } from 'utils';

const styles = StyleSheet.create({
  time: {
    backgroundColor: theme.colors.primary,
    paddingBottom: 24,
    paddingHorizontal: 24,
    alignItems: 'center',
  },
});

export default styles;
