import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import background from 'assets/waiting.png';
import Text from 'components/text/Text';
import Image from 'components/image';
import styles from './NoResults.styles';
import { noResults } from 'i18n';

const NoResults = ({ city, message }) => {
  return (
    <View style={styles.noResultsContainer}>
      <Image source={background} style={styles.backgroundImage} />
      {message ? (
        <Text style={styles.noResultsText}>{message}</Text>
      ) : (
        <>
          <Text style={styles.noResultsText}>{noResults.NO_OFFERS_FROM}</Text>
          <Text style={[styles.noResultsText, { fontWeight: '800' }]}>
            {city}
          </Text>
        </>
      )}
    </View>
  );
};

NoResults.propTypes = {
  city: PropTypes.string,
};

NoResults.defaultProps = {
  city: '',
};

export default NoResults;
