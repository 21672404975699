import React from 'react';

import styles from './FlightThumbnails.styles';
import { flightThumbnail as flightThumbnailKeys } from 'i18n';
import LocationTitle from './components/location-title';
import Title from './components/title';

const {
  CANCELLED,
  CONFIRMED,
  EMPTY_LEG,
  DISCOUNT,
  SEAT_LEFT,
  SEATS_LEFT,
  WAITING_CONFIRMATION,
  OPEN,
  EXPIRED,
  WAITING_PAYMENT,
} = flightThumbnailKeys;

const statusTags = {
  cancelled: CANCELLED,
  confirmed: CONFIRMED,
  waitingConfirmation: WAITING_CONFIRMATION,
  open: OPEN,
  expired: EXPIRED,
};

const getTagStyles = ({ status, emptyLeg, seatsLeft, userBook, source }) => {
  if (statusTags[status] === EXPIRED) {
    return [styles.tag, styles.tagGray];
  }
  if (userBook?.status === 'waitingPayment') {
    return [styles.tag, styles.tagRed];
  }
  if (seatsLeft) {
    return [styles.tag, styles.tagSecondary];
  }
  if (status) {
    if (statusTags[status] === CONFIRMED) {
      return [styles.tag, styles.tagPrimary];
    }

    if (statusTags[status] === CANCELLED) {
      return [styles.tag, styles.tagRed];
    }
  } else {
    if (emptyLeg && source === 'home') {
      return [styles.tag, styles.tagGold];
    }
  }
  return styles.tag;
};

const getTagText = ({
  status,
  discount,
  emptyLeg,
  seatsLeft,
  userBook,
  source,
}) => {
  if (statusTags[status] === EXPIRED) {
    const statusText = statusTags[status];
    return statusText || status;
  }
  if (userBook?.status === 'waitingPayment') {
    return WAITING_PAYMENT;
  }
  if (seatsLeft) {
    if (seatsLeft > 1) {
      return SEATS_LEFT(seatsLeft);
    }
    return SEAT_LEFT;
  }
  if (status) {
    const statusText = statusTags[status];
    return statusText || status;
  }
  if (emptyLeg && source === 'home') {
    return EMPTY_LEG;
  }
  if (discount) {
    return DISCOUNT(discount);
  }
};

const getTitle = ({ segments, titleType, type }) => {
  if (titleType === 'trip') {
    const { fromLocation } = segments?.[0];
    const { toLocation } = segments?.[segments.length - 1];
    return (
      <LocationTitle
        from={fromLocation?.city?.toUpperCase()}
        to={toLocation?.city?.toUpperCase()}
      />
    );
  }
  return <Title type={type} />;
};

export { statusTags, getTagStyles, getTagText, getTitle };
