import dayjs from 'dayjs';
import 'dayjs/locale/es';

dayjs.locale('es');

export const getDayName = (date) => {
  const dayName = dayjs(date).format('dddd');
  const capitalizedDayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);
  const number = date.getDate();
  return `${capitalizedDayName} ${number}`;
};

export const getMonthName = (date) => {
  const month = dayjs(date).format('MMMM');
  return month.charAt(0).toUpperCase() + month.slice(1);
};
