import { StatusBar, StyleSheet } from 'react-native';

import { device, theme, screen } from 'utils';

const { isIOS } = device;
const { background, primary, white } = theme.colors;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: isIOS ? 50 : 0,
    paddingHorizontal: 24,
    paddingVertical: 12,
    backgroundColor: background,
  },
  logoContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
    paddingTop: 14,
    width: '100%',
  },
  internalContainer: {
    backgroundColor: primary,
    paddingBottom: 0,
  },
  logo: {
    height: 100,
    width: 200,
  },
  title: {
    flexGrow: 1,
    paddingLeft: 20,
    textAlign: screen.isMobile ? 'center' : 'left',
    marginTop: 5,
  },
  sectionTitle: {
    color: white,
    fontSize: 18,
  },
  home: {
    backgroundColor: '#8fabb8', // TODO: pasar al theme.colors
  },
});

export default styles;
