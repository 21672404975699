import { StyleSheet } from 'react-native';

import { screen } from 'utils';

const { isMobile } = screen;

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    margin: 20,
    overflow: 'hidden',
    width: isMobile ? 'auto' : 512,
  },
});

export default styles;
