import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, View } from 'react-native';
import Text from 'components/text/Text';

import styles from './LogIn.styles';
import { login as loginKeys } from 'i18n';
import AuthTemplate from 'components/auth-template';
import Button from 'components/button';
import TextInput from 'components/text-input';

const {
  EMAIL_FIELD,
  PASSWORD_FIELD,
  SUBMIT_BUTTON,
  REGISTER,
  FORGOT_PASSWORD,
} = loginKeys;

const LogIn = ({
  email,
  setEmail,
  password,
  setPassword,
  forgotPassword,
  logIn,
  signUp,
  isLoading,
}) => (
  <AuthTemplate>
    <View style={styles.container}>
      <TextInput
        placeholder={EMAIL_FIELD}
        onChangeText={(value) => setEmail(value)}
        value={email}
        icon="email"
        inputStyles={styles.input}
      />

      <TextInput
        placeholder={PASSWORD_FIELD}
        onChangeText={(value) => setPassword(value)}
        value={password}
        secureTextEntry={true}
        icon="lock"
        inputStyles={styles.input}
      />

      <View style={styles.forgotPasswordContainer}>
        <TouchableOpacity
          onPress={forgotPassword}
          style={styles.forgotPasswordTouchable}
        >
          <Text style={styles.forgotPassword}>{FORGOT_PASSWORD}</Text>
        </TouchableOpacity>
      </View>

      <Button
        onPress={logIn}
        labelStyle={styles.buttonLabel}
        buttonStyles={styles.button}
        loading={isLoading}
      >
        <Text style={styles.submitButtonText}>{SUBMIT_BUTTON}</Text>
      </Button>

      <Button
        onPress={signUp}
        labelStyle={styles.registerButtonLabel}
        buttonStyles={styles.registerButton}
        mode="outlined"
        color="#000"
      >
        <Text style={styles.registerButtonText}>{REGISTER}</Text>
      </Button>
    </View>
  </AuthTemplate>
);

LogIn.propTypes = {
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  logIn: PropTypes.func.isRequired,
  signUp: PropTypes.func.isRequired,
};

export default LogIn;
