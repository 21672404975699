import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { Modal, Portal } from 'react-native-paper';

import styles from './Modal.styles';
import { searchInfo as searchInfoKeys } from 'i18n';
import { device } from 'utils';
import Button from 'components/button';
import Category from './components/category';
import Text from 'components/text/Text';

const { MODAL_BUTTON, MODAL_TITLE } = searchInfoKeys;

const ModalComponent = ({
  visible,
  setVisible,
  categories: categoriesProp,
  setSelected,
}) => {
  const [categories, setCategories] = useState(categoriesProp);
  const [localSelection, setLocalSelection] = useState(categoriesProp);

  const onCategoryPress = (name) => {
    const isLastCategory = localSelection?.length === 1;
    if (localSelection.includes(name)) {
      if (!isLastCategory) {
        setLocalSelection(localSelection.filter((str) => str !== name));
      }
    } else {
      setLocalSelection([...localSelection, name]);
    }
  };

  const onFilterApplied = () => {
    setSelected(localSelection);
    setVisible(false);
  };

  const renderCategories = () => {
    if (Boolean(categories?.length)) {
      return categories.map((name, i) => {
        return (
          <View style={styles.category}>
            <Category
              onPress={onCategoryPress}
              name={name}
              isSelected={localSelection.includes(name)}
              key={i}
            />
          </View>
        );
      });
    }
  };

  useEffect(() => {
    setCategories(categoriesProp);
    setSelected(categoriesProp);
    setLocalSelection(categoriesProp);
  }, [categoriesProp]);

  useEffect(() => {
    // Para evitar que se haga scroll de la página cuando este modal está abierto.
    if (device.isWeb) {
      if (visible) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    }
  }, [visible]);

  return (
    <Portal>
      <Modal
        visible={visible}
        onDismiss={onFilterApplied}
        contentContainerStyle={styles.container}
      >
        <ScrollView style={{ maxHeight: 500 }}>
          <Text style={styles.title}>{MODAL_TITLE}</Text>
          <View style={styles.categoriesContainer}>{renderCategories()}</View>
        </ScrollView>
        <Button buttonStyles={styles.button} onPress={onFilterApplied}>
          <Text
            style={{
              fontWeight: '700',
              textTransform: 'capitalize',
              fontSize: 24,
              letterSpacing: 0.3,
            }}
          >
            {MODAL_BUTTON}
          </Text>
        </Button>
      </Modal>
    </Portal>
  );
};

export default ModalComponent;
