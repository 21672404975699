import React from 'react';
import PropTypes from 'prop-types';
import { ScrollView, View } from 'react-native';

import styles from './ResultsContainer.styles';
import FlightThumbnail from 'components/flight-thumbnail';
import NoResults from 'components/noResults';
import { noResults } from 'i18n';
import Spinner from 'components/spinner';

const ResultsContainer = ({ flights, passengers, flightAirports }) => (
  <ScrollView contentContainerStyle={styles.container}>
    {flights ? (
      flights.map((flight) => (
        <FlightThumbnail
          key={flight?.jet?.tail}
          {...flight}
          passengers={passengers}
          flightAirports={flightAirports}
        />
      ))
    ) : (
      <View style={styles.spinnerContainer}>
        <Spinner />
      </View>
    )}
    {flights?.length === 0 && <NoResults message={noResults.NO_RESULTS} />}
    <View style={{ width: 400, marginHorizontal: 10, height: 0 }}></View>
    <View style={{ width: 400, marginHorizontal: 10, height: 0 }}></View>
    <View style={{ width: 400, marginHorizontal: 10, height: 0 }}></View>
    <View style={{ width: 400, marginHorizontal: 10, height: 0 }}></View>
  </ScrollView>
);

ResultsContainer.propTypes = {
  flights: PropTypes.array,
  passengers: PropTypes.number.isRequired,
};

ResultsContainer.defaultProps = {
  flights: null,
};

export default ResultsContainer;
