const firebaseConfig = {
  apiKey: 'AIzaSyCxYg1qooQUAciuzcNK6mOJazlsjF9al2M',
  authDomain: 'crux-jets.firebaseapp.com',
  projectId: 'crux-jets',
  storageBucket: 'crux-jets.appspot.com',
  messagingSenderId: '381922685397',
  appId: '1:381922685397:web:7b215e074546b471130d5c',
  measurementId: 'G-5PV26W6254',
};

export default firebaseConfig;
