import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-native';

import { device } from 'utils';

const { isNative } = device;

const ImageComponent = ({ source, style }) => {
  let imageSource = isNative
    ? source && Image.resolveAssetSource(source).uri
    : source;

  return <Image source={{ uri: imageSource }} style={style} />;
};

ImageComponent.propTypes = {
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  style: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.object,
  ]),
};

ImageComponent.defaultProps = {
  style: null,
};

export default ImageComponent;
