import React, { useEffect, useState } from 'react';

import { getTermsAndConditions } from 'api';
import TermsAndConditions from 'components/terms-and-conditions';

const TermsConditions = () => {
  const [terms, setTerms] = useState('');
  const loadTerms = async () => {
    setTerms(await getTermsAndConditions());
  };
  useEffect(() => {
    loadTerms();
  }, []);

  return <TermsAndConditions data={terms?.data?.data} />;
};

export default TermsConditions;
