import React, { useState, useEffect, useContext } from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { putPayment } from 'api/flights';
import {
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import Text from 'components/text/Text';
import Button from 'components/button';
import { Modal } from 'react-native-paper';
import { useFocusEffect, useRoute } from '@react-navigation/core';
import styles from './StripeForm.styles';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import errorTranslate from 'i18n/errorTranslate';
import { constants, methods, routes, theme } from 'utils';
import { stripeForm as stripeFormKeys } from 'i18n';
import Spinner from 'components/spinner';
import useAuth from '../../oauth';
import DetailValue from 'screens/detail/components/DetailValue';

const { PAID_WITH_CREDIT_CARD } = constants.METRICS;
const COUNTRY_VALUE = constants.COUNTRY
const { triggerMetric } = methods;
const { PAY, SUCCESSFUL_PAYMENT, TITLE, CVC, CARD_NUMBER, EXPIRATION_NUMBER, COUNTRY } =
  stripeFormKeys;


const StripeForm = () => {
  const route = useRoute();
  const { user,role } = useAuth();
  const { bookData, clientSecret } = route.params;
  const { flightBookId, userBookCode, price } = bookData || {};
  const [succeeded, setSucceeded] = useState(false);
  const [errorCard, setErrorCard] = useState(null);
  const [errorExpiry, setErrorExpiry] = useState(null);
  const [errorCVC, setErrorCVC] = useState(null);
  const [errorPay, setErrorPay] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  const { setSnackbar } = useContext(SnackbarContext);
  const navigation = useNavigation();
  const [visible, setVisible] = useState(false);

  const [selectValue, setSelectValue] = useState(100);


  const handleSelectChange = async ( e ) => {
    setSelectValue(e.target.value);
  }

  const getSelectValues = () => {
    const values = [];
    COUNTRY_VALUE.map((country) => (
      values.push({
        id: country.id,
        text: country.text,
      })
    ));
    return values;
  };
    

  useFocusEffect(() => {
    setVisible(true);
    return () => setVisible(false);
  }, []);

  const handleChange = async (event, setError) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  	const handleSubmit = async (ev) => {
		ev.preventDefault();
		setProcessing(true);
			const payload = await stripe.confirmCardPayment(clientSecret, {
				payment_method: {
				card: elements.getElement(CardNumberElement),
				},
			});
		
			if (payload.error) {
				setErrorPay(`Error: ${payload.error.message}`);
				setSnackbar({
				message: errorTranslate(
					`STRIPE_${payload.error.code}`,
					payload.error.message
				),
				visible: true,
				});
				setProcessing(false);
			} else {
				setErrorPay(null);
				setProcessing(false);
				setSucceeded(true);
				putPayment({
				data: {
					paymentMethodId: 'stripe',
					flightBookId,
					userBookCode,
				},
				});
				setSnackbar({
				type: 'success',
				message: SUCCESSFUL_PAYMENT,
				visible: true,
				});
		
				triggerMetric({
				event: PAID_WITH_CREDIT_CARD,
				userId: user,
				});
		
				navigation.navigate(routes.HOME);
			}
  	};

  const disabledButton =
    errorCard || errorExpiry || errorCVC || processing || disabled || succeeded;

    const isAgency = () => role === "agency"
  
    const getFlightValue = ()=>{
        return bookData.flightValue
    }


  //necessary as stripe will fail if its elements are rendered in diff screens
  if (!visible) return null;

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{TITLE}</Text>
      <Text>{CARD_NUMBER}</Text>
      <View style={[styles.cardElementContainer]}>
        <CardNumberElement
          id="card-number"
          options={{
            style: styles.stripeCard,
            placeholder: 'XXXX XXXX XXXX XXXX',
          }}
          onChange={(e) => handleChange(e, setErrorCard)}
        />
      </View>
      {<Text style={styles.error}>{errorCard || ''}</Text>}
      <View style={{ flexDirection: 'row', gap: 40, marginVertical: 20 }}>
        <View style={{ flex: 1 }}>
          <Text>{EXPIRATION_NUMBER}</Text>
          <View style={styles.cardElementContainer}>
            <CardExpiryElement
              id="card-expiry"
              options={{ style: styles.stripeCard }}
              onChange={(e) => handleChange(e, setErrorExpiry)}
            />
          </View>
          {<Text style={styles.error}>{errorExpiry || ''}</Text>}
        </View>
        <View style={{ flex: 1 }}>
          <Text>{CVC}</Text>
          <View style={styles.cardElementContainer}>
            <CardCvcElement
              id="card-cvc"
              options={{ style: styles.stripeCard }}
              onChange={(e) => handleChange(e, setErrorCVC)}
            />
          </View>
          {<Text style={styles.error}>{errorCVC || ''}</Text>}
        </View>

        
      </View>
      <DetailValue
        getFlightValue={getFlightValue}
        flightValue={bookData.flightValue}
        currency={bookData.price.currency}
        discount={bookData.price.discount}
        isAgencyBolean={isAgency()}
              />
      <Button
        disabled={disabledButton}
        buttonStyles={styles.button(disabledButton)}
        onPress={handleSubmit}
      >
        {processing && (
          <Spinner noMargin withText={false} color={theme.colors.white} />
        )}
        {!processing && <Text>{PAY}</Text>}
      </Button>
      {<Text style={styles.error}>{errorPay || ''}</Text>}
      <Modal></Modal>
    </View>
  );
};

export default StripeForm;
