import { StyleSheet } from 'react-native';

import { theme } from 'utils';

const styles = StyleSheet.create({
  button: {
    backgroundColor: theme.colors.secondary,
  },
  labelStyle: {
    color: theme.colors.white,
    fontSize: 18,
    fontWeight: '800',
  },
});

export default styles;
