import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

import styles from './BookingInfo.styles';
import { detail as detailKeys } from 'i18n';
import Text from 'components/text/Text';

const { BOOKING_NUMBER } = detailKeys;

const BookingInfo = ({ code, currency = 'USD', price }) => (
  <View style={styles.container}>
    <View style={styles.row}>
      <Text style={styles.text}>{BOOKING_NUMBER}: </Text>
      <Text style={styles.code}>{code}</Text>
    </View>
  </View>
);

BookingInfo.propTypes = {
  code: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
};

export default BookingInfo;
