import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useDebouncedCallback } from 'use-debounce';
import { useNavigation, useRoute } from '@react-navigation/native';

import styles from './Search.styles';
import { getAirports, getLocations } from 'api/flights';
import { constants, device, routes } from 'utils';
import SearchList from 'components/search-list';
import SearchListItem from 'components/search-list-item';
import SearchNavbar from 'components/search-navbar';
import Spinner from 'components/spinner';
import { SnackbarContext } from 'contexts/SnackbarContext';
import MapSvg from 'components/icons/Map';

import { search as searchKeys, errors as errorsKeys } from 'i18n';
import Text from 'components/text/Text';

const {
  FLIGHT_TYPES: { ONE_WAY },
} = constants;
const { storage } = device;
const { SEARCH_CONFIG, HOME } = routes;

const Search = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const {
    type,
    location: locationParam,
    locations,
    flightType,
    locationIndex,
  } = route.params;
  const [location, setLocation] = useState(route.params?.location);
  const [isLoading, setIsLoading] = useState(false);
  const [showInitialBackground, setShowInitialBackground] = useState(true);
  const { setSnackbar } = useContext(SnackbarContext);

  const buildLocationText = (location) => {
    const { city, country } = location || {};
    if (city && country?.name) {
      return `${city}, ${country.name}`;
    }
    return '';
  };
  const [text, setText] = useState(buildLocationText(location));
  const [suggestions, setSuggestions] = useState(null);

  const onPressHandler = (item) => {
    let data = {};

    if (type === 'fromLocationHome') {
      storage.setItem('fromLocationHome', item);
      return navigation.push(HOME);
    } else if (type === 'fromLocation') {
      data = { fromLocation: item };
    } else {
      let toLocations;

      if (locations) {
        toLocations = locations;
        toLocations[locationIndex] = item;
      } else {
        toLocations = [item];
      }

      data = { toLocations, flightType: flightType || ONE_WAY };
    }

    return navigation.navigate(SEARCH_CONFIG, data);
  };

  const renderItem = ({ item }) => {
    const { airports, city, country, province } = item;
    const airport = airports[0];
    const props = {
      title: `${airport.name.toUpperCase()}`,
      subtitle: `${airport.code ? `(${airport.code}) ` : ''}${city}, ${
        country.name
      }`,
      code: country.code,
    };

    if (type === 'fromLocationHome') {
      props.title = city.toUpperCase();
      props.subtitle = `${country.name}${province ? ', ' + province.name : ''}`;
    }

    return <SearchListItem {...props} onPress={() => onPressHandler(item)} />;
  };

  const onSuccess = (res) => {
    setIsLoading(false);
    return setSuggestions(res);
  };

  const onError = (err) => {
    console.log(`Error: ${err}`);
    setSnackbar({
      message: errorsKeys.SEARCH_FAILURE,
      visible: true,
    });
    setIsLoading(false);
  };

  const makeRequest = useDebouncedCallback((value) => {
    if (value?.trim()?.length < 3) return;

    setIsLoading(true);
    setShowInitialBackground(false);
    if (type === 'fromLocationHome')
      getLocations(value)
        .then((res) => onSuccess(res.data))
        .catch((error) => onError(error));
    else {
      getAirports(value)
        .then((res) => onSuccess(res))
        .catch((error) => onError(error));
    }
  }, 300);

  useEffect(() => {
    if (text) {
      makeRequest(text);
    }
  }, [text]);

  useEffect(() => {
    setLocation(locationParam);
    setText(
      locationParam?.city && locationParam?.country?.name
        ? buildLocationText(locationParam)
        : ''
    );
    setSuggestions(null);
  }, [locationParam]);

  const getPlaceholder = () => {
    if (type === 'fromLocationHome') return searchKeys.SEARCH_LOCATION;
    if (type === 'fromLocation') return searchKeys.SEARCH_AIRPORT;
    else return searchKeys.SEARCH_DESTINATION;
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <SearchNavbar
          text={text}
          placeholder={getPlaceholder()}
          setText={setText}
        />
      </View>
      {showInitialBackground && <MapSvg styleClass={styles.backgroundImage} />}
      {!!(suggestions?.length === 0) && (
        <View style={styles.noResultsContainer}>
          <Text style={styles.noResultsText}>{searchKeys.NO_RESULTS}</Text>
        </View>
      )}
      {isLoading ? (
        <Spinner />
      ) : (
        <SearchList data={suggestions} renderItem={renderItem} />
      )}
    </View>
  );
};

export default Search;
