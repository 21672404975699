import { StatusBar, StyleSheet } from 'react-native';

import { screen } from 'utils';
const { isMobile } = screen;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 12,
    paddingTop: 12,
    paddingBottom: 20,
    width: isMobile ? '100%' : '60%',
  },
  searchInput: {
    fontSize: 18,
    fontWeight: '800',
    paddingLeft: 25,
    paddingRight: 0,
  },
  iconRight: {
    marginRight: 20,
  },
});

export default styles;
