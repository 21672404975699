import { StyleSheet } from 'react-native';

import { screen, theme } from '../../utils';

const styles = StyleSheet.create({
  container: {
    alignSelf: screen.isMobile ? 'auto' : 'center',
    marginHorizontal: 20,
    maxWidth: screen.desktopInitialResolution,
  },
  title: {
    alignSelf: 'flex-start',
    fontSize: 24,
    marginBottom: 40,
    marginTop: 40,
    fontWeight: '600',
  },
  info: {
    backgroundColor: theme.colors.white,
    borderRadius: 5,
    padding: 20,
    elevation: 10,
    shadowOffset: { width: 0, height: 15 },
    shadowOpacity: 0.1,
    shadowRadius: 15.0,
  },
  infoRow: {
    flexDirection: 'row',
    marginVertical: 10,
  },
  rowTitle: {
    color: theme.colors.primary,
    fontSize: 16,
    fontWeight: '700',
    minWidth: 170,
  },
  rowValue: {
    fontSize: 16,
    marginTop: 5,
  },
  fab: {
    backgroundColor: theme.colors.secondary,
    marginLeft: 10,
    fontSize: 8,
  },
  button: {
    borderRadius: 8,
    paddingVertical: 8,
    marginTop: 40,
    elevation: 10,
    shadowOffset: { width: 0, height: 15 },
    shadowColor: theme.colors.secondary,
    shadowOpacity: 0.5,
    shadowRadius: 15.0,
  },
  reservationDetailPrice: {
    fontSize: 24,
    fontWeight: '700',
    marginRight: 20,
  },
});

export default styles;
