import { StyleSheet } from 'react-native';

import { theme } from 'utils';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginRight: 60,
  },
  menuText: {
    color: theme.colors.white,
    fontSize: 16,
    marginLeft: 40,
    
  },
});

export default styles;
