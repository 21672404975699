import React from 'react';
import { ScrollView, Text, View } from 'react-native';

import Banner from 'components/banner';
import OffersContainer from 'containers/offers-container';
import AppVersionText from 'components/app-version-text';
import { SafeAreaView } from 'react-native-safe-area-context';

const Home = () => (

    <View style={{ backgroundColor:'#2E3440' }}>
      <ScrollView contentContainerStyle={{ alignItems: 'center'}} >
        <Banner />            
        <OffersContainer />
        <AppVersionText />    
      </ScrollView>
    </View>   
);

export default Home;
