import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import styles from './Category.styles';
import Text from 'components/text/Text';
import { IconButton } from 'react-native-paper';
import { theme } from 'utils';

const Category = ({ isSelected, name, onPress }) => {
  const containerStyles = isSelected
    ? [styles.category, styles.selected]
    : styles.category;
  const textStyles = isSelected
    ? [styles.name, styles.selectedText]
    : styles.name;

  return (
    <TouchableOpacity onPress={() => onPress(name)}>
      <View style={containerStyles}>
        <Text style={textStyles}>{name}</Text>
        <IconButton
          icon={
            isSelected
              ? 'checkbox-marked-circle'
              : 'checkbox-blank-circle-outline'
          }
          size={22}
          color={isSelected ? theme.colors.secondary : theme.colors.secondary}
        />
      </View>
    </TouchableOpacity>
  );
};

export default Category;
