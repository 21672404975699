import OneWayTripIcon from 'components/icons/OneWayTrip';
import RoundTripIcon from 'components/icons/RoundTrip';
import MultiLegIcon from 'components/icons/MultiLegTrip';
import SeatIcon from 'components/icons/Seat';
import PlaneIcon from 'components/icons/Jet';
import {
  bookTypes as bookTypesKeys,
  flightTypes as flightTypesKeys,
} from 'i18n';

const flightTypeValues = [
  { id: 'oneWay', icon: OneWayTripIcon },
  { id: 'roundTrip', icon: RoundTripIcon },
  { id: 'multiLeg', icon: MultiLegIcon },
];

const flightTypeLabelMap = {
  oneWay: flightTypesKeys.ONE_WAY,
  roundTrip: flightTypesKeys.ROUND_TRIP,
  multiLeg: flightTypesKeys.MULTI_LEG,
};

const bookTypesLabelMap = {
  1: bookTypesKeys.FULL_FLIGHT,
  2: bookTypesKeys.SEATS_FLIGHT,
};

const bookTypesValues = [
  { id: 1, icon: PlaneIcon, value: 'FLIGHT' },
  { id: 2, icon: SeatIcon, value: 'SEAT' },
];

const passengersValues = [
  { id: 1, text: 1 },
  { id: 2, text: 2 },
  { id: 3, text: 3 },
  { id: 4, text: 4 },
  { id: 5, text: 5 },
  { id: 6, text: 6 },
  { id: 7, text: 7 },
  { id: 8, text: '8+' },
];

export {
  flightTypeValues,
  bookTypesValues,
  passengersValues,
  flightTypeLabelMap,
  bookTypesLabelMap,
};
