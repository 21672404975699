import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import Text from 'components/text/Text';
import { IconButton } from 'react-native-paper';

import { constants, methods, theme } from '../../../utils';
import styles from '../PaymentButton.styles';
import { paymentMethods } from 'i18n';
import useAuth from '../../../oauth';

const { PAYMENT_METHOD_CALL } = constants.METRICS;
const { makeCall, triggerMetric } = methods;

const CallButton = () => {
  const { user } = useAuth();

  const onPressHandler = () => {
    triggerMetric({
      event: PAYMENT_METHOD_CALL,
      userId: user,
    });
    makeCall();
  };

  return (
    <TouchableOpacity onPress={onPressHandler}>
      <View style={styles.paymentMethod}>
        <IconButton
          icon="phone-in-talk"
          size={28}
          color={theme.colors.secondary}
        />
        <Text style={styles.paymentLabel}>{paymentMethods.CALL}</Text>
      </View>
    </TouchableOpacity>
  );
};

export default CallButton;
