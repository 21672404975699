import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import useAuth from '../../../oauth';

import styles from './Reservation.styles';
import { postFlight } from 'api';
import { detail as detailKeys } from 'i18n';
import { constants, methods, routes, theme } from 'utils';
import Button from 'components/button';
import Text from 'components/text/Text';
import DetailValue from './DetailValue'


const { BOOK,PAID, BUY, EXPIRED, CANCELLED, PAY,FLIGHT_VALUE_ZERO ,CONSULT_PRICE,CONSULT_TEXT} = detailKeys;
const { BOOK_BUTTON, BUY_BUTTON } = constants.METRICS;
const { triggerMetric, whatsappMessage , } = methods;

const Reservation = ({
  flightValue,
  isSeatType,
  paymentMethods,
  totalSeatsText,
  routeParams,
  passengers,
  logged,
}) => {
  const navigation = useNavigation();
  const { user,role } = useAuth();
  const { price, userBook,jet, segments} = routeParams;
  const { currency,subTotal,commissionTotal,discount } = price;
  const isPaid =
    userBook?.status === 'validatingPayment' ||
    userBook?.status === 'confirmed';
  const isCancelled = userBook?.status === 'cancelled';
  const isWaitingPay = userBook?.status === 'waitingPayment';
  const isExpired = routeParams.status === 'expired';
  const isFlightValueZero = flightValue === 0;

  const origin = segments?.[0]?.fromLocation;
  const target = segments?.[0]?.toLocation;
  const jetType = jet?.type;

  const buttonStyles = () => {
    if (isPaid) return styles.buttonSuccess;
    if (isExpired || isCancelled) return styles.buttonExpired;
    return null;
  };
  const [processing, setProcessing] = useState(false);

  const getButtonText = () => {
    if (isPaid) return PAID;
    if (isExpired) return EXPIRED;
    if (isCancelled) return CANCELLED;
    if (isWaitingPay) return PAY;
    if (isFlightValueZero) return CONSULT_PRICE
    return isSeatType ? BUY : BOOK;
  };

  const triggerBuyFlightMetric = () => {
    
    return triggerMetric({
      event: isSeatType ? BUY_BUTTON : BOOK_BUTTON,
      userId: user,
      origen:origin?.city,
      destino:target?.city,
      avion:jetType,
      sharing: isSeatType,
    });
  };

  const onPressHandler = async () => {
    if (!isFlightValueZero && !logged) {
      navigation.navigate(routes.LOG_IN, {
        goBack: true,
      });
      return;
    }else if(isFlightValueZero){
      const date = origin?.date?.dateTimeLocal.split('T')[0];
      const time = origin?.date?.dateTimeLocal.split('T')[1].substring(0, 5);

      const text = `${CONSULT_TEXT({
        origin:origin?.city,
        target:target?.city,
        flightDate:`${date} - ${time} horas`,
        jetType:jetType
      })} `
        whatsappMessage(text)
      return;
    }
    
    if (isPaid || isExpired || isCancelled) return;

    setProcessing(true);
    let flightBookId, userBookCode;

    if (!userBook) {
      const { passengers: PassengerParam, status, ...restParams } = routeParams;
      const data = {
        bookData: { passengers },
        ...restParams,
      };
      const newFlightData = await postFlight({ data });
      //TODO: revisar en caso de falla
      flightBookId = newFlightData.data.flightBookId;
      userBookCode = newFlightData.data.userBookCode;
    } else {
      flightBookId = routeParams.id;
      userBookCode = routeParams.userBook.code;
    }

    triggerBuyFlightMetric();

    navigation.push(routes.PAYMENT_METHODS, {
      paymentMethods,
      bookData: {
        flightBookId,
        userBookCode,
        price,
        flightValue,
      },
    });
    setProcessing(false);
  };

  const getFlightValue = ()=>{
    if (flightValue === 0){
      //
      return FLIGHT_VALUE_ZERO
    }
    return flightValue
  }

  const getCommissionTotal = () =>{
    return commissionTotal * passengers
  }

  const isAgency = () => role === "agency"
  return (
    <View style={styles.reservationInfoAndAction}>
      
      <DetailValue
        getCommissionTotal={getCommissionTotal}
        getFlightValue={getFlightValue}
        flightValue={flightValue}
        currency={currency}
        isSeatType={isSeatType}
        subTotal={subTotal}
        discount={discount}
        totalSeatsText={totalSeatsText}
        isAgencyBolean={isAgency()}
              />
      <View>
        <View style={[styles.reservationActionContainer]}>
          <Button
            buttonStyles={[styles.reservationAction, buttonStyles()]}
            onPress={() => {
              !isPaid && onPressHandler();
            }}
            loading={processing}
          >
            <Text
              style={{
                fontWeight: '700',
                textTransform: 'capitalize',
                fontSize: 24,
                letterSpacing: 0,
              }}
            >
              {getButtonText()}
            </Text>
          </Button>
        </View>
      </View>
    </View>
  );
};

Reservation.propTypes = {
  currency: PropTypes.string.isRequired,
  flightValue: PropTypes.number.isRequired,
  isSeatType: PropTypes.bool.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  totalSeatsText: PropTypes.string.isRequired,
};

export default Reservation;
