import React from 'react';
import { View } from 'react-native';

import styles from './SearchInfo.styles';
import { searchInfo as searchInfoKeys } from 'i18n';
import { constants, date } from 'utils';
import Text from 'components/text/Text';
import { passengersValues } from '../../screens/search-config/data';

const { getUTCHour, getUTCMinutes } = date;

const {
  FLIGHT_TYPES: { ONE_WAY, MULTI_LEG, ROUND_TRIP },
} = constants;
const { PASSENGER, PASSENGERS } = searchInfoKeys;

const getDateText = (flightType, segments) => {
  const firstTimestamp = segments?.[0]?.date;
  const firstDate = date.millisecondsToDate(firstTimestamp).replace(/-/g, '/');

  if (flightType?.id === ONE_WAY) {
    return firstDate;
  }

  const lastTimestamp = segments?.[segments.length - 1]?.date;
  const lastDate = date.millisecondsToDate(lastTimestamp).replace(/-/g, '/');
  return `${firstDate} - ${lastDate}`;
};

const getPassengersText = (passengers) => {
  if (passengers === 1) {
    return `${passengers} ${PASSENGER}`;
  }

  return `${passengersValues[passengers - 1]?.text} ${PASSENGERS}`;
};

const getSimpleTrip = (segments) => {
  const from = segments[0].fromLocation.airports[0].name;
  const to = segments[0].toLocation.airports[0].name;

  return (
    <Text style={styles.location}>
      {from} - {to}
    </Text>
  );
};

const getTripsInfo = (segments) => {
  return segments.map((seg) => {
    const newDate = new Date(seg.date);
    return (
      <View style={styles.multipleTripsContainer}>
        <Text style={styles.text}>
          {date.millisecondsToDate(seg.date).replace(/-/g, '/')} -{' '}
          {getUTCHour(newDate)}:{getUTCMinutes(newDate)}
        </Text>
        <Text style={styles.location}>
          {seg.fromLocation.airports[0].name} {' > '}{' '}
          {seg.toLocation.airports[0].name}
        </Text>
      </View>
    );
  });
};

export { getDateText, getPassengersText, getTripsInfo };
