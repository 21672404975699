import React, { useState, useEffect } from 'react';
import firebase from 'firebase';

export default function useAuth() {
  const [loading, setLoading] = useState(true);
  const [logged, setLogged] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [useRole, setuseRole] = useState(null)

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setLogged(true);
        setLoading(false);
        setUserEmail(user.uid);
        setuseRole(user.displayName)
      } else {
        setLogged(false);
        setLoading(false);
        setUserEmail(null);
      }
    });
  }, []);

  return { loading, logged, user: userEmail ,role:useRole};
}
