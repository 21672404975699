import { StyleSheet } from 'react-native';

import { theme } from 'utils';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: 5,
    flexDirection: 'row',
    flexGrow: 1,
    height: 70,
    padding: 16,
    maxWidth: 500,
    width: '100%',
    flexShrink: 1,
  },
  textContainer: {
    flexDirection: 'column',
    textTransform: 'capitalize',
  },
  text: {
    flex: 1,
    fontSize: 18,
    marginLeft: 8,
    textTransform: 'capitalize',
  },
  iconLeft: {
    marginRight: 10,
  },
  iconRightContainer: {
    height: 24,
    position: 'relative',
    width: 24,
  },
});

export default styles;
