import React from 'react';
import { Text, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

import styles from './Spinner.styles';
import { spinner as spinnerKeys } from 'i18n';
import { theme } from 'utils';
import isotipo from 'assets/isotipo.png';
import isotipoWhite from 'assets/isotipo_white.png';
import Image from 'components/image';

const { LOADING } = spinnerKeys;

const Spinner = (props) => {
  const {
    withText = true,
    withImage = true,
    noMargin,
    color = theme.colors.primary,
  } = props;

  const getImage = () => {
    switch (color) {
      case theme.colors.primary:
        return <Image source={isotipo} style={styles.image} />;
      case theme.colors.white:
        return <Image source={isotipoWhite} style={styles.image} />;
      default:
        return <Image source={isotipo} style={styles.image} />;
    }
  };

  return (
    <View style={[styles.container, noMargin && { marginTop: 0 }]}>
      <ActivityIndicator
        animating={true}
        color={color}
        size={withImage ? 'large' : 'small'}
        {...props}
      />
      {withImage && getImage()}
      {withText && <Text style={styles.text}>{LOADING}</Text>}
    </View>
  );
};

export default Spinner;
