import React from 'react';
import PropTypes from 'prop-types';
import { ImageBackground, View } from 'react-native';

import { screen } from 'utils';
import styles from './AuthTemplate.styles';
import Logo from '../logo';
import backgroundImg from 'assets/background-new.jpg';
import backgroundBigImg from 'assets/background-big.jpeg';

const AuthTemplate = ({ children }) => (
  <View style={styles.container}>
    <ImageBackground
      source={screen.isMobile ? backgroundImg : backgroundBigImg}
      resizeMode="cover"
      style={styles.background}
      imageStyle={{ opacity: 0.9 }}
    >
      <View style={styles.logoContainer}>
        <Logo />
      </View>
      <View style={styles.content}>
        <View style={styles.formContainer}>
          <View style={styles.formChildrenContainer}>{children}</View>
        </View>
      </View>
    </ImageBackground>
  </View>
);

AuthTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthTemplate;
