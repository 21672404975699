import { StyleSheet } from 'react-native';

import { theme } from 'utils';

const styles = StyleSheet.create({
  title: {
    color: theme.colors.primary,
    fontSize: 18,
    fontWeight: '700',
  },
});

export default styles;
