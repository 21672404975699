import { StyleSheet } from 'react-native';

import { theme } from 'utils';

const styles = StyleSheet.create({
  image: {
    height: 180,
    width: 321,
    elevation: 10,
    shadowOffset: { width: 0, height: 0 },
    shadowColor: theme.colors.secondary,
    shadowOpacity: 0.7,
    shadowRadius: 15.0,
  },
});

export default styles;
