import axios from 'axios'
import axiosInstance from 'axios'

export const postDecidirPayment = ({ data, onSuccess, onError }) => {
    const { flightBookId, userBookCode } = data;
    return axios.post(
      `/flights/${flightBookId}/${userBookCode}/decidir/payment`,
      data
    );
  };
  
export const getDecidirToken = ({ data, onSuccess, onError }) => {
    const {
      card_number,
      card_expiration_month,
      card_expiration_year,
      security_code,
      card_holder_name,
      card_holder_dni,
    } = data;
  
    return axiosInstance.post(
      //test
       'https://developers.decidir.com/api/v2/tokens',
      //prod
      //'https://live.decidir.com/api/v2/tokens',
      {
        card_number,
        card_expiration_month,
        card_expiration_year,
        security_code,
        card_holder_name,
        card_holder_identification: {
          type: 'dni',
          number: card_holder_dni,
        },
      },
      {
        headers: {
          //test
          // apikey: 'pT6lJafcQ0CQgfaPHBqZwWNRmap6Ge5e',
          //prod
          apikey: 'YrSFgCXMWX1yF5bDdi5eEHDSo5VVaS1P',
          'Content-Type': 'application/json',
        },
      }
    ).then(onSuccess)
    .catch(onError);
  };

  { getDecidirToken, postDecidirPayment } 