import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper';
import useAuth from '../../oauth';

SwiperCore.use([Pagination, Autoplay, Navigation]);

import { constants, methods, routes, screen as screenSpec } from 'utils';
import CarouselSlide from './CarouselSlide';
import { useNavigation } from '@react-navigation/core';
import { flightTypeLabelMap } from 'screens/search-config/data';
import Skeleton from './components/Skeleton';
import { getDayName, getMonthName } from './BannerCarousel.utils';

const { BANNER_SELECTION } = constants.METRICS;
const { triggerMetric } = methods;
const { RESULTS } = routes;
const { isMobile, isTablet } = screenSpec;

const BannerCarousel = ({ commissions }) => {
  const navigation = useNavigation();
  const { user } = useAuth();

  const getCommmissionsSlides = () => {
    if (!commissions || !commissions.length) {
      return (
        <SwiperSlide
          style={{
            width: '100%',
          }}
        >
          <Skeleton />
        </SwiperSlide>
      );
    }

    return commissions.map((commission, i) => {
      const date = new Date(commission.segments[0].date);
      const slideProps = {
        fromCity: commission.segments[0].fromLocation.city,
        toCity: commission.segments[0].toLocation.city,
        day: getDayName(date),
        month: getMonthName(date),
        discount: commission.discount,
        url: commission.url,
      };
      return (
        <SwiperSlide
          style={{
            width: '100%',
          }}
        >
          <CarouselSlide {...slideProps} />
        </SwiperSlide>
      );
    });
  };

  const getFlightAirports = (search) => {
    const tripAirports = [];
    tripAirports.push(search.segments[0].fromLocation.airports[0]);
    tripAirports.push(search.segments[0].toLocation.airports[0]);
    return tripAirports;
  };

  const triggerSelectedSlideMetric = (segments) => {
    const origen = segments?.[0]?.fromLocation?.city;
    const destino = segments?.[0]?.toLocation?.city;
    triggerMetric({
      event: BANNER_SELECTION,
      userId: user,
      origen,
      destino,
    });
  };

  const onClick = (e) => {
    if (
      !e.clickedSlide ||
      !e.clickedSlide.getAttribute('data-swiper-slide-index')
    ) {
      return;
    }

    const clickedCommission =
      commissions[e.clickedSlide.getAttribute('data-swiper-slide-index')];

    if (!clickedCommission) {
      return;
    }

    triggerSelectedSlideMetric(clickedCommission?.segments);

    navigation.push(RESULTS, {
      ...clickedCommission,
      toLocations: [clickedCommission.segments[0].toLocation],
      flightType: {
        text: flightTypeLabelMap.oneWay,
      },
      flightAirports: getFlightAirports(clickedCommission),
    });
  };

  return (
    <Swiper
      spaceBetween={isMobile ? 20 : 50}
      slidesPerView={isMobile ? 1.3 : isTablet ? 1.6 : 3.9}
      centeredSlides={true}
      style={{
        width: isMobile ? '100%' : '100%',
      }}
      autoplay={false}
      loop
      onClick={onClick}
      pagination={commissions?.length > 1}
      navigation={isMobile ? false : true}
      initialSlide={2}
    >
      {getCommmissionsSlides()}
    </Swiper>
  );
};

export default BannerCarousel;
