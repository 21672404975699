import React from 'react';
import PropTypes from 'prop-types';
import { Image, TouchableOpacity, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import styles from './FlightThumbnails.styles';
import { flightThumbnail as flightThumbnailKeys } from 'i18n';
import { getTagStyles, getTagText, getTitle } from './FlightThumbnail.utils';
import { constants, date, methods, routes, screen, theme } from 'utils';
import Tag from './components/tag';
import Text from '../text/Text';
import SeatIcon from 'components/icons/Seat';
import PlaneIcon from 'components/icons/Jet';
import useAuth from '../../oauth';

const { BY_SEAT ,FLIGHTTHUMBNAIL_VALUE_ZERO  } = flightThumbnailKeys;
const { OFFER_SELECTION } = constants.METRICS;
const { getStringDateOrdered } = date;
const { triggerMetric } = methods;
const { DETAIL } = routes;
const { isMobile } = screen;

const FlightThumbnail = (props) => {
  const navigation = useNavigation();
  const { user } = useAuth();
  const {
    jet,
    price,
    segments,
    status,
    tags,
    titleType,
    withDate,
    type,
    emptySeats,
    userBook,
    source,
  } = props;
  const { category, maxPax, photo, type: jetType } = jet;
  const { currency, discount, value, seatPrice } = price;
  const { emptyLeg } = tags;
  // TODO: Remove harcoded `seatsLeft`.
  const seatsLeft =
    type === 'seat' && status === 'open'
      ? emptySeats < maxPax
        ? emptySeats
        : maxPax - 1
      : null;
  const homeThumbnail = source === 'home';
  const hasTag = !!seatsLeft || (!!emptyLeg && homeThumbnail);
  const containerStyles = withDate
    ? [styles.container, { marginHorizontal: isMobile ? 24 : 0 }]
    : styles.container;
  const date = new Date(segments[0]?.fromLocation?.date?.dateTimeLocal);
  const showTag = hasTag || status;

  const onPressHandler = () => {
    const origen = segments?.[0]?.fromLocation?.city;
    const destino = segments?.[0]?.toLocation?.city;
    triggerMetric({
      event: OFFER_SELECTION,
      userId: user,
      origen,
      destino,
      avion: jetType,
    });
    navigation.push(DETAIL, props);
  };

  const getPriceString = (price) => {
    return `$${Number(price).toLocaleString('es-AR')}`;
  };

  // TODO: Mejorar con loadings
  if (!segments?.[0]) {
    return null;
  }

  const Icon = type === 'seat' ? SeatIcon : PlaneIcon;


  const getPriceFlight = (type) =>{
      if(type === 'seat' && (seatPrice !== 0 && value !== 0)){
       return getPriceString(seatPrice)
      }else if (type !== 'seat' && (seatPrice !== 0 && value !== 0)){
        return getPriceString(value)
      }else{
        return FLIGHTTHUMBNAIL_VALUE_ZERO
      }
  }


  return (
    <TouchableOpacity onPress={onPressHandler} activeOpacity={1}>
      <View style={containerStyles}>
        <View style={styles.surface}>
          <View>
            <Image source={{ uri: photo }} style={styles.image} />
            {showTag && (
              <Tag
                text={getTagText({
                  status,
                  emptyLeg,
                  seatsLeft,
                  userBook,
                  source,
                })}
                styles={getTagStyles({
                  status,
                  emptyLeg,
                  seatsLeft,
                  userBook,
                  source,
                })}
              />
            )}
            {discount && (
              <Tag
                text={getTagText({ discount })}
                styles={[styles.tag, styles.discountTag]}
              />
            )}
          </View>
        </View>
        <View style={styles.imageFooter}>
          <View style={styles.jetInfo}>
            {getTitle({ segments, titleType, type: jetType })}
            {!withDate && (
              <Text style={styles.subtitle}>
                {`${category}, ${maxPax} seats`}
              </Text>
            )}
          </View>
          {withDate ? (
            <View style={{ alignItems: 'flex-end', marginTop: 8 }}>
              <Text style={styles.subtitle}>{userBook?.code}</Text>
              <Text style={styles.date}>{getStringDateOrdered(date)}</Text>
            </View>
          ) : (
            <View style={styles.priceContainer}>
              <View style={{ flexDirection: 'column' }}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Text style={styles.currency}>{currency}</Text>
                  <Icon height={16} width={18} color={theme.colors.secondary} />
                </View>
                <Text style={styles.price}>

                  {getPriceFlight()}



                </Text>
              </View>
              {homeThumbnail && (
                <Text>
                  {
                    new Date(segments[0].fromLocation.date.dateTimeLocal)
                      .toLocaleString('es')
                      .split(' ')[0]
                  }
                </Text>
              )}
            </View>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

FlightThumbnail.propTypes = {
  jet: PropTypes.object,
  price: PropTypes.object,
  segments: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  tags: PropTypes.object,
  titleType: PropTypes.string,
};

FlightThumbnail.defaultProps = {
  jet: {},
  price: {},
  segments: {},
  tags: {},
  titleType: '',
};

export default FlightThumbnail;
