import React from 'react';
import { View } from 'react-native';
import ContentLoader, { Rect } from 'react-content-loader/native';

import styles from './Skeleton.styles';
import { screen, theme } from 'utils';

const { isMobile } = screen;
const { shadow } = theme;

const Skeleton = () => (
  <View style={[styles.container, shadow]}>
    <ContentLoader
      width="100%"
      height="100%"
      speed={1}
      style={styles.content}
      backgroundColor="#2a2f3a"
      foregroundColor="#414a5a"
    >
      {/* Origen */}
      <Rect
        x="0"
        y={isMobile ? 10 : 50}
        rx="0"
        ry="0"
        width="80%"
        height="30"
      />
      {/* Línea divisora */}
      <Rect
        x="0"
        y={isMobile ? 50 : 90}
        rx="0"
        ry="0"
        width="100%"
        height="2"
      />
      {/* Destino */}
      <Rect
        x="0"
        y={isMobile ? 60 : 100}
        rx="0"
        ry="0"
        width="80%"
        height="30"
      />

      {/* Fecha */}
      <Rect
        x="0"
        y={isMobile ? 150 : 350}
        rx="0"
        ry="0"
        width="40%"
        height="10"
      />
      <Rect
        x="0"
        y={isMobile ? 170 : 370}
        rx="0"
        ry="0"
        width="40%"
        height="10"
      />

      {/* Descuento */}
      <Rect
        x={isMobile ? 120 : 265}
        y={isMobile ? 130 : 330}
        rx="0"
        ry="0"
        width={isMobile ? '50%' : '30%'}
        height="60"
      />
    </ContentLoader>
  </View>
);

export default Skeleton;
