import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native';

import Text from '../text/Text';
import { theme } from 'utils';

const CustomButton = ({
  labelStyle,
  onPress,
  text,
  selected,
  icon: Icon,
  ...restProps
}) => (
  <TouchableOpacity onPress={onPress} {...restProps}>
    {text && <Text style={[{ textAlign: 'center' }, labelStyle]}>{text}</Text>}
    {Icon && (
      <Icon
        color={selected ? theme.colors.white : theme.colors.primary}
        style={[{ textAlign: 'center' }, labelStyle]}
      />
    )}
  </TouchableOpacity>
);

CustomButton.propTypes = {
  labelStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.array])
    .isRequired,
  onPress: PropTypes.func.isRequired,
  text: PropTypes.string,
  icon: PropTypes.func,
};

export default CustomButton;
