import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Button, Menu } from 'react-native-paper';

import styles from './Select.styles';
import Text from 'components/text/Text';

const Select = ({ label, values, selectedValue, setValue, style=styles }) => {
  const [visible, setVisible] = useState(false);

  const openMenu = () => setVisible(true);

  const closeMenu = () => setVisible(false);

  const onSelectHandler = (id) => {
    setValue(id);
    closeMenu();
  };

  const getSelectedValueText = () => {
    const selectedValueObj = values.find((value) => value.id === selectedValue);
    return selectedValueObj?.text || selectedValue;
  };

  return (
    <View style={style.container}>
      <Text style={style.label}>{label}</Text>
      <Menu
        visible={visible}
        onDismiss={closeMenu}
        anchor={
          <Button
            icon="chevron-down"
            onPress={openMenu}
            style={style.button}
            contentStyle={style.contentStyle}
            labelStyle={style.labelStyle}
            color="white"
          >
            <Text style={style.text}>{getSelectedValueText()}</Text>
          </Button>
        }
      >
        {values.map((value) => (
          <Menu.Item
            key={value.id}
            onPress={() => onSelectHandler(value.id)}
            title={value.text}
          />
        ))}
      </Menu>
    </View>
  );
};

Select.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  selectedValue: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default Select;
