import { constants } from '../../utils';

const { ONE_WAY, ROUND_TRIP, MULTI_LEG } = constants.FLIGHT_TYPES;

const getOneWaySegment = ({ fromLocation, toLocations, timestamps }) => [
  {
    fromLocation,
    toLocation: toLocations[0],
    date: timestamps[0],
  },
];

const getRoundTripSegments = ({ fromLocation, toLocations, timestamps }) => {
  return [
    {
      fromLocation,
      toLocation: toLocations[0],
      date: timestamps[0],
    },
    {
      fromLocation: toLocations[0],
      toLocation: fromLocation,
      date: timestamps[1],
    },
  ];
};

const getMultiLegSegments = ({ fromLocation, toLocations, timestamps }) =>
  toLocations.map((location, i) => ({
    fromLocation: i === 0 ? fromLocation : toLocations[i - 1],
    toLocation: toLocations[i],
    date: timestamps[i],
  }));

const buildSegments = ({ flightType, ...restParams }) => {
  if (flightType === ONE_WAY) {
    return getOneWaySegment(restParams);
  }

  if (flightType === ROUND_TRIP) {
    return getRoundTripSegments(restParams);
  }

  if (flightType === MULTI_LEG) {
    return getMultiLegSegments(restParams);
  }
};

const isLastSelection = (newTimestamps, flightType, toLocations) => {
  if (flightType === ROUND_TRIP) {
    return newTimestamps.length > toLocations.length;
  }

  if (flightType === MULTI_LEG) {
    return newTimestamps.length === toLocations.length;
  }
};

// time es una fecha cualquiera pero que tiene el horario seleccionado (el que elige el user en el time picker).
// Este método devuelve un timestamp.
const setHoursAndMinutes = (timestamp, time) => {
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const currentDate = new Date(timestamp);

  currentDate.setUTCHours(hours, minutes);
  return currentDate.getTime();
};

export { buildSegments, isLastSelection, setHoursAndMinutes };
