import { StyleSheet } from 'react-native';

import { screen, theme } from '../../utils';

const styles = {
  container: {
    alignSelf: 'center',
    marginHorizontal: '5%',
    maxWidth: screen.tabletInitialResolution,
    width: '90%',
  },
  cardElementContainer: {
    backgroundColor: theme.colors.white,
    borderRadius: 5,
    padding: 20,
    elevation: 10,
    shadowOffset: { width: 0, height: 15 },
    shadowOpacity: 0.1,
    shadowRadius: 15.0,
    marginVertical: 10,
  },
  button: (disabled) => ({
    borderRadius: 8,
    paddingVertical: 8,
    backgroundColor: disabled ? theme.colors.lightGrey : theme.colors.secondary,
    shadowColor: theme.colors.secondary,
    elevation: disabled ? 0 : 10,
    shadowOffset: { width: 0, height: 15 },
    shadowOpacity: disabled ? 0 : 0.5,
    shadowRadius: 15.0,
    marginTop: 10,
  }),
  title: {
    alignSelf: 'flex-start',
    fontSize: 24,
    marginVertical: 40,
    fontWeight: '600',
  },
  price: {
    alignSelf: 'flex-start',
    fontSize: 24,
    fontWeight: '600',
  },
  error: {
    color: theme.colors.red,
  },
  stripeCard: {
    base: {
      padding: '40',
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#32325d',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
  
  selectCountry:{
    button:{
      backgroundColor:'white',
      display: 'flex',
      flexDirection: 'row',
      color:'black'
    },
    container:{},
    label:{},
    contentStyle:{},
    labelStyle:{
      color:'black'
    },
    text:{},



  }
};

export default styles;

