import { StyleSheet } from 'react-native';

import { theme } from 'utils';

const { colors, radius } = theme;

const { lightGrey, secondary, white, primary } = colors;

const styles = StyleSheet.create({
  category: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 0,
    marginTop: 10,
    textAlign: 'left',
    paddingLeft: 10,
    borderWidth: 2,
    borderColor: white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomColor: colors.lightGrey,
    borderBottomWidth: 2,
  },
  selected: {},
  selectedText: {},
  name: {
    fontSize: 18,
    fontWeight: '600',
  },
});

export default styles;
