import { StyleSheet } from 'react-native';

import { screen, theme } from 'utils';

const { isMobile } = screen;

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    backgroundColor: theme.colors.white,
    justifyContent: 'center',
    paddingHorizontal: 20,
    paddingVertical: 50,
    marginHorizontal: 20,
    marginVertical: 150,
    borderRadius: 10,
    width: isMobile ? 'auto' : 500,
    minHeight: 565,
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  title: {
    fontSize: 24,
    fontWeight: '600',
    textAlign: 'center',
  },
  categoriesContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 10,
    paddingHorizontal: 20,
  },
  name: {
    textTransform: 'uppercase',
  },
  button: {
    alignSelf: 'center',
    marginTop: 40,
    width: '100%',
    elevation: 10,
    shadowOffset: { width: 0, height: 15 },
    shadowColor: theme.colors.secondary,
    shadowOpacity: 0.5,
    shadowRadius: 15.0,
  },
});

export default styles;
