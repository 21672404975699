import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { FAB } from 'react-native-paper';

import styles from './SearchInfo.styles';
import { theme } from 'utils';
import { getPassengersText, getTripsInfo } from './SearchInfo.utils';
import Modal from './components/modal';
import Text from 'components/text/Text';

const SearchInfo = ({
  flightType,
  passengers,
  segments,
  categories,
  selectedCategories,
  setSelectedCategories,
}) => {
  const [visible, setVisible] = useState(false);

  const onFilterPress = () => setVisible(true);

  return (
    <View style={styles.containerWrapper}>
      <View style={styles.container}>
        {getTripsInfo(segments)}
        <View style={{ flexDirection: 'row' }}>
          <Text style={styles.text}>
            {flightType?.text} - {getPassengersText(passengers)}
          </Text>
        </View>
        <View>
          <FAB
            style={styles.fab}
            small={false}
            icon="filter"
            onPress={onFilterPress}
            color={theme.colors.white}
            disabled={!categories}
          />
        </View>
        <Modal
          visible={visible}
          setVisible={setVisible}
          categories={categories}
          selected={selectedCategories}
          setSelected={setSelectedCategories}
        />
      </View>
    </View>
  );
};

SearchInfo.propTypes = {
  flightType: PropTypes.object.isRequired,
  passengers: PropTypes.number.isRequired,
  segments: PropTypes.array.isRequired,
};

export default SearchInfo;
