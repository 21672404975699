import React, { useState } from 'react';
import { TouchableOpacity, View, Linking} from 'react-native';
import { IconButton, Menu } from 'react-native-paper';
import firebase from 'firebase';
import { useNavigation } from '@react-navigation/native';
import styles from './Menu.styles';
import { navbar as navbarKeys } from 'i18n';
import { routes, screen } from 'utils';
import Text from 'components/text/Text';

const { LOGOUT, MEMBERSHIP_TITLE, MY_BOOKINGS, TERMS_CONDITIONS_TITLE, LOGIN, ABOUT_US } =
  navbarKeys;
const { BOOKINGS, LOG_IN, MEMBERSHIP, TERMS_CONDITIONS, ABOUTUS } = routes;
const { isBigScreen } = screen;

const MenuComponent = ({ iconsColor, logged }) => {
  const navigation = useNavigation();
  const [visible, setVisible] = useState(false);
  const isLogged = logged !== null && logged;
  const isNotLogged = logged !== null && !logged;

  const openMenu = () => setVisible(true);

  const closeMenu = () => setVisible(false);

  const onSelectHandler = (screen, withPush) => {
    closeMenu();
    if (screen) {
      if (withPush) {
        navigation.push(screen);
      } else {
        navigation.navigate(screen);
      }
    }
  };

  const onLogoutHandler = () => {
    firebase.auth().signOut();
    closeMenu();
    navigation.navigate(LOG_IN);
  };

  const renderMobileMenu = () => (
    <Menu
      visible={visible}
      onDismiss={closeMenu}
      anchor={
        <IconButton
          icon="menu"
          size={28}
          color={iconsColor}
          onPress={openMenu}
        />
      }
    >
      {isNotLogged && (
        <>
          <Menu.Item
            icon="login"
            key="log in"
            onPress={() => onSelectHandler(LOG_IN)}
            title={LOGIN}
          />
          <Menu.Item
            icon="card-account-details-star-outline"
            key="membership"
            onPress={() => onSelectHandler(MEMBERSHIP)}
            title={MEMBERSHIP_TITLE}
          />
          <Menu.Item
            icon="text-box-outline"
            key="terms and conditions"
            onPress={() => onSelectHandler(TERMS_CONDITIONS)}
            title={TERMS_CONDITIONS_TITLE}
          />
        </>
      )}
      {isLogged && (
        <>
          <Menu.Item
            icon="airplane"
            key="bookings"
            onPress={() => onSelectHandler(BOOKINGS, true)}
            title={MY_BOOKINGS}
          />
          <Menu.Item
            icon="card-account-details-star-outline"
            key="membership"
            onPress={() => onSelectHandler(MEMBERSHIP)}
            title={MEMBERSHIP_TITLE}
          />
          <Menu.Item
            icon="text-box-outline"
            key="terms and conditions"
            onPress={() => onSelectHandler(TERMS_CONDITIONS)}
            title={TERMS_CONDITIONS_TITLE}
          />
          <Menu.Item
            icon="logout"
            key="sign out"
            onPress={onLogoutHandler}
            title={LOGOUT}
          />
        </>
      )}
    </Menu>
  );

  const renderDesktopMenu = () => (
    <View style={styles.container}>
      {isNotLogged && (
        <>
          <TouchableOpacity onPress={() => onSelectHandler(LOG_IN)}>
            <Text style={styles.menuText}>{LOGIN}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => onSelectHandler(MEMBERSHIP)}>
            <Text style={styles.menuText}>{MEMBERSHIP_TITLE}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => onSelectHandler(ABOUTUS)}>
            <Text style={styles.menuText} onPress={() => Linking.openURL('https://www.web.cruxjets.com/')}>{ABOUT_US}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => onSelectHandler(TERMS_CONDITIONS)}>
            <Text style={styles.menuText}>{TERMS_CONDITIONS_TITLE}</Text>
          </TouchableOpacity>
        </>
      )}
      {isLogged && (
        <>
          <TouchableOpacity onPress={() => onSelectHandler(BOOKINGS, true)}>
            <Text style={styles.menuText}>{MY_BOOKINGS}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => onSelectHandler(MEMBERSHIP)}>
            <Text style={styles.menuText}>{MEMBERSHIP_TITLE}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => onSelectHandler(ABOUTUS)}>
            <Text style={styles.menuText} onPress={() => Linking.openURL('https://www.web.cruxjets.com/')}>{ABOUT_US}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => onSelectHandler(TERMS_CONDITIONS)}>
            <Text style={styles.menuText}>{TERMS_CONDITIONS_TITLE}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={onLogoutHandler}>
            <Text style={styles.menuText}>{LOGOUT}</Text>
          </TouchableOpacity>
        </>
      )}
    </View>
  );
  return <>{!isBigScreen ? renderMobileMenu() : renderDesktopMenu()}</>;
};

export default MenuComponent;
