import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  logo: {
    height: 100,
    width: 155,
  },
});

export default styles;
