import { StyleSheet } from 'react-native';

import { screen } from 'utils';

const { isMobile } = screen;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    flexWrap: isMobile ? 'nowrap' : 'wrap',
    justifyContent: 'center',
    marginTop: 10,
    width: 'auto',
    maxWidth: 2000,
    marginHorizontal: isMobile ? 0 : 'auto',
  },
});

export default styles;
