import React, { useContext, useState } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';

import { login as loginKeys } from 'i18n';
import { constants, methods, routes } from 'utils';
import { logIn } from 'api';
import LogInComponent from './components/log-in';
import { SnackbarContext } from '../../contexts/SnackbarContext';

const { LOG_IN_BUTTON, GO_TO_REGISTER } = constants.METRICS;
const { triggerMetric } = methods;
const { INVALID_LOGIN, VERIFY_ACCOUNT } = loginKeys;
const { HOME, RECOVER_PASSWORD, SIGN_UP, LOG_IN } = routes;

const LogIn = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { goBack } = route.params || {};
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { setSnackbar } = useContext(SnackbarContext);

  const logInSuccessful = () => {
    if (goBack) {
      navigation.goBack();
    } else {
      const nextRoute = route?.name || HOME;
      navigation.navigate(nextRoute !== LOG_IN ? nextRoute : HOME);
    }
  };

  const verifyAccount = () =>
    setSnackbar({ message: VERIFY_ACCOUNT, visible: true });

  const logInError = () =>
    setSnackbar({ message: INVALID_LOGIN, type: 'error', visible: true });

  const triggerSuccessfulLogInMetric = (userId) =>
    triggerMetric({ event: LOG_IN_BUTTON, userId });

  const triggerRegistrationMetric = (userId) =>
    triggerMetric({ event: GO_TO_REGISTER });

  const onSuccess = (res) => {
    setIsLoading(false);
    triggerSuccessfulLogInMetric(res?.user?.email);
    logInSuccessful();
  };

  const onError = (err) => {
    setIsLoading(false);
    if (err?.request?.status === 403) {
      verifyAccount();
    } else {
      console.error(err);
      logInError(err);
    }
  };

  const logInHandler = () => {
    setIsLoading(true);
    logIn({ data: { email, password }, onSuccess, onError });
  };

  const signUp = () => {
    triggerRegistrationMetric();
    return navigation.navigate(SIGN_UP);
  };

  const forgotPassword = () => navigation.navigate(RECOVER_PASSWORD);

  return (
    <>
      <LogInComponent
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        forgotPassword={forgotPassword}
        logIn={logInHandler}
        signUp={signUp}
        isLoading={isLoading}
      />
    </>
  );
};

export default LogIn;
