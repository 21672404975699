const getDate = (date) => ('0' + date.getUTCDate()).slice(-2);

const getMonth = (date) => ('0' + (date.getUTCMonth() + 1)).slice(-2);

const getYear = (date) => date.getUTCFullYear();

const getHour = (date) => ('0' + date.getHours()).slice(-2);
const getUTCHour = (date) => ('0' + date.getUTCHours()).slice(-2);

const getMinutes = (date) => ('0' + date.getMinutes()).slice(-2);
const getUTCMinutes = (date) => ('0' + date.getUTCMinutes()).slice(-2);

export const millisecondsToDate = (milliseconds) => {
  const convertedDate = new Date(milliseconds);
  const date = getDate(convertedDate);
  const month = getMonth(convertedDate);
  const year = getYear(convertedDate);

  return `${date}-${month}-${year}`;
};

const getStringDate = (date) => date.toISOString().substring(0, 10);

// receives a new Date()
// returns -> "30/06/2021"
const getStringDateOrdered = (date) =>
  `${getDate(date)}/${getMonth(date)}/${getYear(date)}`;

const getTimeBetweenTwoTimestamps = (startTimestmap, endTimestamp) => {
  const diff = startTimestmap.getTime() - endTimestamp.getTime();
  const diffInMinutes = Math.abs(Math.round(diff / 60000));
  const hours = Math.floor(diffInMinutes / 60);
  const remainingMinutes = diffInMinutes - 60 * hours;

  return {
    hours,
    minutes: remainingMinutes,
  };
};

export default {
  getHour,
  getUTCHour,
  getMinutes,
  getUTCMinutes,
  getStringDate,
  millisecondsToDate,
  getStringDateOrdered,
  getTimeBetweenTwoTimestamps,
};
