import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: 5,
    padding: 10,
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  rowTitle: {
    fontWeight: '700',
    marginRight: 10,
  },
  text: {
    fontSize: 16,
    fontWeight: '700',
  },
  code: {
    fontSize: 16,
  },
});

export default styles;
