const black = '#000';

export default {
  colors: {
    primary: '#2E3440',
    secondary: '#A5548A',
    white: '#fff',
    black,
    lightGrey: '#e0e0e0',
    mediumGrey: '#999',
    darkGrey: '#333',
    red: '#C90202',
    success: '#57CC99',
    background: '#2E3440',
    gold: '#FFD700',
  },
  shadow: {
    shadowColor: black,
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.2,
    shadowRadius: 16.0,
    elevation: 20,
  },
  radius: 10,
  thumbnailHeight: 200,
  thumbnailWidth: 400,
};
