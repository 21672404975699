import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { IconButton } from 'react-native-paper';

import styles from './LocationInput.styles';
import { methods } from 'utils';
import FakeTextInput from 'components/fake-text-input';
import { PlaneArrival } from 'components/icons';

const { isAnEmptyObject } = methods;

const LocationInput = ({
  location,
  locationsQuantity,
  index,
  onFocus,
  onPress,
}) => {
  const getLabel = () => {
    if (location && !isAnEmptyObject(location)) {
      const { airports, city, country } = location;
      return [airports?.[0]?.name, `${city}, ${country.name.toUpperCase()}`];
    }

    return '';
  };
  const hasMoreThanOneLocation = locationsQuantity > 1;

  return (
    <View style={styles.container}>
      <FakeTextInput
        Icon={PlaneArrival}
        onPress={() => onFocus({ locationIndex: index })}
        text={getLabel() || 'Ingrese destino'}
        textStyles={styles.input}
      />

      {hasMoreThanOneLocation && (
        <IconButton
          icon="minus"
          size={16}
          style={styles.iconButton}
          onPress={() => onPress(index)}
        />
      )}
    </View>
  );
};

LocationInput.propTypes = {
  location: PropTypes.object,
  locationsQuantity: PropTypes.number,
  index: PropTypes.number,
  onFocus: PropTypes.func,
  onPress: PropTypes.func,
};

LocationInput.defaultProps = {
  location: null,
  locationsQuantity: null,
  index: null,
  onFocus: null,
  onPress: null,
};

export default LocationInput;
