import React from 'react';
import { View } from 'react-native';

import StripeButton from './lib/StripeButton';
import DecidirButton from './lib/DecidirButton'
import TransferButton from './lib/TransferButton';
import CallButton from './lib/CallButton';

const PaymentButton = (props) => {
  switch (props.id) {
    case 'decidir':
      return <DecidirButton {...props} />;
    case 'stripe':
      return <StripeButton {...props} />;
    case 'abroad':
      return <TransferButton {...props} />;
    case 'local':
      return <TransferButton {...props} />;
    case 'clickToCall':
      return <CallButton {...props} />;
    default:
      return <View></View>;
      break;
  }
};

export default PaymentButton;
