import firebase from 'firebase';

import firebaseConfig from './firebaseConfig';

const firebaseInit = () => {
  if (!Boolean(firebase?.apps?.length)) {
    firebase.initializeApp(firebaseConfig);
  }
};

export { firebaseInit };
