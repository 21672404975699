import { Platform } from 'react-native';
import * as Analytics from 'expo-firebase-analytics';

export const GADebugModeEnabled = (enabled) => {
  // La línea de abajo es para habilitar el modo de depuración en el dispositivo de desarrollo
  // y poder subir eventos con un retraso mínimo.
  if (Platform.OS !== 'web') {
    // Para habilitarlo en web, hay que descargar una extensión de chrome (https://firebase.google.com/docs/analytics/debugview#web)
    Analytics.setDebugModeEnabled(true);
  }
};
