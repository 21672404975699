import React from 'react';
import PropTypes from 'prop-types';
import { CalendarList } from 'react-native-calendars';

import styles from './Calendar.styles';
import './localeConfig';
import { screen, methods, theme } from 'utils';
import {
  getDateStyles,
  isCurrentDateGreater,
  selectInnerDates,
} from './Calendar.utils';

const { deviceWidth } = screen;
const { primary, white, secondary, lightGrey } = theme.colors;

const calendarTheme = {
  todayTextColor: secondary,
  selectedDayBackgroundColor: primary,
  textSectionTitleDisabledColor: lightGrey,
  textDisabledColor: lightGrey,
  textInactiveColor: lightGrey,
  'stylesheet.day.basic': {
    todayText: {
      color: primary,
      fontWeight: '800',
    },
    selected: {
      borderRadius: 50,
      height: 42,
      width: 42,
      padding: 5,
      margin: -5,
      backgroundColor: primary,
      elevation: 10,
      shadowOffset: { width: 0, height: 15 },
      shadowColor: primary,
      shadowOpacity: 0.5,
      shadowRadius: 15.0,
    },
    inactiveText: {
      backgroundColor: primary,
    },
    selectedText: {
      color: white,
      fontWeight: '800',
    },
  },
};

const Calendar = ({
  onDaySelected,
  // `markedDates` es un objeto donde las keys son fechas y los values
  // contienen info para esa fecha en particular.
  // Ej. { '2012-05-21': { startingDay: true, endingDay: true, color: 'red', textColor: 'white'} }
  markedDates = {},
}) => {
  const onDayPress = (dateObj) => {
    const { dateString, timestamp } = dateObj;
    const todayTimestamp = new Date().getDate();
    const newMarkedDates = { ...markedDates };

    if (isCurrentDateGreater(newMarkedDates, dateString)) {
      newMarkedDates[dateString] = getDateStyles({ timestamp });
      selectInnerDates(newMarkedDates, timestamp, todayTimestamp);
      onDaySelected(newMarkedDates, timestamp);
    }
  };

  return (
    <CalendarList
      // Set custom calendarWidth
      calendarWidth={deviceWidth}
      theme={calendarTheme}
      style={styles.container}
      // Handler which gets executed on day press. Default = undefined
      onDayPress={onDayPress}
      // If firstDay=1 week starts from Monday. Note that dayNames and dayNamesShort should still start from Sunday.
      firstDay={1}
      // Disable all touch events for disabled days. can be override with disableTouchEvent in markedDates
      disableAllTouchEventsForDisabledDays={true}
      // Collection of dates that have to be colored in a special way. Default = {}
      markedDates={markedDates}
      // Date marking style [simple/period/multi-dot/custom]. Default = 'simple'
      markingType={'simple'}
      // Max amount of months allowed to scroll to the past. Default = 50
      pastScrollRange={0}
      // Max amount of months allowed to scroll to the future. Default = 50
      futureScrollRange={12}
      // Enable or disable scrolling of calendar list
      scrollEnabled={true}
      // Enable or disable vertical scroll indicator. Default = false
      showScrollIndicator={true}
      enableSwipeMonths={false}
      // Minimum date that can be selected, dates before minDate will be grayed out. Default = undefined
      minDate={Date()}
    />
  );
};

Calendar.propTypes = {
  onDaySelected: PropTypes.func.isRequired,
  markedDates: PropTypes.object,
};

export default Calendar;
