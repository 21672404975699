import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, View } from 'react-native';

import styles from './FakeTextInput.styles';
import { theme } from 'utils';
import Text from '../text/Text';

const FakeTextInput = ({
  Icon,
  iconAlignRight = false,
  onPress,
  text,
  textStyles,
}) => {
  const textClasses = [styles.text, textStyles];
  const renderTexts = () => {
    if (typeof text === 'string') {
      return <Text style={textClasses}>{text}</Text>;
    }

    return (
      <View style={styles.textContainer}>
        {text.map((t, i) => {
          const classes =
            i === 0 ? [textStyles, { fontWeight: '700' }] : textStyles;
          return (
            <Text
              key={i}
              style={[
                classes,
                { flexGrow: 1, marginRight: 30, textTransform: 'capitalize' },
              ]}
              ellipsizeMode="tail"
              numberOfLines={1}
            >
              {t}
            </Text>
          );
        })}
      </View>
    );
  };

  return (
    <TouchableOpacity
      style={[styles.container, theme.shadow]}
      onPress={onPress}
      activeOpacity={1}
    >
      {!iconAlignRight && <Icon style={styles.iconLeft} />}
      {renderTexts()}
      {iconAlignRight && (
        <View style={styles.iconRightContainer}>
          <Icon />
        </View>
      )}
    </TouchableOpacity>
  );
};

FakeTextInput.propTypes = {
  Icon: PropTypes.func.isRequired,
  onPress: PropTypes.func.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default FakeTextInput;
