import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={158}
      height={77}
      viewBox="0 0 158 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M1 76v0c43.28-42.29 112.601-42.167 156 0v0"
        stroke="#A5548A"
        strokeDasharray="10 5"
      />
      <Path
        d="M92.835 39.735c.048.3-.05.54-.292.718L78.401 50.555a.703.703 0 01-.608.102.684.684 0 01-.457-.41l-2.115-5.035-4.238.418a.657.657 0 01-.568-.205.603.603 0 01-.143-.205.7.7 0 01-.055-.426.697.697 0 01.198-.379l2.754-2.754 15.176-1.54-15.736-1.136-1.84-4.395c-.12-.269-.08-.519.12-.75.199-.221.438-.292.717-.213l20.708 5.556a.683.683 0 01.324.181.692.692 0 01.197.371z"
        fill="#A5548A"
      />
    </Svg>
  );
}

export default SvgComponent;
