import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity, View } from 'react-native';

import Text from 'components/text/Text';
import styles from '../PaymentButton.styles';
import { IconButton } from 'react-native-paper';
import { paymentMethods } from 'i18n';
import { routes, theme } from 'utils';

const { DECIDIR_FORM } = routes;

const DecidirButton = ({ bookData }) => {
  const navigation = useNavigation();

  const onPressHandler = () => {
    return navigation.push(DECIDIR_FORM, { bookData });
  };

  return (
    <TouchableOpacity onPress={onPressHandler}>
      <View style={styles.paymentMethod}>
        <IconButton icon="credit-card" size={28} color={theme.colors.primary} />
        <Text style={styles.paymentLabel}>{paymentMethods.DECIDIR}</Text>
      </View>
    </TouchableOpacity>
  );
};

export default DecidirButton;
