import * as React from 'react';
import { Text as NativeText, Platform } from 'react-native';

import {
  useFonts,
  OpenSans_300Light,
  OpenSans_300Light_Italic,
  OpenSans_400Regular,
  OpenSans_400Regular_Italic,
  OpenSans_600SemiBold,
  OpenSans_600SemiBold_Italic,
  OpenSans_700Bold,
  OpenSans_700Bold_Italic,
  OpenSans_800ExtraBold,
  OpenSans_800ExtraBold_Italic,
} from '@expo-google-fonts/open-sans';

const CUSTOM_FONT_FAMILY = {
  regular: 'SomeFont',
  bold: 'SomeFont-Bold',
  italic: 'SomeFont-Italic',
  boldItalic: 'SomeFont-BoldItalic',
};
const B700_FONT_WEIGHT_REGEX = /"(font(?:-w|W)eight)":("(?:700|bold)")/;
const B600_FONT_WEIGHT_REGEX = /"(font(?:-w|W)eight)":("(?:600)")/;
const B800_FONT_WEIGHT_REGEX = /"(font(?:-w|W)eight)":("(?:[89]00)")/;
const ITALIC_FONT_STYLE_REGEX = /"(font(?:-s|S)tyle)":("italic")/;

const hasStyleProp = (tester) => (style) =>
  style ? tester.test(JSON.stringify(style)) : false;
const has600BoldFontProp = hasStyleProp(B600_FONT_WEIGHT_REGEX);
const has700BoldFontProp = hasStyleProp(B700_FONT_WEIGHT_REGEX);
const has800BoldFontProp = hasStyleProp(B800_FONT_WEIGHT_REGEX);
const hasItalicFontProp = hasStyleProp(ITALIC_FONT_STYLE_REGEX);

function Text(props) {
  let [fontsLoaded] = useFonts({
    OpenSans_300Light,
    OpenSans_300Light_Italic,
    OpenSans_400Regular,
    OpenSans_400Regular_Italic,
    OpenSans_600SemiBold,
    OpenSans_600SemiBold_Italic,
    OpenSans_700Bold,
    OpenSans_700Bold_Italic,
    OpenSans_800ExtraBold,
    OpenSans_800ExtraBold_Italic,
  });

  if (!fontsLoaded) return null;

  const makeFontFamilyPropFix = (style) =>
    Platform.OS === 'ios'
      ? {
          fontFamily: has600BoldFontProp(style)
            ? 'OpenSans_600SemiBold'
            : has700BoldFontProp(style)
            ? 'OpenSans_700Bold'
            : has800BoldFontProp(style)
            ? 'OpenSans_800ExtraBold'
            : hasItalicFontProp(style)
            ? 'OpenSans_400Regular_Italic'
            : 'OpenSans_400Regular',
        }
      : {
          fontFamily: 'OpenSans_400Regular',
        };
  return (
    <NativeText
      {...props}
      style={[props.style, makeFontFamilyPropFix(props.style)]}
    >
      {props.children}
    </NativeText>
  );
}

export default Text;
