import { StyleSheet } from 'react-native';

import { theme } from 'utils';

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  input: {
    backgroundColor: theme.colors.white,
    borderWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 5,
    padding: 0,
    shadowColor: theme.colors.black,
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.2,
    shadowRadius: 16.0,
    elevation: 20,
    paddingRight: 0,
  },
  error: {
    backgroundColor: '#f43535',
    borderBottomEndRadius: 5,
    borderBottomStartRadius: 5,
    borderTopEndRadius: 0,
    borderTopStartRadius: 0,
    color: 'white',
    marginTop: -15,
    marginBottom: 10,
  },
});

export default styles;
