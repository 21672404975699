import React, { useContext, useState } from 'react';
import { useNavigation } from '@react-navigation/native';

import { recoverPassword as recoverPasswordRequest } from 'api';
import { recoverPassword as recoverPasswordKeys } from 'i18n';
import { routes } from 'utils';
import RecoverPasswordComponent from 'components/recover-password';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import errorTranslate from 'i18n/errorTranslate';

const { CHECK_EMAIL, INVALID_RECOVERY, MISSING_FIELD } = recoverPasswordKeys;
const { LOG_IN } = routes;

const RecoverPassword = () => {
  const navigation = useNavigation();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { setSnackbar } = useContext(SnackbarContext);

  const onSuccess = (res) => {
    setIsLoading(false);
    setSnackbar({ message: CHECK_EMAIL, type: 'success', visible: true });
    navigation.navigate(LOG_IN);
  };

  const onError = (err) => {
    setIsLoading(false);
    if (err?.response?.data?.missingFields) {
      setSnackbar({ message: MISSING_FIELD, type: 'error', visible: true });
    }
  };

  const recoverPasswordHandler = () => {
    const data = { email };
    setIsLoading(true);
    recoverPasswordRequest({ data, onSuccess, onError });
  };

  const onCancel = () => navigation.navigate(LOG_IN);

  return (
    <>
      <RecoverPasswordComponent
        email={email}
        setEmail={setEmail}
        recoverPassword={recoverPasswordHandler}
        onCancel={onCancel}
        isLoading={isLoading}
      />
    </>
  );
};

export default RecoverPassword;
