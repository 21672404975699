import React from 'react';
import PropTypes from 'prop-types';

import styles from './ReservationType.styles';
import { detail as detailKeys } from 'i18n';
import Text from 'components/text/Text';

const { BOOKING_FLIGHT } = detailKeys;

const ReservationType = ({ isSeatType }) => (
  <>
    {!isSeatType && (
      <Text style={styles.reservationType}>{BOOKING_FLIGHT}</Text>
    )}
  </>
);

ReservationType.propTypes = {
  isSeatType: PropTypes.bool.isRequired,
};

export default ReservationType;
