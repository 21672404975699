import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import styles from './SearchNavbar.styles';
import { theme } from 'utils';
import TextInput from '../text-input';

const SearchNavbar = ({ text, setText, placeholder }) => {
  const navigation = useNavigation();
  const ref = useRef(null);

  const onPressIconLeft = () => {
    navigation.goBack();
  };

  const onPressIconRight = () => {
    setText('');
  };

  useEffect(() => {
    ref?.current?.focus?.();
  });

  return (
    <View style={styles.container}>
      <TextInput
        placeholder={placeholder || 'Buscar destino'}
        placeholderTextColor={theme.colors.primary}
        inputStyles={styles.searchInput}
        iconRight="close"
        iconRightStyles={styles.iconRight}
        value={text}
        onChangeText={(value) => setText(value)}
        onPressIconLeft={onPressIconLeft}
        onPressIconRight={onPressIconRight}
        ref={ref}
      />
    </View>
  );
};

SearchNavbar.propTypes = {
  text: PropTypes.string,
  setText: PropTypes.func.isRequired,
};

SearchNavbar.defaultProps = {
  text: '',
};

export default SearchNavbar;
