import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { theme } from 'utils';

function SvgComponent(props) {
  const { color = theme.colors.primary, height = 22, width = 23 } = props;

  return (
    <Svg
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M2.30056 11.7931L0.599609 13.494L6.55294 16.0455L9.10557 22L10.8065 20.299L9.95605 16.0455L13.9414 12.0601L18.284 21.3276L19.8947 19.7168L18.4632 7.5395L21.8651 4.13639C22.0949 3.91446 22.2782 3.64898 22.4043 3.35545C22.5304 3.06193 22.5967 2.74623 22.5995 2.42678C22.6023 2.10733 22.5414 1.79052 22.4205 1.49485C22.2995 1.19918 22.1208 0.930558 21.8949 0.704664C21.6691 0.47877 21.4004 0.300127 21.1048 0.179157C20.8091 0.058188 20.4923 -0.0026851 20.1728 9.08375e-05C19.8534 0.00286677 19.5377 0.0692362 19.2442 0.195326C18.9506 0.321415 18.6852 0.504699 18.4632 0.734485L14.9711 4.22661L2.79377 2.79391L1.27205 4.31683L10.4673 8.73161L6.55415 12.6448L2.30056 11.7931Z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
