import React from 'react';
import PropTypes from 'prop-types';
import { FlatList } from 'react-native';

const SearchList = ({ data, renderItem }) => {
  const getKey = (item) => {
    const { airports, city, country } = item;
    const str = `${airports?.[0]?.name}-${country?.code}-${city}-${country?.name}`;
    return str.split(' ').join('');
  };

  return (
    <FlatList data={data} renderItem={renderItem} keyExtractor={getKey} keyboardShouldPersistTaps="handled" />
  );
};

SearchList.propTypes = {
  data: PropTypes.array,
  renderItem: PropTypes.func.isRequired,
};

SearchList.defaultProps = {
  data: null,
};

export default SearchList;
