import React, { useState } from 'react';
import { Modal, Portal } from 'react-native-paper';

import styles from './TimePicker.web.styles';
import TimeKeeper from 'react-timekeeper';

const TimePicker = ({
  isVisible = true,
  hideDatePicker,
  setDate,
  DEFAULT_HOUR,
  DEFAULT_MERIDIEM,
}) => {
  const [time, setTime] = useState(`${DEFAULT_HOUR}:00${DEFAULT_MERIDIEM}`);
  const [hour, setHour] = useState(`${DEFAULT_HOUR}`);
  const [minute, setMinute] = useState('00');

  const onChange = (time) => {
    setTime(time.formatted12);
    setHour(time.hour);
    setMinute(time.minute);
  };

  const onDoneClick = () => {
    const date = new Date();
    date.setHours(hour, minute);
    setDate(date);
    hideDatePicker();
  };

  const onClose = () => {
    hideDatePicker();
  };

  return (
    <Portal>
      <Modal
        visible={isVisible}
        contentContainerStyle={styles.container}
        onDismiss={onClose}
      >
        <div className={styles.container}>
          <TimeKeeper
            time={time}
            onChange={onChange}
            onDoneClick={onDoneClick}
          />
        </div>
      </Modal>
    </Portal>
  );
};

export default TimePicker;
