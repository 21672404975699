import React, { useState } from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useRoute } from '@react-navigation/native';
import { FAB } from 'react-native-paper';
import * as Clipboard from 'expo-clipboard';
import * as DocumentPicker from 'expo-document-picker';
import DetailValue from '../detail/components/DetailValue'
import styles from './PaymentDetail.styles';
import { putPayment } from 'api';
import { paymentDetail as paymentDetailKeys } from '../../i18n';
import { routes, theme } from 'utils';
import Button from '../../components/button';
import Text from 'components/text/Text';
import useAuth from '../../oauth';

const { HOME } = routes;
const { TITLE, UPLOAD_FILE, UPLOAD_LATER } = paymentDetailKeys;

const PaymentDetail = () => {
  const {user,role} = useAuth()
  const navigation = useNavigation();
  const route = useRoute();
  const { data, label, bookData, id,passengers} = route.params;

  const { currency,discount } = bookData.price;
  const { flightBookId, userBookCode } = bookData;

  const pickDocument = () => {
    let result = DocumentPicker.getDocumentAsync({
      type: '*/*',
      copyToCacheDirectory: true,
    }).then(async (response) => {
      if (response.type == 'success') {
        let { uri } = response;
        const fetchResponse = await fetch(uri);
        const blob = await fetchResponse.blob();

        postDocument(blob);
      }
    });
  };

  const postDocument = (blob) => {
    const formData = new FormData();
    const extension = blob.type.split('/')[1];
    const imageFile = new File([blob], `${Date.now()}.${extension}`, {
      type: blob.type,
    });
    formData.append('file', imageFile);

    putPayment({
      data: {
        file: formData,
        flightBookId: flightBookId,
        userBookCode: userBookCode,
        paymentMethodId: id,
      },
      onSuccess: () => {
        return navigation.navigate(HOME);
      },
      onError: () => {
        console.log('errorrrrrrr');
      },
    });
  };

  const transferToLabel = data.cbu ? 'CBU' : 'Swift';
  const transferToValue = data.cbu ? data.cbu : data.swift;

  const cuitOrECNLabel = data.cuit ? 'CUIT Number' : 'Account';
  const cuitOrECNValue = data.cuit ? data.cuit : data.account_num;

  const isAgency = () => role === "agency"
  const getFlightValue = ()=>{
      return bookData.flightValue
  }
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{label}</Text>
      <View style={styles.info}>
        <View>
          <Text style={styles.rowTitle}>{transferToLabel}</Text>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={[styles.rowValue, { marginTop: 0 }]}>
              {transferToValue}
            </Text>
            <FAB
              style={styles.fab}
              small={true}
              icon="content-copy"
              onPress={() => Clipboard.setString(data.cbu)}
              color={theme.colors.white}
            />
          </View>
        </View>
        <View style={styles.infoRow}>
          <View>
            <Text style={styles.rowTitle}>Holder</Text>
            <Text style={styles.rowValue}>{data.holder}</Text>
          </View>
          {data.type_account && (
            <View style={{ columnGap: '2em' }}>
              <Text style={styles.rowTitle}>Type of account</Text>
              <Text style={styles.rowValue}>{data.type_account}</Text>
            </View>
          )}
        </View>
        <View style={styles.infoRow}>
          <View>
            <Text style={styles.rowTitle}>Bank</Text>
            <Text style={styles.rowValue}>{data.bank}</Text>
          </View>
          <View style={{ columnGap: '2em' }}>
            <Text style={styles.rowTitle}>{cuitOrECNLabel}</Text>
            <Text style={styles.rowValue}>{cuitOrECNValue}</Text>
          </View>
        </View>
      </View>
      <View style={{height: 14}} />
      <DetailValue
        getFlightValue={getFlightValue}
        flightValue={bookData.flightValue}
        currency={currency}
        discount={discount}
        isAgencyBolean={isAgency()}
              />
      <Button buttonStyles={styles.button} onPress={pickDocument}>
        <Text
          style={{
            fontWeight: '600',
            textTransform: 'capitalize',
            fontSize: 20,
            letterSpacing: 0,
          }}
        >
          {UPLOAD_FILE}
        </Text>
      </Button>

      <Text style={{ marginTop: 20 }}>{UPLOAD_LATER}</Text>
    </View>
  );
};

export default PaymentDetail;
