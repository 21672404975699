import { StyleSheet } from 'react-native';

import { theme } from '../../utils';

const styles = StyleSheet.create({
  title: {
    alignSelf: 'center',
    fontSize: 24,
    marginBottom: 40,
    marginTop: 40,
  },
  paymentMethod: {
    width: '100%',
    height: 80,
    backgroundColor: theme.colors.white,
    borderRadius: 10,
    flexDirection: 'row-reverse',
    marginBottom: 20,
    padding: 20,
    alignItems: 'center',
    elevation: 10,
    justifyContent: 'space-between',
    shadowOffset: { width: 0, height: 15 },
    shadowColor: theme.colors.primary,
    shadowOpacity: 0.1,
    shadowRadius: 15.0,
  },
  paymentLabel: {
    fontSize: 18,
  },
});

export default styles;
