import React from 'react';
import { View } from 'react-native';
import { TextInput } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';

import styles from './Banner.styles';
import { banner as bannerKeys } from 'i18n';
import { routes, theme } from 'utils';
import aircraftImg from 'assets/jet.png';
import Image from '../image';
import FakeTextInput from '../fake-text-input';
import CommissionsCarousel from 'components/carousel/CommissionCarousel';

const { SEARCH_PLACEHOLDER } = bannerKeys;
const { SEARCH } = routes;
const IconMagnify = (props) => (
  <TextInput.Icon color={theme.colors.primary} size={32} icon="magnify" />
);

const Banner = () => {
  const navigation = useNavigation();

  const onPressHandler = () => {
    navigation.push(SEARCH, { type: 'toLocation' });
  };
  return (
    <View style={styles.container}>
      <View style={styles.swiperContainer}>
        <Image source={aircraftImg} style={styles.aircraft} />
        <CommissionsCarousel />
      </View>
      <View style={styles.surface}>
        <View style={styles.surfaceBackground} />
        <View style={styles.searchContainer}>
          <FakeTextInput
            Icon={IconMagnify}
            iconAlignRight={true}
            onPress={onPressHandler}
            text={SEARCH_PLACEHOLDER}
            textStyles={styles.searchInput}
          />
        </View>
      </View>
    </View>
  );
};

export default Banner;
