import { StyleSheet } from 'react-native';

import { theme } from 'utils';

const { colors, radius, thumbnailHeight, thumbnailWidth } = theme;
const { primary, red, secondary, white, success, mediumGrey, gold, lightGrey } =
  colors;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    marginBottom: 10,
    width: 'auto',
  },
  surface: {
    marginTop: 15,
    marginRight: 20,
    maxWidth: '100%',
    width: thumbnailWidth,
    elevation: 10,
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.3,
    shadowRadius: 15.0,
    borderRadius: radius,
  },
  image: {
    borderRadius: radius,
    height: thumbnailHeight,
    width: '100%',
    resizeMode: 'cover',
    backgroundColor: lightGrey,
  },
  imageFooter: {
    flexDirection: 'row',
    padding: 10,
    width: '100%',
    maxWidth: thumbnailWidth,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  jetInfo: {
    flexShrink: 1,
    width: '100%',
    marginTop: 5,
  },
  subtitle: {
    color: mediumGrey,
    fontSize: 14,
    fontWeight: '700',
  },
  priceContainer: {
    marginLeft: 10,
    textAlign: 'right',
    marginTop: 8,
  },
  price: {
    color: primary,
    fontSize: 28,
    fontWeight: '700',
    textAlign: 'right',
    lineHeight: 30,
  },
  currency: {
    color: primary,
    fontSize: 16,
    fontWeight: '700',
    textAlign: 'right',
    lineHeight: 16,
    marginRight: 8,
    alignSelf: 'flex-end',
  },
  priceLegend: {
    textAlign: 'right',
    color: primary,
    fontSize: 14,
    lineHeight: 12,
  },
  date: {
    color: primary,
    display: 'flex',
    flexGrow: 1,
    fontSize: 24,
    lineHeight: 24,
    fontWeight: '700',
    textAlign: 'right',
  },
  tag: {
    backgroundColor: secondary,
    borderRadius: 5,
    color: white,
    fontSize: 14,
    fontWeight: '700',
    left: 10,
    paddingHorizontal: 10,
    paddingVertical: 4,
    position: 'absolute',
    top: 10,
    overflow: 'hidden',
  },
  discountTag: {
    backgroundColor: success,
    left: null,
    top: 165,
    right: 10,
  },
  tagPrimary: {
    backgroundColor: primary,
  },
  tagSecondary: {
    backgroundColor: secondary,
  },
  tagRed: {
    backgroundColor: red,
  },
  tagGreen: {
    backgroundColor: success,
  },
  tagGray: {
    backgroundColor: mediumGrey,
  },
  tagGold: {
    backgroundColor: gold,
    color: primary,
  },
});

export default styles;
