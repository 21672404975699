const dateSelected = {
  startingDay: true,
  endingDay: true,
  selected: true,
};

const innerDateSelected = {
  disabled: true,
};

export { dateSelected, innerDateSelected };
