import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { theme } from 'utils';

function SvgComponent(props) {
  const { color = theme.colors.primary } = props;

  return (
    <Svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M0.307666 12.5L3.20867 10.3723C3.20433 10.2867 3.20167 10.2007 3.20167 10.1137C3.20167 8.24567 4.398 6.726 5.869 6.726L12.3473 6.726L12.3473 9.38733L18.75 4.69367L12.3473 -2.79869e-07L12.3473 2.66033L5.869 2.66033C4.301 2.66033 2.82833 3.436 1.71933 4.84367C0.610332 6.252 -3.55068e-07 8.123 -4.42082e-07 10.1137C-4.78101e-07 10.9377 0.104331 11.741 0.307666 12.5Z"
        fill={color}
      />
      <Path
        d="M16.801 9.897C16.801 11.7627 15.6067 13.281 14.1377 13.281L7.645 13.281L7.645 10.6227L1.25 15.3117L7.64533 20L7.64533 17.3417L14.1377 17.3417C15.7037 17.3417 17.175 16.5673 18.2827 15.1613C19.3897 13.7547 20 11.8853 20 9.897C20 9.06933 19.8933 8.26233 19.6893 7.5L16.7937 9.62367C16.7987 9.71433 16.801 9.80533 16.801 9.897Z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
