import React, { useState } from 'react';
import { View } from 'react-native';
import Text from 'components/text/Text';
import { detail as detailKeys } from 'i18n';
import styles from './Reservation.styles';

const {TOTAL,SUBTOTAL,COMMISION,TOTAL_C} = detailKeys;

const DetailValue = ({
    getCommissionTotal,
    getFlightValue,
    flightValue,
    currency,
    isSeatType,
    subTotal,
    discount,
    totalSeatsText,
    isAgencyBolean
}) => {

    const isAgency = ()=> {
        return (
        <View style={styles.reservationInfo}>
            {subTotal ? 
            <Text style={styles.reservationDetailPrice}>
             {SUBTOTAL}: {subTotal} {currency}           
            </Text>
            
            :
             <View></View>}
          
            <Text style={styles.reservationDetailPrice}>
              {COMMISION}: { discount}%
            </Text>
            
            { getCommissionTotal ? <Text style={styles.reservationDetailPrice}>
              {TOTAL_C}: {getCommissionTotal()} {currency}
            </Text> : <View></View>}

            {
              isSeatType ? (
                <Text style={styles.reservationDetailPrice}>
                {TOTAL} ({totalSeatsText}): {getFlightValue()} {flightValue === 0 ? '' :currency} 
                </Text>
              ) : (
                <Text style={styles.reservationDetailPrice}>
                {TOTAL}: {getFlightValue()} {currency}
                </Text>
              )
            }
          </View> 
           )
    }

    const isOtherUser = ()=> {
        return (
        <View style={styles.reservationInfo}>
            <Text style={styles.reservationTotal}>
              {TOTAL}
              {isSeatType && `(${totalSeatsText})`}
            </Text>
            <Text style={styles.reservationPrice}>
              {flightValue === 0 ? '' :currency} {getFlightValue()}
            </Text>
          </View>
          )
    }

  return (
    <View>
        {
            isAgencyBolean 
            ? isAgency()
            : isOtherUser()
        }
    </View>
  )
}

export default DetailValue