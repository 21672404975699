import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../text/Text';

import styles from './Title.styles';

const LocationTitle = ({ type }) => {
  return (
    <Text style={styles.title} ellipsizeMode="tail" numberOfLines={1}>
      {type}
    </Text>
  );
};

LocationTitle.propTypes = {
  type: PropTypes.string.isRequired,
};

export default LocationTitle;
