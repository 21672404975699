import React, { createContext, useState } from 'react';

import Snackbar from 'components/snackbar';

const SnackbarContext = createContext();

const initialSnackbarProps = {
  message: '',
  visible: false,
};

const SnackbarProvider = ({ children }) => {
  const [snackbarProps, setSnackbarProps] = useState(initialSnackbarProps);

  const onDismiss = () => setSnackbarProps(initialSnackbarProps);

  return (
    <SnackbarContext.Provider value={{ setSnackbar: setSnackbarProps }}>
      {children}
      <Snackbar onDismiss={onDismiss} type="error" {...snackbarProps} />
    </SnackbarContext.Provider>
  );
};

export { SnackbarContext, SnackbarProvider };
