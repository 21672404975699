import React from 'react';
import { TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import styles from './Logo.styles';
import { routes } from 'utils';
import Image from 'components/image';

const { HOME } = routes;

const Logo = ({ source }) => {
  const navigation = useNavigation();

  const onPress = () => navigation.push(HOME);

  return (
    <TouchableOpacity onPress={onPress}>
      <Image source={source} style={styles.logo} testID="logo-image" />
    </TouchableOpacity>
  );
};

export default Logo;
