import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
  Polyline,
} from 'react-google-maps';

import { theme } from 'utils';

const Map = withScriptjs(
  withGoogleMap(({ longitudesAndLatitudes }) => {
    const mapRef = useRef(null);

    const fitBounds = () => {
      const bounds = new window.google.maps.LatLngBounds();
      longitudesAndLatitudes.map((item) => bounds.extend(item));
      mapRef.current.fitBounds(bounds);
    };

    const renderMarkers = () => {
      return longitudesAndLatitudes.map((position) => (
        <Marker position={position} />
      ));
    };

    useEffect(() => {
      fitBounds();
    }, [longitudesAndLatitudes]);

    return (
      <GoogleMap
        defaultZoom={8}
        ref={mapRef}
        options={{ draggable: false, disableDefaultUI: true }}
      >
        {renderMarkers()}
        <Polyline
          path={longitudesAndLatitudes}
          geodesic={true}
          options={{
            strokeColor: theme.colors.secondary,
            strokeOpacity: 0.75,
            strokeWeight: 2,
          }}
        />
      </GoogleMap>
    );
  })
);

Map.propTypes = {
  longitudesAndLatitudes: PropTypes.array.isRequired,
};

export default Map;
