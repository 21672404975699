const app = {
  BOOKINGS_TITLE: 'Mis reservas',
  DATE_CONFIG_TITLE: 'Seleccionar Fecha',
  DETAIL_TITLE: 'Detalle',
  PAYMENT_DETAIL_TITLE: 'Detalle de pago',
  PAYMENT_METHODS_TITLE: 'Medio de pago',
  RESULTS_TITLE: 'Resultados',
  SEARCH_CONFIG_TITLE: '¿Adónde vamos?',
  TERMS_CONDITIONS_TITLE: 'Términos y Condiciones',
};

const banner = {
  SEARCH_PLACEHOLDER: '¿A dónde vamos?',
};

const bookTypes = {
  FULL_FLIGHT: 'Avión completo',
  SEATS_FLIGHT: 'Por butaca',
};

const dateConfig = {
  DEPARTURE_TIME: 'Horario de partida',
  VALIDATION_SELECT_DATE: 'Debes seleccionar fecha',
};

const noResults = {
  NO_OFFERS_FROM: 'No tenemos ofertas desde',
  NO_GPS:
    'No pudimos encontrar tu ubicación, intenta nuevamente cambiando la localidad',
  NO_RESULTS:
    'Sin resultados. Intente nuevamente con otra combinación de día u horario.',
  NO_RESULTS_BOOKINGS: 'Aún no tienes vuelos reservados.',
};

const detail = {
  CONSULT_TEXT: (params)=>`Quiero saber mas información sobre el vuelo con origen ${params.origin} a ${params.target}, fecha ${params.flightDate} , en el jet ${params.jetType} gracias. `,
  BOOK: 'Reservar',
  BUY: 'Comprar',
  CONSULT_PRICE:'Consultar',
  BOOKING_FLIGHT: 'Reserva de Avión Completo',
  BOOKING_NUMBER: 'Nº de reserva',
  BOOKING_SUCCESS: '¡Hemos realizado tu reserva!',
  BOOKING_FEEDBACK:
    'En breve nos estaremos comunicando con vos para terminar el proceso de pago.',
  MODAL_BUTTON: 'Aceptar',
  PASSENGERS_QUANTITY: 'Cantidad de pasajeros',
  PRICE: 'Precio',
  TOTAL: 'Total',
  TOTAL_C:'Total C.',
  COMMISION: 'Comisión',
  SUBTOTAL: 'Sub Total',
  SEAT: 'asiento',
  SEATS: 'asientos',
  TITLE: 'Detalle de vuelo',
  PAID: 'Pagado',
  EXPIRED: 'Expirado',
  CANCELLED: 'Cancelado',
  PAY: 'Pagar',
  FLIGHT_VALUE_ZERO:'Precio a Consultar',
};

const errors = {
  DEFAULT: 'Se ha producido un error, intente en unos minutos.',
  EMAIL_NOT_FOUND: 'Email incorrecto',
  EMAIL_FORMAT: 'El formato del email es incorrecto',
  PASSWORD_LENGTH: 'La contraseña debe contener al menos 6 caracteres',
  EMAIL_USED: 'El email ingresado ya se encuentra registrado',
  STRIPE_CANCELLED: 'Pago cancelado',
  SEARCH_FAILURE:
    'No hemos podido realizar la búsqueda, intente en unos minutos.',
};

const flightThumbnail = {
  BY_SEAT: 'por asiento',
  CANCELLED: 'Cancelado',
  CONFIRMED: 'Confirmado',
  EMPTY_LEG: 'Destacado',
  DISCOUNT: (param) => `${param}% OFF`,
  SEAT_LEFT: 'Última butaca',
  SEATS_LEFT: (param) => `Últimas ${param} butacas`,
  WAITING_CONFIRMATION: 'Esperando Confirmación',
  WAITING_PAYMENT: 'Adeuda Pago',
  OPEN: 'Abierto',
  EXPIRED: 'Expirado',
  FLIGHTTHUMBNAIL_VALUE_ZERO:'Consultar',
};

const flightTypes = {
  ONE_WAY: `Solo ida`,
  ROUND_TRIP: `Ida y vuelta`,
  MULTI_LEG: 'Multi destino',
};

const flightTypesLineBreak = {
  ONE_WAY: `Solo \nIda`,
  ROUND_TRIP: `Ida y \nVuelta`,
  MULTI_LEG: 'Multi \nDestino',
};

const locationsContainer = {
  ADD_DESTINATION: 'Agregar destino',
};

const login = {
  EMAIL_FIELD: 'E-mail',
  PASSWORD_FIELD: 'Contraseña',
  SUBMIT_BUTTON: 'Ingresar',
  REGISTER: 'Registrarse',
  FORGOT_PASSWORD: 'Olvidé mi contraseña',
  INVALID_LOGIN: 'Usuario y/o contraseña inválidos.',
  VERIFY_ACCOUNT: 'Revisa tus mails para verificar la cuenta.',
};

const navbar = {
  LOGIN: 'Iniciar sesión',
  LOGOUT: 'Cerrar sesión',
  MEMBERSHIP_TITLE: 'Membership',
  MY_BOOKINGS: 'Mis reservas',
  TERMS_CONDITIONS_TITLE: 'Términos y Condiciones',
  ABOUT_US: 'Sobre Nosotros'

};

const paymentDetail = {
  TITLE: 'Datos de pago',
  UPLOAD_FILE: 'Subir comprobante',
  UPLOAD_LATER: 'Puedes subir luego el comprobante entrando desde Mis Reservas',
};

const paymentMethods = {
  CALL: 'Contactarse por Teléfono',
  STRIPE: 'Tarjeta de Crédito',
  DECIDIR: 'Tarjeta de Crédito Nacional',
  TITLE: 'Elige un medio de Pago',
  PROMO_TITLE: 'Estás a un paso de volar',
  BOOK_CODE: 'Código de Reserva',
  SUCCESSFUL_PAYMENT: 'Pago realizado correctamente',
};

const recoverPassword = {
  RECOVER_TITLE: 'Recuperar contraseña',
  EMAIL_FIELD: 'E-mail',
  SUBMIT_BUTTON: 'Enviar',
  CHECK_EMAIL: 'Revisa tus mails para cambiar la contraseña.',
  INVALID_RECOVERY: 'Ha ocurrido un error, intenta nuevamente.',
  MISSING_FIELD: 'Ingresa el e-mail asociado a tu cuenta.',
  CANCEL_BUTTON: 'Cancelar',
};

const resultsContainer = {
  LOADING: 'Cargando...',
};

const search = {
  SEARCH_LOCATION: 'Buscar Localidad',
  SEARCH_AIRPORT: 'Buscar Aeropuerto',
  SEARCH_DESTINATION: 'Buscar Destino',
  NO_RESULTS: 'Sin resultados',
};

const searchConfig = {
  CURRENT_LOCATION: 'Origen',
  PASSENGERS_QUANTITY: 'Cantidad de pasajeros',
  SET_FROM_LOCATION: 'Primero debe configurar un origen de vuelo',
  SET_TO_LOCATION: 'Configura al menos 1 destino de vuelo',
  JUST: 'Solo',
};

const searchInfo = {
  PASSENGER: 'pasajero',
  PASSENGERS: 'pasajeros',
  MODAL_TITLE: 'Selecciona el tipo de avión',
  MODAL_BUTTON: 'Aplicar',
};

const signup = {
  ENTER_ACCOUNT: 'Ingresa a tu cuenta',
  MISSING_FIELD: 'Completa este campo.',
  INVALID_REGISTRATION: 'Ha ocurrido un error, intenta nuevamente.',
  REGISTER: 'Registrarme',
  SUCCESSFUL_REGISTRATION: 'Revisa tus mails para verificar la cuenta.',
  EMAIL_LABEL: 'E-mail',
  PASSWORD_LABEL: 'Contraseña',
  FIRSTNAME_LABEL: 'Nombre',
  LASTNAME_LABEL: 'Apellido',
  DNI_LABEL: 'DNI',
  PHONE_LABEL: 'Teléfono',
  CANCEL: 'Cancelar',
};

const spinner = {
  LOADING: 'Cargando...',
};

const stripeForm = {
  PAY: 'Pagar',
  SUCCESSFUL_PAYMENT: '¡Pago exitoso!',
  TITLE: 'Carga tus datos',
  CARD_NUMBER: 'Número de Tarjeta',
  EXPIRATION_NUMBER: 'Fecha de Vencimiento',
  CVC: 'CVC',
  COUNTRY:'Ciudad',
};

const carouselSlide = {
  SELECTED_FLIGHTS: 'En vuelos seleccionados',
};



export {
  app,
  banner,
  bookTypes,
  dateConfig,
  detail,
  errors,
  flightThumbnail,
  flightTypes,
  flightTypesLineBreak,
  locationsContainer,
  login,
  navbar,
  paymentDetail,
  recoverPassword,
  resultsContainer,
  searchConfig,
  searchInfo,
  signup,
  paymentMethods,
  search,
  spinner,
  stripeForm,
  noResults,
  carouselSlide,
};
