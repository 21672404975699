import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { IconButton } from 'react-native-paper';

import Text from 'components/text/Text';
import { constants, methods, routes, theme } from 'utils';
import styles from '../PaymentButton.styles';
import useAuth from '../../../oauth';

const { PAYMENT_METHOD_ABROAD, PAYMENT_METHOD_LOCAL } = constants.METRICS;
const { triggerMetric } = methods;
const { PAYMENT_DETAIL } = routes;

const ICONS = {
  abroad: 'bank',
  local: 'bank',
};

const TransferButton = (props) => {
  const navigation = useNavigation();
  const { user } = useAuth();

  const onPressHandler = (data) => {
    triggerMetric({
      event:
        props.id === 'abroad' ? PAYMENT_METHOD_ABROAD : PAYMENT_METHOD_LOCAL,
      userId: user,
    });
    return navigation.navigate(PAYMENT_DETAIL, data);
  };

  return (
    <TouchableOpacity onPress={() => onPressHandler(props)}>
      <View style={styles.paymentMethod}>
        <IconButton
          icon={ICONS[props.id]}
          size={26}
          color={theme.colors.success}
        />
        <Text style={styles.paymentLabel}>{props.label}</Text>
      </View>
    </TouchableOpacity>
  );
};

export default TransferButton;
