import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

const isIOS = Platform.OS === 'ios';
const isAndroid = Platform.OS === 'android';
const isNative = isIOS || isAndroid;
const isWeb = Platform.OS === 'web';

const storageSetItem = (key, data) => {
  if (isNative) {
    AsyncStorage.setItem(`@CruxJets:${key}`, JSON.stringify(data));
  } else {
    window?.localStorage.setItem(`@CruxJets:${key}`, JSON.stringify(data));
  }
};

const asyncStorageGetItem = async (key) => {
  const data = await AsyncStorage.getItem(`@CruxJets:${key}`);
  return JSON.parse(data);
};

const localStorageGetItem = async (key) => {
  return JSON.parse(window?.localStorage.getItem(`@CruxJets:${key}`));
};

export default {
  isIOS,
  isAndroid,
  isNative,
  isWeb,
  storage: {
    setItem: storageSetItem,
    getItem: isNative ? asyncStorageGetItem : localStorageGetItem,
  },
};
