import React from 'react';
import PropTypes from 'prop-types';
import { Image, TouchableOpacity, View } from 'react-native';

import styles from './SearchListItem.styles';
import { constants, urls } from 'utils';
import Text from 'components/text/Text';

const { getFlagUri } = urls;

const SearchListItem = ({ code, title, subtitle, onPress }) => (
  <TouchableOpacity onPress={onPress}>
    <View style={styles.item}>
      <Image
        source={{
          uri: getFlagUri(code),
        }}
        style={styles.flag}
      />
      <View style={styles.countryName}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.subtitle}>{subtitle}</Text>
      </View>
    </View>
  </TouchableOpacity>
);

SearchListItem.propTypes = {
  airports: PropTypes.array.isRequired,
  city: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
};

export default SearchListItem;
