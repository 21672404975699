import { Linking ,Platform} from 'react-native';

import constants from './constants';
import device from './device';

const { PHONE_NUMBER } = constants;
const { isIOS } = device;

const capitalize = (word) =>
  `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`;

const isAnEmptyObject = (obj) =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

const makeCall = () => {
  let phoneNumber = '';

  if (isIOS) {
    phoneNumber = `telprompt:${PHONE_NUMBER}`;
  } else {
    phoneNumber = `tel:${PHONE_NUMBER}`;
  }

  Linking.openURL(phoneNumber);
};

const whatsappMessage = (text) => {
  
  const base = 'https://api.whatsapp.com/send';
  const phone = PHONE_NUMBER
  //paramns
  const paramsPhone = `?phone=${phone}` 
  const ParamsText = `&text=${text}`
  const url = base+paramsPhone

  if(text){
    customLinking(url+ParamsText)
  }else{
    customLinking(url)
  }
};

const customLinking=(url)=>{

  if (Platform.OS === 'web') {
    window.open(url, '_blank')
  } else {
    Linking.openURL(url)
  }
}


const triggerMetric = (data) => window?.dataLayer?.push(data);

export default {
  capitalize,
  isAnEmptyObject,
  makeCall,
  triggerMetric,
  whatsappMessage,
};
