import { StyleSheet } from 'react-native';

import { screen, theme } from 'utils';

const { isMobile } = screen;
const { secondary, white } = theme.colors;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(46, 52, 64, .9)',
    borderRadius: 10,
    padding: 20,
    paddingTop: 10,
    marginBottom: isMobile ? 0 : 100,
  },
  input: {
    backgroundColor: white,
    marginTop: 20,
  },
  forgotPasswordContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  forgotPasswordTouchable: {
    width: 'auto',
  },
  forgotPassword: {
    color: white,
  },
  buttonLabel: {
    padding: 8,
    color: white,
  },
  button: {
    marginTop: 20,
    backgroundColor: secondary,
    opacity: 1,
  },
  submitButtonText: {
    fontWeight: '700',
    textTransform: 'capitalize',
    fontSize: 24,
    letterSpacing: 0,
  },
  registerButtonLabel: {
    color: white,
    padding: 8,
  },
  registerButton: {
    marginTop: 20,
    opacity: 1,
    borderWidth: 1,
    borderColor: white,
    backgroundColor: 'transparent',
  },
  registerButtonText: {
    fontWeight: '700',
    textTransform: 'capitalize',
    fontSize: 20,
    letterSpacing: 0,
  },
});

export default styles;
