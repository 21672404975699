import React from 'react';
import PropTypes from 'prop-types';
import { ScrollView, View } from 'react-native';

import styles from './BottomButton.styles';
import { theme } from 'utils';
import Button from '../button';
import Text from 'components/text/Text';

const BottomButton = ({ children, buttonText, onPress }) => (
  <View style={styles.container}>
    <ScrollView style={styles.scrollableContainer}>{children}</ScrollView>

    <View style={[styles.buttomContainer]}>
      <View style={[styles.buttonActionContainer, theme.shadow]}>
        <Button buttonStyles={styles.button} onPress={onPress}>
          <Text style={styles.text}>{buttonText}</Text>
        </Button>
      </View>
    </View>
  </View>
);

BottomButton.propTypes = {
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
};

export default BottomButton;
