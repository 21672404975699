import React from 'react';
import { Textfit } from 'react-textfit';
import { ImageBackground, View } from 'react-native';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import styles from './BannerCarousel.styles';
import { carouselSlide as keys } from 'i18n';
import { device, screen, theme } from 'utils';

const { SELECTED_FLIGHTS } = keys;
const pointer = device.isWeb ? { cursor: 'pointer' } : {};

// Estos estilos los declaramos acá porque porque el componente que los utiliza no es de RN.
const defaultTextStyles = {
  fontFamily: 'OpenSans_400Regular',
  fontWeight: '800',
  textTransform: 'capitalize',
};
const textStyles = {
  color: theme.colors.white,
  flexGrow: 1,
  fontSize: 36,
  width: '100%',
};
const dateStyles = {
  color: theme.colors.white,
  fontSize: 16,
};
const discountStyles = {
  color: theme.colors.primary,
  fontSize: screen.isMobile ? 18 : 20,
};

const CarouselSlide = ({
  fromCity,
  toCity,
  fromDay,
  fromMonth,
  toDay,
  toMonth,
  day,
  month,
  discount,
  url,
}) => {
  return (
    <View style={[styles.slideContainer, pointer]}>
      <ImageBackground
        source={{
          uri: url,
        }}
        resizeMode="cover"
        imageStyle={{ backgroundColor: theme.colors.secondary }}
        style={styles.image}
        borderRadius={20}
      >
        <View style={styles.overlay} />

        <View
          style={{
            flexGrow: 1,
            maxHeight: '40%',
          }}
        >
          <Textfit
            mode="multi"
            max={36}
            style={{
              ...defaultTextStyles,
              ...textStyles,
            }}
            forceSingleModeWidth
          >
            <span>{fromCity?.toLowerCase()}</span>
            <View style={styles.divider}></View>
            <span>{toCity?.toLowerCase()}</span>
          </Textfit>
        </View>
       
          <View style={styles.offerColumns}>
            <View style={styles.dateContainer}>
              <Textfit
                mode="single"
                max={16}
                min={10}
                style={{ ...defaultTextStyles, ...dateStyles }}
              >
                
                {!!day && !!month && (
                  <> 
                    <span style={{ display: "block", textTransform: "none" }}>
                      IDA: {day} de {month}
                    </span>
                    {/* <span style={{ display: "block" }}>
                      VUELTA: Domingo 17 de Abril
                    </span> */}
                  </>
                )}
              
                {!!fromDay && !!fromMonth && (
                  <span style={{ display: 'block' }}>
                    Del {fromDay} de {fromMonth}
                  </span>
                )}
                {!!toDay && !!toMonth && (
                  <span style={{ display: 'block' }}>
                    al {toDay} de {toMonth}
                  </span>
                )}
              </Textfit>
              <Textfit
                mode="multi"
                max={16}
                min={10}
                style={{ ...defaultTextStyles, ...dateStyles }}
              >
                <span style={{ display: 'block' }}>{SELECTED_FLIGHTS}</span>
              </Textfit>
            </View>
            {discount  && discount!=0 && (
            <View style={styles.discountContainer}>
              <Textfit
                mode="single"
                max={20}
                style={{ ...defaultTextStyles, ...discountStyles }}
              >
                {discount}% OFF
              </Textfit>
            </View>)}
          </View>
        
      </ImageBackground>
    </View>
  );
};

export default CarouselSlide;
