import { StyleSheet } from 'react-native';

import { theme } from 'utils';

const { secondary, white } = theme.colors;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(46, 52, 64, .9)',
    borderRadius: 10,
    padding: 20,
    paddingTop: 10,
  },
  input: {
    backgroundColor: white,
    marginBottom: 10,
    marginTop: 10,
    height: 50,
  },
  button: {
    marginTop: 20,
    backgroundColor: secondary,
    opacity: 1,
  },
  buttonLabel: {
    padding: 8,
    color: white,
  },
  buttonText: {
    fontSize: 24,
    fontWeight: '700',
    letterSpacing: 0,
    textTransform: 'capitalize',
    color: white,
  },
  cancelButton: {
    marginTop: 20,
    opacity: 1,
    borderWidth: 1,
    borderColor: white,
    backgroundColor: 'transparent',
  },
  cancelButtonText: {
    fontWeight: '700',
    textTransform: 'capitalize',
    fontSize: 20,
    letterSpacing: 0,
  },
});

export default styles;
