import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props) {
  const {
    color = theme.colors.primary,
    height = 20,
    width = 23,
    style,
  } = props;

  return (
    <Svg
      width={`${width}`}
      height={`${height}`}
      viewBox={`0 0 ${23} ${20}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <Path
        d="M4.44961 19.8C5.36261 19.8 6.09961 19.063 6.09961 18.15V16.5H17.0996V18.15C17.0996 19.063 17.8366 19.8 18.7496 19.8C19.6626 19.8 20.3996 19.063 20.3996 18.15V15.4C20.3996 14.19 19.4096 13.2 18.1996 13.2H4.99961C3.78961 13.2 2.79961 14.19 2.79961 15.4V18.15C2.79961 19.063 3.53661 19.8 4.44961 19.8ZM20.3996 7.7H21.4996C22.1046 7.7 22.5996 8.195 22.5996 8.8V9.9C22.5996 10.505 22.1046 11 21.4996 11H20.3996C19.7946 11 19.2996 10.505 19.2996 9.9V8.8C19.2996 8.195 19.7946 7.7 20.3996 7.7ZM1.69961 7.7H2.79961C3.40461 7.7 3.89961 8.195 3.89961 8.8V9.9C3.89961 10.505 3.40461 11 2.79961 11H1.69961C1.09461 11 0.599609 10.505 0.599609 9.9V8.8C0.599609 8.195 1.09461 7.7 1.69961 7.7ZM17.0996 11H6.09961V2.2C6.09961 0.99 7.08961 0 8.29961 0H14.8996C16.1096 0 17.0996 0.99 17.0996 2.2V11Z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
