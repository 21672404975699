import { errors } from 'i18n';

const { DEFAULT } = errors;

const FLAG_SIZE_HEIGHT = 26;
const FLAG_SIZE_WIDTH = 40;

const FLIGHT_TYPES = {
  ONE_WAY: 'oneWay',
  ROUND_TRIP: 'roundTrip',
  MULTI_LEG: 'multiLeg',
};

const METRICS = {
  BANNER_SELECTION: 'seleccion_banner',
  BOOK_BUTTON: 'boton_reservar',
  BUY_BUTTON: 'boton_comprar',
  GO_TO_REGISTER: 'registrarse',
  LOG_IN_BUTTON: 'ingresar',
  REGISTER: 'registrarme',
  RESERVATION_SUCCESSFUL: 'reserva_confirmada',
  SEARCH_BUTTON: 'buscador',
  PAYMENT_METHOD_ABROAD: 'trasferencia_Internacional',
  PAYMENT_METHOD_LOCAL: 'transferencia_Local',
  PAYMENT_METHOD_CREDIT_CARD: 'tarjeta_de_credito',
  PAYMENT_METHOD_CALL: 'contactarse_por_telefono',
  PAID_WITH_CREDIT_CARD: 'pago_con_tarjeta',
  OFFER_SELECTION: 'card_ultimos_vuelos',
};

const PHONE_NUMBER = '5491160497298';

const ERRORS = {
  DEFAULT,
};

export default {
  FLAG_SIZE_HEIGHT,
  FLAG_SIZE_WIDTH,
  FLIGHT_TYPES,
  METRICS,
  PHONE_NUMBER,
  ERRORS,
};
