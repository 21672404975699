import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  reservationType: {
    fontSize: 18,
    fontStyle: 'italic',
    marginTop: 10,
    textAlign: 'center',
  },
});

export default styles;
