import { StyleSheet } from 'react-native';

import { screen, theme } from 'utils';

const { isMobile } = screen;
const { primary } = theme.colors;

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  surface: {
    marginBottom: 20,
  },
  surfaceBackground: {
    backgroundColor: primary,
    height: 60,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  surfaceContent: {
    borderRadius: 10,
    display: 'flex',
    paddingBottom: 10,
    paddingHorizontal: 24,
  },
  textImageContainer: {
    alignSelf: isMobile ? 'auto' : 'center',
    flexDirection: isMobile ? 'column' : 'row-reverse',
    maxWidth: 800,
  },
  aircraft: {
    alignSelf: 'center',
    height: 151,
    marginBottom: -60,
    width: 345,
    zIndex: 9,
  },
  searchContainer: {
    alignItems: 'center',
    alignSelf: isMobile ? 'auto' : 'center',
    maxWidth: 800,
    width: '100%',
    display: 'flex',
    padding: 24,
  },
  searchInput: {
    width: '100%',
    fontWeight: '700',
  },
  swiperContainer: {
    marginHorizontal: 'auto',
    position: 'relative',
    width: '100%',
    backgroundColor: primary,
  },
});

export default styles;
