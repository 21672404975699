import { StyleSheet } from 'react-native';

import { screen, theme } from 'utils';

const { isMobile } = screen;
const { primary, white } = theme.colors;

const styles = StyleSheet.create({
  text: {
    color: white,
    fontSize: 36,
    fontWeight: '800',
    marginTop: isMobile ? 0 : 0,
    lineHeight: isMobile ? 36 : 42,
    width: '100%',
    textTransform: 'capitalize',
  },
  divider: {
    width: '100%',
    height: 2,
    marginVertical: 5,
    backgroundColor: white,
  },
  slideContainer: {
    borderRadius: 20,
    height: isMobile ? 400 : 480,
    marginHorizontal: 0,
    paddingBottom: 40,
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 10,
    overflow: 'hidden',
    justifyContent: 'space-between',
    paddingTop: 40,
    paddingBottom: isMobile ? 20 : 40,
    paddingHorizontal: isMobile ? 20 : 40,
  },
  discountContainer: {
    backgroundColor: theme.colors.success,
    padding: 10,
    borderRadius: 5,
    alignSelf: 'flex-end',
    marginTop: 10,
    marginLeft: 'auto',
    textAlign: 'center',
    width: 'auto',
  },
  discount: {
    color: primary,
    fontSize: isMobile ? 18 : 20,
    fontWeight: '800',
  },
  dateContainer: {
    marginRight: 10,
    flexGrow: 1,
    flexBasis: '55%',
  },
  date: {
    fontSize: 16,
    fontWeight: '700',
    color: white,
    textTransform: 'capitalize',
  },
  offerColumns: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: theme.colors.primary,
    opacity: 0.6,
  },
});

export default styles;
