import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import TimePicker from './components/TimePicker';

import styles from './DateConfig.styles';
import {
  buildSegments,
  isLastSelection,
  setHoursAndMinutes,
} from './DateConfig.utils';
import { flightTypeValues, bookTypesValues } from './data';
import { dateConfig as dateConfigKeys } from 'i18n';
import { constants, date, routes } from 'utils';
import Alert from 'components/Alert';
import Calendar from 'components/calendar';
import { Clock } from 'components/icons';
import FakeTextInput from 'components/fake-text-input';
import LegInfo from './components/LegInfo';
import LazyHOC from 'components/lazy-hoc/LazyHoc';

const { ONE_WAY } = constants.FLIGHT_TYPES;
const { DATE_CONFIG, RESULTS } = routes;
const { VALIDATION_SELECT_DATE } = dateConfigKeys;
const { getHour, getMinutes } = date;

const DEFAULT_HOUR = 9;
const DEFAULT_MERIDIEM = 'am';
const DEFAULT_DATE = new Date();
DEFAULT_DATE.setHours(DEFAULT_HOUR, 0, 0);

const DateConfig = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const {
    bookTypeId,
    flightType,
    passengers,
    fromLocation,
    toLocations,
    timestamps = [],
    markedDates: markedDatesParams,
  } = route.params;
  const [date, setDate] = useState(DEFAULT_DATE);
  const [departureTime, setDepartureTime] = useState(
    `${DEFAULT_HOUR}:00${DEFAULT_MERIDIEM}`
  );
  const [markedDates, setMarkedDates] = useState(markedDatesParams);
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);

  const hideDatePicker = () => {
    setDatePickerVisibility(false);
  };

  const getFlightAirports = () => {
    const tripAirports = [];
    tripAirports.push(fromLocation.airports[0]);
    tripAirports.push(...toLocations.map((location) => location.airports[0]));
    return tripAirports;
  };

  const navigateToResults = (timestamps) => {
    const segments = buildSegments({
      flightType,
      fromLocation,
      toLocations,
      timestamps,
    });
    const flightAirports = getFlightAirports();
    navigation.push(RESULTS, {
      bookType: bookTypesValues.find((bt) => bt.id === bookTypeId),
      flightType: flightTypeValues.find((ft) => ft.id === flightType),
      passengers,
      segments,
      toLocations,
      flightAirports,
    });
  };

  const navigateToDateConfig = (markedDates, timestamps) => {
    navigation.push(DATE_CONFIG, {
      bookTypeId,
      flightType,
      passengers,
      fromLocation,
      toLocations,
      timestamps,
      markedDates,
    });
  };

  const onChange = (selectedDate) => {
    hideDatePicker();
    setDate(selectedDate);
  };

  const onPressHandler = () => {
    setDatePickerVisibility(true);
  };

  const onDaySelected = (newMarkedDates, selectedTimestamp) => {
    const oldMarkedDates = markedDates;
    setMarkedDates(newMarkedDates);
    setTimeout(function () {
      let currentTimestamp = selectedTimestamp;
      if (selectedTimestamp && date) {
        currentTimestamp = setHoursAndMinutes(selectedTimestamp, date);
      }
      const nextTimestamps = currentTimestamp
        ? [...timestamps, currentTimestamp]
        : timestamps;

      if (
        flightType === ONE_WAY ||
        isLastSelection(nextTimestamps, flightType, toLocations)
      ) {
        navigateToResults(nextTimestamps);
      } else {
        navigateToDateConfig(newMarkedDates, nextTimestamps);
      }
      setMarkedDates(oldMarkedDates);
    }, 300);
  };

  useEffect(() => {
    if (date) {
      const hours = getHour(date);
      const minutes = getMinutes(date);

      setDepartureTime(`${hours}:${minutes}`);
    }
  }, [date]);

  return (
    // <LazyHOC>
    <View>
      <View style={styles.time}>
        <LegInfo
          timestamps={timestamps}
          fromLocation={fromLocation}
          toLocations={toLocations}
          flightType={flightType}
        />
        <FakeTextInput
          Icon={Clock}
          onPress={onPressHandler}
          text={departureTime}
        />

        <TimePicker
          isVisible={isDatePickerVisible}
          setDate={setDate}
          hideModal={hideDatePicker}
          onChange={onChange}
          hideDatePicker={hideDatePicker}
          testID="dateTimePicker"
          value={date}
          mode="time"
          is24Hour={true}
          DEFAULT_HOUR={DEFAULT_HOUR}
          DEFAULT_MERIDIEM={DEFAULT_MERIDIEM}
        />
      </View>

      <Calendar
        flightType={flightType}
        timestamps={timestamps}
        toLocations={toLocations}
        onDaySelected={onDaySelected}
        markedDates={markedDates}
      />
    </View>
    // </LazyHOC>
  );
};

export default DateConfig;
