import { StyleSheet } from 'react-native';
import { theme } from 'utils';

const styles = StyleSheet.create({
  noResultsContainer: {
    justifyContent: 'center',
    width: '100%',
  },
  noResultsText: {
    fontSize: 20,
    textAlign: 'center',
    color: theme.colors.primary,
  },
  backgroundImage: {
    height: 200,
    opacity: 1,
    width: 250,
    marginHorizontal: 'auto',
    alignSelf: 'center',
  },
});

export default styles;
