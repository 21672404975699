import { StyleSheet } from 'react-native';

import { theme } from 'utils';

const styles = StyleSheet.create({
  location: {
    color: theme.colors.primary,
    fontWeight: '600',
    fontSize: 18,
  },
  planeIcon: {
    height: 17,
    marginLeft: 8,
    width: 22,
    marginBottom: -2,
  },
});

export default styles;
