import { StyleSheet } from 'react-native';

import { theme } from 'utils';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    maxWidth: 500,
    marginBottom: 20,
    paddingHorizontal: 10,
    width: '100%',
  },
  iconButton: {
    backgroundColor: theme.colors.lightGrey,
    marginLeft: 10,
  },
  input: {
    fontSize: 16,
  },
});

export default styles;
