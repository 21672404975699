import React from 'react';
import PropTypes from 'prop-types';
import { Image as ImageRN, View } from 'react-native';

import styles from './JetInfo.styles';
import { theme } from 'utils';
import BookingInfo from './BookingInfo';
import Swiper from 'components/swiper';
import Text from 'components/text/Text';

const JetInfo = ({ category, maxPax, photos, type, userBook }) => {
  const renderSlides = () => {
    return photos.map((photo) => {
      return (
        <View key={photo} style={[styles.slideContainer, theme.shadow]}>
          <ImageRN source={{ uri: photo }} style={styles.image} />
        </View>
      );
    });
  };

  return (
    <>
      <View style={styles.swiperContainer}>
        <Swiper>{renderSlides()}</Swiper>
      </View>
      <View style={styles.info}>
        <Text style={styles.jetModel}>{type}</Text>
        <Text style={styles.jetDescription}>
          {category}, {maxPax} seats
        </Text>
        {userBook && <BookingInfo {...userBook} />}
      </View>
    </>
  );
};

JetInfo.propTypes = {
  category: PropTypes.string.isRequired,
  maxPax: PropTypes.number.isRequired,
  photos: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
  // userBook
};

export default JetInfo;
