import { StyleSheet } from 'react-native';

import { constants } from 'utils';

const { FLAG_SIZE_HEIGHT, FLAG_SIZE_WIDTH } = constants;

const styles = StyleSheet.create({
  item: {
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: '#ccc', // TODO: pasar al theme.colors
    flexDirection: 'row',
    padding: 24,
  },
  flag: {
    height: FLAG_SIZE_HEIGHT,
    marginRight: 16,
    width: FLAG_SIZE_WIDTH,
  },
  countryName: {
    flexShrink: 1,
  },
  title: {
    fontSize: 16,
    fontWeight: '700',
  },
  subtitle: {
    textTransform: 'capitalize',
  },
});

export default styles;
