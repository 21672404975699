import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useRoute } from '@react-navigation/native';

import { getFlightDetails } from 'api';
import Detail from './Detail';
import { routes } from 'utils';

const LazyDetail = (props) => {
  const route = useRoute();
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(route.params || {});
  const { id, price } = data;
  const loadData = async () => {
    const res = await getFlightDetails(id);
    setData(res.data);
    setLoading(false);
  };
  // las validaciones sobre price se hacen para saber si es un caso donde todos los valores vienen por params,
  // o si solo viene el id
  useEffect(() => {
    if (id && !price) {
      loadData();
    } else {
      if (!price) {
        setTimeout(() => navigation.navigate(routes.HOME), 100);
      } else {
        setLoading(false);
      }
    }
  }, []);
  if (loading) return <View></View>;
  return <Detail {...props} {...data} />;
};

export default LazyDetail;
