import React from 'react';
import Text from '../../../components/text/Text';
import { View } from 'react-native';

import styles from './LegInfo.styles';
import { constants } from 'utils';

const { ROUND_TRIP, MULTI_LEG } = constants.FLIGHT_TYPES;

const LegInfo = ({ timestamps, fromLocation, toLocations, flightType }) => {
  const currentIndex = timestamps.length;
  const getLeg = () => {
    if (currentIndex === 0) {
      return `${fromLocation.city} > ${toLocations[currentIndex].city}`;
    }

    if (currentIndex < toLocations.length) {
      return `${toLocations[currentIndex - 1].city} > ${
        toLocations[currentIndex].city
      }`;
    }

    if (flightType === ROUND_TRIP) {
      return `${toLocations[0].city} > ${fromLocation.city}`;
    }
  };

  const getTripsQuantity = () => {
    if (flightType === MULTI_LEG) {
      return `(${currentIndex + 1}/${toLocations.length})`;
    }
    return '';
  };

  return (
    <View style={styles.container}>
      <Text style={styles.text}>{`${getLeg()} ${getTripsQuantity()}`}</Text>
    </View>
  );
};

export default LegInfo;
