import { StyleSheet } from 'react-native';

import { screen, theme } from 'utils';

const { isBigScreen } = screen;
const { primary, white } = theme.colors;

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexShrink: 1,
    height: 100,
    backgroundColor: white,
  },
  swiperBackground: {
    backgroundColor: primary,
    height: 180,
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  swiperBackgroundWhite: {
    backgroundColor: '#fff',
    height: 180,
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  reservationContainer: {
    flexDirection: 'column',
    padding: 20,
    maxWidth: isBigScreen ? 'auto' : 500,
    width: '100%',
    marginHorizontal: 'auto',
    alignItems: isBigScreen ? 'flex-end' : 'stretch',
  },
  titleContainer: {
    marginLeft: 32,
    marginTop: 110,
  },
  title: {
    color: white,
    fontSize: 24,
  },
  columnsContainer: isBigScreen
    ? {
        maxWidth: 1200,
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignSelf: 'center',
      }
    : {},
  column: isBigScreen
    ? {
        width: '50%',
      }
    : {},
  mapContainer: {
    borderRadius: 20,
    margin: 20,
    overflow: 'hidden',
    width: 512,
  },
});

export default styles;
