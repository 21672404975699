import React from 'react';
import { View, KeyboardAvoidingView, Platform, ScrollView } from 'react-native';

import styles from './SignUp.styles';
import { signup as signupKeys } from 'i18n';
import AuthTemplate from '../auth-template';
import Button from '../button';
import Text from '../text/Text';
import TextInput from '../text-input';

const {
  EMAIL_LABEL,
  PASSWORD_LABEL,
  FIRSTNAME_LABEL,
  LASTNAME_LABEL,
  DNI_LABEL,
  PHONE_LABEL,
  REGISTER,
  CANCEL,
} = signupKeys;

const SignUp = (props) => {
  const {
    email,
    setEmail,
    password,
    setPassword,
    name,
    setName,
    surname,
    setSurname,
    dni,
    setDni,
    phone,
    setPhone,
    signUp,
    emailError,
    passwordError,
    nameError,
    surnameError,
    dniError,
    phoneError,
    isLoading,
    onCancel,
  } = props;

  return (
    <AuthTemplate>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        enabled
      >
        <ScrollView>
          <View style={styles.container}>
            <TextInput
              itemProps={{ floatingLabel: true }}
              label={EMAIL_LABEL}
              onChangeText={(text) => setEmail(text)}
              value={email}
              errorMessage={emailError}
              inputStyles={styles.input}
              type="outlined"
              keyboardType="email-address"
            />

            <TextInput
              label={PASSWORD_LABEL}
              secureTextEntry={true}
              onChangeText={(value) => setPassword(value)}
              value={password}
              errorMessage={passwordError}
              inputStyles={styles.input}
            />

            <TextInput
              label={FIRSTNAME_LABEL}
              onChangeText={(value) => setName(value)}
              value={name}
              errorMessage={nameError}
              inputStyles={styles.input}
            />

            <TextInput
              label={LASTNAME_LABEL}
              onChangeText={(value) => setSurname(value)}
              value={surname}
              errorMessage={surnameError}
              inputStyles={styles.input}
            />

            <TextInput
              label={DNI_LABEL}
              onChangeText={(value) => setDni(value)}
              value={dni}
              errorMessage={dniError}
              inputStyles={styles.input}
              keyboardType="number-pad"
            />

            <TextInput
              itemProps={{ floatingLabel: true }}
              label={PHONE_LABEL}
              onChangeText={(value) => setPhone(value)}
              value={phone}
              errorMessage={phoneError}
              inputStyles={styles.input}
              keyboardType="phone-pad"
            />

            <Button
              onPress={signUp}
              labelStyle={styles.buttonLabel}
              buttonStyles={styles.button}
              loading={isLoading}
            >
              <Text style={styles.buttonText}>{REGISTER}</Text>
            </Button>
            <Button
              onPress={onCancel}
              buttonStyles={styles.cancelButton}
              labelStyle={styles.buttonLabel}
              mode="outlined"
              color="#000"
            >
              <Text style={styles.cancelButtonText}>{CANCEL}</Text>
            </Button>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </AuthTemplate>
  );
};

export default SignUp;
