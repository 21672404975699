import React, { useEffect } from 'react';
import { ScrollView, View } from 'react-native';
import Text from 'components/text/Text';
import { useRoute } from '@react-navigation/native';
import Image from 'components/image';
import background from 'assets/congrats.png';
import styles from './PaymentMethods.styles';
import PaymentButton from 'components/payment-button';
import { paymentMethods as keys } from 'i18n';
import { constants, methods, theme } from 'utils';
import useAuth from '../../oauth';

const { RESERVATION_SUCCESSFUL } = constants.METRICS;
const { triggerMetric } = methods;

const PaymentMethods = () => {
  const route = useRoute();
  const { bookData } = route.params || {};
  const { user  } = useAuth();

  const paymentMethods = [
    {
      id: 'abroad',
      label: 'Transferencia Internacional',
      data: {
        swift: 'WFBIUS6S',
        account_num: '3850164934',
        bank: 'WELLS FARGO',
        holder: 'CRUX JETS LLC',
      },
    },
    {
      id: 'local',
      label: 'Transferencia Local',
      data: {
        cbu: '0150861902000000765163',
        cuit: '30-71707245-2',
        bank: 'ICBC',
        holder: 'CRUXJETS',
        type_account: 'Cta. Cte. en PESOS',
      },
    },
    {
      id: 'stripe',
      label: 'Transferencia Internacional',
      data: {
        cbu: '1234567890',
      }
    },
    {
        id: 'decidir',
        label: 'Transferencia Nacional',
        data: {
          cbu: '',
        },
    },
    {
      id: 'clickToCall',
      label: 'Contactarse por Teléfono',
      data: {
        phone: '1167572504',
      },
    },
  ];

  const triggerSuccessfulReservationMetric = () => {
    const { bookData } = route?.params;

    return triggerMetric({
      event: RESERVATION_SUCCESSFUL,
      numero_de_reserva: bookData?.userBookCode,
      valor: bookData?.flightValue,
      userId: user,
    });
  };

  useEffect(() => {
    if (user) {
      triggerSuccessfulReservationMetric();
    }
  }, [user]);

  return (
    <ScrollView style={styles.container}>
      <View style={styles.congrats}>
        <Image source={background} style={styles.backgroundImage} />
        <Text style={styles.congratsTitle}>Reserva confirmada</Text>
        <Text style={styles.title}>{keys.PROMO_TITLE}</Text>
      </View>
      <View style={styles.paymentDetails}>
        <View style={styles.infoContainer}>
          <Text style={styles.bold}>{keys.BOOK_CODE}</Text>
          <Text>{bookData.userBookCode}</Text>
        </View>
        <View style={styles.priceContainer}>
          <Text
            style={[
              styles.bold,
              { marginRight: 5, fontSize: 16, color: theme.colors.white },
            ]}
          >
            {bookData.price.currency}
          </Text>
          <Text
            style={[styles.bold, { fontSize: 24, color: theme.colors.white }]}
          >
            {bookData.flightValue}
          </Text>
        </View>
      </View>
      <Text style={styles.title}>{keys.TITLE}</Text>
      <View style={{ marginHorizontal: 20, paddingBottom: 50 }}>
        {paymentMethods.map((method) => (
          <PaymentButton {...method} bookData={bookData} />
        ))}
      </View>
    </ScrollView>
  );
};

export default PaymentMethods;
