import axiosInstance from 'axios';
import firebase from 'firebase';

const CancelToken = axiosInstance.CancelToken;

export const axios = axiosInstance.create({
  //baseURL: 'http://localhost:3000',
  baseURL: 'https://crux-jets-server.herokuapp.com/',
  timeout: 30000,
  withCredentials: true,
});

axios.interceptors.request.use(async (config) => {
  const user = firebase.auth().currentUser;
  if (user) {
    const token = await user.getIdToken(true);
    config.headers.authToken = token;
  }

  return config;
});
