import { StyleSheet } from 'react-native';

import { screen } from 'utils';

const { isMobile } = screen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  background: {
    flex: 1,
  },
  content: {
    flex: 1,
    justifyContent: isMobile ? 'space-between' : 'center',
    paddingTop: 0,
    // justifyContent: 'flex-end',
  },
  formContainer: {
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
  },
  formChildrenContainer: {
    maxWidth: 500,
    padding: 40,
    paddingTop: 0,
    width: '100%',
  },
  logoContainer: {
    alignItems: isMobile ? 'center' : 'flex-start',
  },
});

export default styles;
