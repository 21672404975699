import React from 'react';
import { View } from 'react-native';
import Image from 'components/image';

import styles from './Membership.styles';
import background from 'assets/barrier.png';
import Text from 'components/text/Text';

const Membership = () => (
  <View style={styles.container}>
    <Text style={styles.text}>Próximamente</Text>
    <Image source={background} style={styles.backgroundImage} />
  </View>
);

export default Membership;
