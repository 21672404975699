import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { theme } from 'utils';

function SvgComponent(props) {
  const { color = theme.colors.primary } = props;

  return (
    <Svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M0.328176 13.3333L3.42258 11.0638C3.41795 10.9724 3.41511 10.8807 3.41511 10.7879C3.41511 8.79538 4.6912 7.1744 6.26027 7.1744L13.1705 7.1744L13.1705 10.0132L20 5.00658L13.1705 -2.98527e-07L13.1705 2.83769L6.26027 2.83769C4.58773 2.83769 3.01689 3.66507 1.83396 5.16658C0.651022 6.6688 -3.78739e-07 8.66453 -4.71555e-07 10.7879C-5.09974e-07 11.6668 0.111288 12.5237 0.328176 13.3333Z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
