import { StyleSheet } from 'react-native';

import { screen, theme } from '../../utils';

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    marginHorizontal: 20,
    width: '100%',
    maxWidth: screen.tabletInitialResolution,
    paddingTop: 20,
    backgroundColor:'white'
  },
  paymentDetails: {
    marginTop: -40,
    backgroundColor: theme.colors.white,
    borderRadius: 10,
    marginBottom: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    marginHorizontal: 20,
  },
  infoContainer: {
    padding: 20,
  },
  congratsTitle: {
    alignSelf: 'flex-start',
    fontSize: 28,
    marginBottom: 12,
    marginTop: 0,
    fontWeight: '800',
    zIndex: 99,
  },
  title: {
    alignSelf: 'flex-start',
    fontSize: 20,
    marginBottom: 20,
    marginHorizontal: 20,
    marginTop: 0,
    fontWeight: '600',
    zIndex: 99,
  },
  bold: {
    fontWeight: '600',
    fontSize: 18,
  },
  congrats: {
    marginHorizontal: 'auto',
    alignSelf: 'center',
    paddingLeft: 160,
    paddingTop: 64,
    width: 400,
    height: 300,
    paddingRight: 40,
    maxWidth: '100%',
  },
  priceContainer: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    fontSize: 20,
    backgroundColor: theme.colors.primary,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderBottomEndRadius: 10,
    borderTopEndRadius: 10,
  },
  backgroundImage: {
    height: 258,
    opacity: 1,
    width: 390,
    marginLeft: 5,
    position: 'absolute',
    left: 0,
    top: 0,
    maxWidth: '100%',
  },
});

export default styles;
