import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';

import styles from './Bookings.styles';
import { getBookings } from 'api';
import { noResults as noResultsKeys } from 'i18n';
import FlightThumbnail from 'components/flight-thumbnail';
import Spinner from 'components/spinner';
import NoResults from 'components/noResults';

const Bookings = () => {
  const [bookings, setBookings] = useState(null);

  const renderBookings = () => {
    if (bookings?.length !== 0) {
      return bookings.map((booking) => {
        const thumbnailProps = {
          ...booking,
          ...booking.flight,
        };
        return (
          <FlightThumbnail
            titleType="trip"
            key={booking.id}
            {...thumbnailProps}
            withDate={true}
          />
        );
      });
    }

    return (
      <View style={{ marginTop: 100 }}>
        <NoResults message={noResultsKeys.NO_RESULTS_BOOKINGS} />
      </View>
    );
  };

  const onSuccess = (res) => {
    setBookings(res.data);
  };

  const onError = (err) => {
    console.log('**** onError -> ', err);
  };

  useEffect(() => {
    getBookings({ onSuccess, onError });
  }, []);

  return (
    <View style={{ backgroundColor:'#2E3440' }}>
      <ScrollView contentContainerStyle={styles.container}>
        {bookings ? renderBookings() : <Spinner />}
      </ScrollView>
    </View>
  );
};

export default Bookings;
