import { StyleSheet } from 'react-native';

import { theme } from 'utils';

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.primary,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
  },
  button: {
    backgroundColor: theme.colors.lightGrey,
    padding: 10,
    borderRadius: 5,
  },
  buttonChecked: {
    backgroundColor: theme.colors.secondary,
  },
  buttonDisabled: {
    opacity: 0.5,
  },
  buttonLabelStyle: {
    color: theme.colors.primary,
    flexShrink: 0,
    fontSize: 16,
    textAlign: 'center',
    textTransform: 'none',
    lineHeight: 18,
  },
  buttonLabelStyleChecked: {
    color: theme.colors.white,
  },
});

export default styles;
