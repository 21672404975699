import 'react-native-gesture-handler'; // Esta línea va siempre arriba de todo.
import React, { useEffect, useRef } from 'react';
import { firebaseInit } from './firebase-init';
import { StyleSheet, LogBox } from 'react-native';
import {
  getPathFromState,
  NavigationContainer,
} from '@react-navigation/native';
import {
  CardStyleInterpolators,
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';
import { Provider as PaperProvider } from 'react-native-paper';
import * as Analytics from 'expo-firebase-analytics';
import { GADebugModeEnabled } from 'utils/googleAnalytics';
import { requestTrackingPermissionsAsync } from 'expo-tracking-transparency';

import useAuth from './oauth';
import { device, theme } from 'utils';

import { app as appKeys } from './i18n';
import { routes, screen } from './utils';
import Bookings from './screens/bookings';
import Detail from './screens/detail';
import Home from './screens/home';
import LogIn from './screens/log-in/LogIn';
import Membership from './screens/membership';
import Navbar from './components/navbar';
import RecoverPassword from './screens/recover-password/RecoverPassword';
import Results from './screens/results';
import SignUp from './screens/SignUp';
import Search from './screens/search/Search';
import SearchConfig from './screens/search-config';
import DateConfig from './screens/date-config';
import PaymentDetail from './screens/payment-detail';
import PaymentMethods from './screens/payment-methods';
import TermsConditions from './screens/terms-conditions';
import * as Sentry from 'sentry-expo';
import PaymentsProvider from 'components/payments-provider';
import StripeForm from 'screens/stripe-form';
import DecidirForm from 'screens/decidir-form'
import { SnackbarProvider } from './contexts/SnackbarContext';

firebaseInit();

if (device.isIOS) {
  Analytics.setAnalyticsCollectionEnabled(false);
}

const {
  BOOKINGS_TITLE,
  DATE_CONFIG_TITLE,
  DETAIL_TITLE,
  PAYMENT_DETAIL_TITLE,
  PAYMENT_METHODS_TITLE,
  RESULTS_TITLE,
  SEARCH_CONFIG_TITLE,
  TERMS_CONDITIONS_TITLE,
} = appKeys;
const { isBigScreen, maxWidth } = screen;
const {
  BOOKINGS,
  DATE_CONFIG,
  DETAIL,
  HOME,
  LOG_IN,
  MEMBERSHIP,
  PAYMENT_DETAIL,
  PAYMENT_METHODS,
  RECOVER_PASSWORD,
  RESULTS,
  SEARCH,
  SEARCH_CONFIG,
  SEARCH_FROM_LOCATION,
  SIGN_UP,
  TERMS_CONDITIONS,
  STRIPE_FORM,
  DECIDIR_FORM
} = routes;

const Stack = createStackNavigator();

const TAB_TITLE = 'CruxJets';

Sentry.init({
  dsn: 'https://c2ae2cde18084c6d9c186aac3bffbcaf@o1006374.ingest.sentry.io/5985023',
  enableInExpoDevelopment: true,
  debug: true, // Sentry will try to print out useful debugging information if something goes wrong with sending an event. Set this to `false` in production.
});

// Esta línea nos permite visualizar los tracks que se envían en la consola del browser.
GADebugModeEnabled(true);

if (LogBox) {
  LogBox.ignoreAllLogs();
}

export default function App() {
  const { loading, logged } = useAuth();
  const navigationRef = useRef();
  const routeNameRef = useRef();

  const screens = {
    Detail: {
      path: 'flight/:id?',
    },
    TermsConditions: {
      path: 'terms',
    },
  };

  const linking = {
    prefixes: ['https://app.cruxjets.com', 'cruxjets://'],
    config: {
      screens,
    },
    getPathFromState: (state, config) => {
      let path = getPathFromState(state, config);
      // impedimos que se vea cualquier ruta que no esté detallada
      if (!screens[navigationRef.current.getCurrentRoute().name]) return '/';
      const index = path.indexOf('?');
      if (index >= 0) {
        path = path.substr(0, index);
      }
      return path;
    },
  };

  const onReadyHandler = async () => {
    // Este método nos permite trackear la screen inicial.
    const currentRoute = navigationRef.current.getCurrentRoute();
    if (!currentRoute) return;
    const currentRouteName = currentRoute.name;
    await Analytics.setCurrentScreen(currentRouteName);
    routeNameRef.current = currentRouteName;
  };

  const onStateChangeHandler = async () => {
    // Con este método detectamos los cambios de screen y los trackeamos.
    const previousRouteName = routeNameRef.current;
    const currentRouteName = navigationRef.current.getCurrentRoute().name;

    if (previousRouteName !== currentRouteName) {
      await Analytics.setCurrentScreen(currentRouteName);
    }

    routeNameRef.current = currentRouteName;
  };

  const getLoggedScreen = (Screen) => {
    if (logged) {
      return Screen;
    } else {
      return (
        <Stack.Screen
          component={LogIn}
          name={Screen.props.name}
          initialParams={{
            loginRequired: true,
          }}
          options={{ headerShown: false, title: TAB_TITLE }}
        />
      );
    }
  };

  useEffect(() => {
    (async () => {
      const { status } = await requestTrackingPermissionsAsync();
      if (status === 'granted' && device.isIOS) {
        Analytics.setAnalyticsCollectionEnabled(true);
      }
    })();
  }, []);

  return (
    <PaymentsProvider>
      <PaperProvider styles={styles.MainColorContainer}>
        <NavigationContainer
          linking={linking}
          ref={navigationRef}
          onReady={onReadyHandler}
          onStateChange={onStateChangeHandler}
        >
          <SnackbarProvider>
            {!loading && (
              <Stack.Navigator
                headerMode="screen"
                screenOptions={{
                  cardStyle: styles.container,
                  animationEnabled: true,
                  ...TransitionPresets.SlideFromRightIOS,
                }}
                initialRouteName={HOME}
              >
                {/* No Login Required */}
                <Stack.Screen
                  component={LogIn}
                  name={LOG_IN}
                  options={{ headerShown: false, title: TAB_TITLE }}
                />
                <Stack.Screen
                  name={HOME}
                  component={Home}
                  options={{
                    header: (props) => <Navbar {...props} logged={logged} />,
                    title: TAB_TITLE,
                    cardStyle:styles.bodyColorContainer
                  }}
                />
                <Stack.Screen
                  name={RECOVER_PASSWORD}
                  options={{ headerShown: false, title: TAB_TITLE }}
                >
                  {(props) => <RecoverPassword {...props} />}
                </Stack.Screen>
                <Stack.Screen
                  name={SIGN_UP}
                  options={{ headerShown: false, title: TAB_TITLE }}
                >
                  {(props) => <SignUp {...props} />}
                </Stack.Screen>

                <Stack.Screen
                  name={MEMBERSHIP}
                  component={Membership}
                  options={{
                    header: (props) => (
                      <Navbar {...props} type="internal" logged={logged} />
                    ),
                    title: TAB_TITLE,
                    cardStyle:styles.bodyColorContainer
                  }}
                />
                <Stack.Screen
                  name={DETAIL}
                  component={(props) => <Detail {...props} logged={logged} />}
                  options={{
                    header: (props) => (
                      <Navbar
                        {...props}
                        type="internal"
                        title={DETAIL_TITLE}
                        logged={logged}
                      />
                    ),
                    title: TAB_TITLE,
                  }}
                />

                <Stack.Screen
                  name={SEARCH}
                  component={Search}
                  options={{
                    header: (props) => (
                      <Navbar {...props} type="internal" logged />
                    ),
                    title: TAB_TITLE,
                    cardStyleInterpolator:
                      CardStyleInterpolators.forFadeFromBottomAndroid,
                  }}
                />

                <Stack.Screen
                  name={SEARCH_FROM_LOCATION}
                  component={Search}
                  options={{
                    header: (props) => (
                      <Navbar {...props} type="internal" logged />
                    ),
                    title: TAB_TITLE,
                    cardStyleInterpolator:
                      CardStyleInterpolators.forFadeFromBottomAndroid,
                  }}
                />

                <Stack.Screen
                  name={SEARCH_CONFIG}
                  component={SearchConfig}
                  options={{
                    header: (props) => (
                      <Navbar
                        {...props}
                        type="internal"
                        title={SEARCH_CONFIG_TITLE}
                        logged
                      />
                    ),
                    title: TAB_TITLE,
                    cardStyle:styles.bodyColorContainer
                  }}
                />

                <Stack.Screen
                  name={DATE_CONFIG}
                  component={DateConfig}
                  options={{
                    header: (props) => (
                      <Navbar
                        {...props}
                        type="internal"
                        title={DATE_CONFIG_TITLE}
                        logged
                      />
                    ),
                    title: TAB_TITLE,
                    cardStyle:styles.bodyColorContainer
                  }}
                />

                <Stack.Screen
                  name={RESULTS}
                  component={Results}
                  options={{
                    header: (props) => (
                      <Navbar
                        {...props}
                        type="internal"
                        title={RESULTS_TITLE}
                        logged
                      />
                    ),
                    title: TAB_TITLE,
                    cardStyle:styles.bodyColorContainer
                  }}
                />

                {/* Login Required */}

                {getLoggedScreen(
                  <Stack.Screen
                    name={BOOKINGS}
                    component={Bookings}
                    options={{
                      header: (props) => (
                        <Navbar
                          {...props}
                          type="internal"
                          title={BOOKINGS_TITLE}
                          logged
                        />
                      ),
                      title: TAB_TITLE,
                      cardStyle:styles.bodyColorContainer
                    }}
                  />
                )}
                {getLoggedScreen(
                  <Stack.Screen
                    name={PAYMENT_METHODS}
                    component={PaymentMethods}
                    options={{
                      header: (props) => (
                        <Navbar
                          {...props}
                          type="internal"
                          title={PAYMENT_METHODS_TITLE}
                          logged
                        />
                      ),
                      title: TAB_TITLE,
                      cardStyle:styles.bodyColorContainer
                    }}
                  />
                )}
                {getLoggedScreen(
                  <Stack.Screen
                    name={PAYMENT_DETAIL}
                    component={PaymentDetail}
                    options={{
                      header: (props) => (
                        <Navbar
                          {...props}
                          type="internal"
                          title={PAYMENT_DETAIL_TITLE}
                          logged
                        />
                      ),
                      title: TAB_TITLE,
                      cardStyle:styles.bodyColorContainer
                    }}
                  />
                )}
                {getLoggedScreen(
                  <Stack.Screen
                    name={STRIPE_FORM}
                    component={StripeForm}
                    options={{
                      header: (props) => (
                        <Navbar
                          {...props}
                          type="internal"
                          title={PAYMENT_DETAIL_TITLE}
                          logged
                        />
                      ),
                      title: TAB_TITLE,
                      cardStyle:styles.bodyColorContainer
                    }}
                  />
                )}
                {getLoggedScreen(
                  <Stack.Screen
                    name={DECIDIR_FORM}
                    component={DecidirForm}
                    options={{
                      header: (props) => (
                        <Navbar
                          {...props}
                          type="internal"
                          title={PAYMENT_DETAIL_TITLE}
                          logged
                        />
                      ),
                      title: TAB_TITLE,
                      cardStyle:styles.bodyColorContainer
                    }}
                  />
                )}
                <Stack.Screen
                  name={TERMS_CONDITIONS}
                  component={TermsConditions}
                  options={{
                    header: (props) => (
                      <Navbar
                        {...props}
                        type="internal"
                        title={TERMS_CONDITIONS_TITLE}
                        logged={logged}
                      />
                    ),
                    title: TAB_TITLE,
                    cardStyle:styles.bodyColorContainer
                  }}
                />
              </Stack.Navigator>
            )}
          </SnackbarProvider>
        </NavigationContainer>
      </PaperProvider>
    </PaymentsProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf: isBigScreen ? 'center' : 'auto',
    backgroundColor: theme.colors.background,
    width: isBigScreen ? maxWidth : 'auto',
  },
  MainColorContainer:{
    backgroundColor:'#2E3440'}
    ,
  bodyColorContainer:{
  backgroundColor:"white"
  }
});
